/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import AdminNavbar from "../Navbars/DefaultNavbar";

class Notify extends Component {
 
  render() {
    return (
      
      <div style={{  marginLeft: "10px"}}>  
        <AdminNavbar/>  
        <hr/>
       <div style={{  marginLeft: "500px", marginTop : "150px"}}> 
          <div><p style={{color : "white"}}>
             <h5> <b> Your account is not approved yet. </b></h5>
             <h5> <b> Please contact your coordinator or admin.</b></h5>
            </p>
           </div> 
         </div>
        </div>
    );
  }
}
export default Notify;