/* eslint-disable import/extensions */
/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://photo-wonder.com/product/black-dashboard-react
* Copyright 2020 Photo-Wonder (https://photo-wonder.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Photo-Wonder

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "./components/dashboard/dashboard-new";
import Orders from "./components/dashboard/orders";
// import QA from "./components/dashboard/qa";
// import Teams from "./components/dashboard/teams.js";
import Help from "./components/dashboard/help";
import Users from "./components/dashboard/users";
import Artists from "./components/dashboard/artists";
// import DailyReports from "./components/dashboard/dailyreports.js";
import Reports from "./components/dashboard/reports.js";
import Bill from "./components/dashboard/bill.js";
import UserProfile from "./components/auth/UserProfile.js";
import documentation from "./components/auth/documentation.js";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-puzzle-10",
    component: Orders,
    layout: "/admin",
  },
  // {
  //   path: "/qa",
  //   name: "QA",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: QA,
  //   layout: "/admin",
  // },
  {
    path: "/help",
    name: "Help",
    rtlName: "Help",
    icon: "tim-icons icon-support-17",
    component: Help,
    layout: "/admin",
  },
  // {
  //   path: "/team",
  //   name: "Teams",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: Teams,
  //   layout: "/admin",
  // },
  {
    path: "/users",
    name: "Users",
    rtlName: "Users",
    icon: "tim-icons icon-single-02",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/artists",
    name: "Artists",
    rtlName: "artists",
    icon: "tim-icons icon-satisfied",
    component: Artists,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-badge",
    component: UserProfile,
    layout: "/admin",
  },
  
  // {
  //   path: "/dailyreports",
  //   name: "DailyReport",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-coins",
  //   component: DailyReports,
  //   layout: "/admin"
  // },
  {
    path: "/reports",
    name: "Report",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-bar-32",
    component: Reports,
    layout: "/admin"
  }
  ,{
    path: "/bill",
    name: "Bill",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-puzzle-10",
    component: Bill,
    layout: "/admin"
  },
  {
    path: "/documentation",
    name: "documentation",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-paper",
    component: documentation,
    layout: "/admin"
  }
];
export default routes;
