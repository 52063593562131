/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
// import { MDBDataTableV5 } from "mdbreact";

import {
    // Input,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    // UncontrolledTooltip,
    FormGroup,
    Button,
  Table,
  UncontrolledTooltip,
  ButtonGroup,
  Input,
} from "reactstrap";

import ReactLoading from "react-loading";
import MyBaseTable from "./BillTable";
import Datetime from "react-datetime";
import { forEach } from "lodash";

const axios = require("axios");

class Artists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: 9,
            infoPanel: [],
            isrendered: false,
            checkbox1: []
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchApi();
        }, 400);
        console.log("Child did mount.");
    }

    onSelectionChange = async (rows) => {
        console.log(rows);
        const data = await rows.map((row) => row.email);
        this.setState({
            checkbox1: data,
        });
    };

    setTableValues = async (data) => {
        const self = this;
        console.log(data);
        console.log("data");
        self.setState({
            infoPanel: data,
            isrendered: true,
        });
        let Elite = [];
        let MostPopular = [];
        let Traditional = [];
        let KnockOut = [];
        let PremierRT = [];

        let sales = {};
        sales["Elite-Presentation"] = 0;
        for (let index = 0; index < data.length; index++) {
            let sale = {};

            const record = data[index];
            const salesType = record.SalesType;
            const BilledForType = record.BilledForType;
            const BilledForCount = record.BilledForCount;
            if (BilledForType === "Video") {
                if (salesType.includes("Elite")) {
                    if (Object.keys(sales).includes("Elite-Presentation")) {
                        sales["Elite-Presentation"] =
                            sales["Elite-Presentation"] + BilledForCount;
                    } else {
                        sales["Elite-Presentation"] = BilledForCount;
                    }
                } else if (salesType.includes("Most Popular")) {
                    if (
                        Object.keys(sales).includes("Most Popular-Presentation")
                    ) {
                        sales["Most Popular-Presentation"] =
                            sales["Most Popular-Presentation"] + BilledForCount;
                    } else {
                        sales["Most Popular-Presentation"] = BilledForCount;
                    }
                } else {
                    console.log("!!!!ISSUE in billing....................");
                }
            } else if (BilledForType === "KO52") {
                if (Object.keys(sales).includes("Code 52")) {
                    sales["Code 52"] = sales["Code 52"] + BilledForCount;
                } else {
                    sales["Code 52"] = BilledForCount;
                }
            } else if (BilledForType === "KO25") {
                if (Object.keys(sales).includes("Code 25")) {
                    sales["Code 25"] = sales["Code 25"] + BilledForCount;
                } else {
                    sales["Code 25"] = BilledForCount;
                }
            } else if (BilledForType === "TotalImages") {
                if (Object.keys(sales).includes(salesType)) {
                    sales[salesType] = sales[salesType] + BilledForCount;
                } else {
                    sales[salesType] = BilledForCount;
                }
            }
        }
        let price = {
          "Most Popular":0.13,
          "Most Popular-ArtistTouch": 0.13,
          "Most Popular-Presentation": 4.50,
          "Elite": 0.19,
          "Elite-ArtistTouch": 0.19,
          "Elite-Presentation": 9.00,
          "Premier RT": 0.23,
          "Traditional Scenes":	0.10,
          "Traditional-ArtistTouch": 0.10,
          "Yearbook": 0.10,
          "Yearbook-ArtistTouch":	0.10,
          "Retake":	0.10,
          "Code 52":	0.04,
          "Code 25":	0.04
        }

        let prices = (T)=>{
          if (Object.keys(price).includes(T)){
            return price[T]
          }
          if(T.includes('Elite')){
            price[T] = 0.19
          }else if(T.includes('Most')){ 
            price[T] = 0.13
          }else if(T.includes('Premier')){ 
            price[T] = 0.23
          }else{
            price[T] = 0.10
          }
          return price[T]
          
        }
        Object.keys(sales).forEach( (T,I)=>{
          if(T.includes('Elite')){
            Elite[T] = {count:sales[T],price:prices(T)}
          }
          else if(T.includes('Most')){
            MostPopular[T] = {count:sales[T],price:prices(T)}
          }
          else if(T.includes('Code')){
            KnockOut[T] = {count:sales[T],price:prices(T)}
          }
          else if(T.includes('Premier')){
            PremierRT[T] = {count:sales[T],price:prices(T)}
          }
          else{
            Traditional[T] = {count:sales[T],price:prices(T)}
          }

        } );
        let sum = 0;
        
        Object.keys(Elite).forEach( x => {
          sum = sum + (Elite[x].count * Elite[x].price)
        })
        Object.keys(MostPopular).forEach( x => {
          sum = sum + (MostPopular[x].count * MostPopular[x].price)
        })
        Object.keys(KnockOut).forEach( x => {
          sum = sum + (KnockOut[x].count * KnockOut[x].price)
        })
        Object.keys(Traditional).forEach( x => {
          sum = sum + (Traditional[x].count * Traditional[x].price)
        })
        Object.keys(PremierRT).forEach( x => {
          sum = sum + (PremierRT[x].count * PremierRT[x].price)
        })

        self.setState({
          Elite,
          MostPopular,
          KnockOut,
          Traditional,
          PremierRT,
          sum
      });
    };
    handleChange = async (e,v) => {

      console.log(e.target.value,v);
      if(v.includes('Elite')){
        let Elite = this.state.Elite;
        Elite[v].price = e.target.value
        await this.setState({
          Elite
        })
      }
      else if(v.includes('Most')){
        let MostPopular = this.state.MostPopular;
        MostPopular[v].price = e.target.value
        await this.setState({
          MostPopular
        })
      }
      else if(v.includes('Code')){
        let KnockOut = this.state.KnockOut;
        KnockOut[v].price = e.target.value
        await this.setState({
          KnockOut
        })
      }
      else if(v.includes('Premier')){
        let PremierRT = this.state.PremierRT;
        PremierRT[v].price = e.target.value
        await this.setState({
          PremierRT
        })
      }
      else{
        let Traditional = this.state.Traditional;
        console.log(Traditional[v])
        Traditional[v].price = e.target.value
        await this.setState({
          Traditional
        })
      }
      let {Elite,
        MostPopular,
        KnockOut,
        Traditional,
        PremierRT} = this.state
      let sum = 0;
      Object.keys(Elite).forEach( x => {
        sum = sum + (Elite[x].count * Elite[x].price)
      })
      Object.keys(MostPopular).forEach( x => {
        sum = sum + (MostPopular[x].count * MostPopular[x].price)
      })
      Object.keys(KnockOut).forEach( x => {
        sum = sum + (KnockOut[x].count * KnockOut[x].price)
      })
      Object.keys(Traditional).forEach( x => {
        sum = sum + (Traditional[x].count * Traditional[x].price)
      })
      Object.keys(PremierRT).forEach( x => {
        sum = sum + (PremierRT[x].count * PremierRT[x].price)
      })
      console.log(sum)
      this.setState({
        sum
      })
    }
    fetchApi = () => {
        let self = this;
        // let {fromDate,toDate} = self.state;
        var date = new Date(),
            m = date.getMonth();
        axios
            .get(`/billApi/10`)
            .then((response) => {
                this.setTableValues(response.data.data);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                // always executed
            });
    };

    updateToDate = (e) => {
        this.setState({
            toDate: e,
        });
    };

    updateFromDate = (e) => {
        this.setState({
            fromDate: e,
        });
    };

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col lg="12">
                            <Card
                                className="card-chart"
                                style={{ minHeight: "557px" }}
                            >
                                <CardHeader>
                                    <CardTitle tag="h4">Monthly Bill</CardTitle>
                                </CardHeader>
                                <div>
                                   { 0 ? ( <Row>
                                        <Col xs={12} md={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        From Date
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Datetime
                                                            onBlur={
                                                                this
                                                                    .updateFromDate
                                                            }
                                                            dateFormat="YYYY/MM/DD"
                                                            timeFormat={true}
                                                            filter={
                                                                this.dateFrom
                                                            }
                                                            inputProps={{
                                                                placeholder:
                                                                    "Start date",
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        To Date
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Datetime
                                                            onBlur={
                                                                this
                                                                    .updateToDate
                                                            }
                                                            dateFormat="YYYY/MM/DD"
                                                            timeFormat={true}
                                                            filter={this.dateto}
                                                            inputProps={{
                                                                placeholder:
                                                                    "End date",
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={8} md={2}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle></CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Button
                                                        onClick={this.fetchApi}
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Filter Reports
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>):(<></>)}
                                </div>
                                <CardBody>
                                    {/* <BillTable /> */}
    <Table  className="table-shopping" style={{
          overflowX: "auto",
          overflowY: "auto"
    }}>
    <thead>
        <tr>
            <th>Sales Type</th>
            <th className="text-justified">Count</th>
            <th className="text-justified">Price/Unit</th>
            <th className="text-justified">AMOUNT</th>
        </tr>
    </thead>
    <tbody>
    <tr>
            <td colSpan="4">Premier RT</td>
            
    </tr>
      {
       this.state.Elite && Object.keys(this.state.PremierRT).map( (x,i) => (
          <tr key={"PremierRT-"+i}>
          <td className="td-name">
          <small>{x}</small>
          </td>
            <td className="text-justified"> <small>{this.state.PremierRT[x].count}</small></td>
            <td className="text-justified"><Input type="number" name="price" placeholder="$0" onChange={(e) => {this.handleChange(e,x)}} ref={(input)=> this.myinput = input} value={this.state.PremierRT[x].price}/></td>
            <td className="text-justified"> <small>${ parseFloat(this.state.PremierRT[x].count * this.state.PremierRT[x].price).toFixed(2)}</small></td>
          </tr>
        ))
      }
    <tr>
            <td colSpan="4">Elite</td>
            
    </tr>
      {
       this.state.Elite && Object.keys(this.state.Elite).map( (x,i) => (
          <tr key={"Elite-"+i}>
          <td className="td-name">
          <small>{x}</small>
          </td>
            <td className="text-justified"> <small>{this.state.Elite[x].count}</small></td>
            <td className="text-justified"><Input type="number" name="price" placeholder="$0" onChange={(e) => {this.handleChange(e,x)}} ref={(input)=> this.myinput = input} value={this.state.Elite[x].price}/></td>
            <td className="text-justified"> <small>${ parseFloat(this.state.Elite[x].count * this.state.Elite[x].price).toFixed(2)}</small></td>
          </tr>
        ))
      }
      <tr>
            <td colSpan="4">Most Popular</td>
            
    </tr>
      {
       this.state.MostPopular && Object.keys(this.state.MostPopular).map( (x,i) => (
          <tr key={"MP-"+i}>
          <td className="td-name">
          <small>{x}</small>
          </td>
          <td className="text-justified"> <small>{this.state.MostPopular[x].count}</small></td>
          <td className="text-justified"><Input type="number" name="price" placeholder="$0" onChange={(e) => {this.handleChange(e,x)}} ref={(input)=> this.myinput = input} value={this.state.MostPopular[x].price}/></td>
            <td className="text-justified"> <small>${ parseFloat(this.state.MostPopular[x].count * this.state.MostPopular[x].price).toFixed(2)}</small></td>
          </tr>
        ))
      }
      <tr>
            <td colSpan="4">Traditional</td>
            
    </tr>
      {
       this.state.Traditional && Object.keys(this.state.Traditional).map( (x,i) => (
          <tr key={"Traditional-"+i}>
          <td className="td-name">
          <small>{x}</small>
          </td>
          <td className="text-justified"> <small>{this.state.Traditional[x].count}</small></td>
          <td className="text-justified"><Input type="number" name="price" placeholder="$0" onChange={(e) => {this.handleChange(e,x)}} ref={(input)=> this.myinput = input} value={this.state.Traditional[x].price}/></td>
            <td className="text-justified"> <small>${ parseFloat(this.state.Traditional[x].count * this.state.Traditional[x].price).toFixed(2)}</small></td>
          </tr>
        ))
      }
      <tr>
            <td colSpan="4">Knock Out</td>
            
    </tr>
      {
       this.state.KnockOut && Object.keys(this.state.KnockOut).map( (x,i) => (
          <tr key={"KnockOut-"+i}>
          <td className="td-name">
          <small>{x}</small>
          </td>
          <td className="text-justified"> <small>{this.state.KnockOut[x].count}</small></td>
          <td className="text-justified"><Input type="number" name="price" placeholder="$0" onChange={(e) => {this.handleChange(e,x)}} ref={(input)=> this.myinput = input} value={this.state.KnockOut[x].price}/></td>
            <td className="text-justified"> <small>${ parseFloat(this.state.KnockOut[x].count * this.state.KnockOut[x].price).toFixed(2)}</small></td>
          </tr>
        ))
      }
      
        <tr>
            <td colSpan="2"></td>
            <td className="td-total">Total</td>
            <td className="td-price">${parseFloat(this.state.sum).toFixed(2)}</td>
        </tr>
    </tbody>
</Table>
                                    {/* <BillTable /> */}
                                    {!this.state.isrendered ? (
                                        <div
                                            style={{
                                                margin: "100px 450px 150px",
                                            }}
                                        >
                                            <ReactLoading
                                                type="spinningBubbles"
                                                color="#e14eca"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                        <CardTitle style={{
                                          marginTop:"50px"
                                        }}tag="h4">Bill Details</CardTitle>
                                        <MyBaseTable
                                            records={this.state.infoPanel}
                                            onSelectionChange={
                                                this.onSelectionChange
                                            }
                                        />
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default Artists;
