import moment from "moment-timezone";
const axios = require("axios");

const setTableValuesFunc = async (stateTasks, change) => {
    let images = 0;
    let openTasks = 0;
    let downloadedTasks = 0;
    let deliveredTasks = 0;
    let redos = 0;
    let issuesCount = 0;
    let rejectCount = 0;
    let holdCount = 0;
    let cancelledCount = 0;
    let qaCount = 0;
    let videoQaCount = 0;
    let videoArtistCount = 0;
    let issuesCountFiles = 0;
    let rejectCountFiles = 0;
    let holdCountFiles = 0;
    let cancelledCountFiles = 0;
    // let CountFiles = 0;
    let qaCountFiles = 0;
    let videoQaCountFiles = 0;
    let videoArtistCountFiles = 0;
    const { users } = (await axios.get(`/api/users`)).data.data;

    // console.log(users)
    // console.log('adding users')

    const mapping = {};
    for (let index = 0; index < users.length; index++) {
        const user = users[index];
        mapping[user.email] = user.name;
    }

    const tasks = stateTasks;
    // const tasks = stateTasks[0];

    for (let i = 0; i < tasks.length; i++) {
        images += parseInt(tasks[i].Count) || 0;
        tasks[i].assignee = mapping[tasks[i].assignee];

        if (change) {
            if (!tasks[i].createdAt) {
                tasks[i].createdAt = tasks[i].OriginalRequestDate;
            }
            tasks[i].createdAt = moment(tasks[i].createdAt)
                .tz("Asia/Kolkata")
                .format("YYYY-MM-DD HH:mm");
            if (tasks[i].completedAt) {
                tasks[i].completedAt = moment(tasks[i].completedAt)
                    .tz("Asia/Kolkata")
                    .format("YYYY-MM-DD HH:mm");
            }
        }
        const fileCount = parseInt(tasks[i].Count) || 0;
        if (tasks[i].stage.toLowerCase() === "issue") {
            issuesCount += 1;
            issuesCountFiles += fileCount;
        }
        if (tasks[i].stage === "rejected") {
            rejectCount += 1;
            rejectCountFiles += fileCount;
        }
        if (tasks[i].stage === "hold") {
            holdCount += 1;
            holdCountFiles += fileCount;
        }
        if (tasks[i].stage === "cancelled") {
            cancelledCount += 1;
            cancelledCountFiles += fileCount;
        }
        if (tasks[i].stage === "qa") {
            qaCount += 1;
            qaCountFiles += fileCount;
        }
        if (tasks[i].stage === "videoQa") {
            videoQaCount += 1;
            videoQaCountFiles += fileCount;
        }
        if (tasks[i].stage === "videoArtist") {
            videoArtistCount += 1;
            videoArtistCountFiles += fileCount;
        }

        // eslint-disable-next-line eqeqeq
        if (tasks[i].ProductionStatusId == "2") {
            deliveredTasks += 1;
        }
        // eslint-disable-next-line eqeqeq
        else if (tasks[i].ProductionStatusId == "1") {
            downloadedTasks += 1;
        }
        // eslint-disable-next-line eqeqeq
        else if (tasks[i].ProductionStatusId == "0") {
            openTasks += 1;
        }
        // else {
        //   openTasks += 1;
        // }
        // eslint-disable-next-line eqeqeq
        if (tasks[i].Correction == "1") {
            redos += 1;
        }
        if (tasks[i].Notes) {
            tasks[i].Notes = this.rl(tasks[i].Notes);
        }
        if (change && tasks[i].comments) {
            tasks[i].comments = this.rl(tasks[i].comments);
        }
    }

    return {
        loading: false,
        originalTasks: tasks,
        MostPopular: true,
        Trad: true,
        Elite: true,
        CapAndGown: true,
        PremierRT: true,
        TraditionalAT: true,
        Yearbook: true,
        Heroes: true,
        open: true,
        downloaded: true,
        delivered: true,
        redo: true,
        infoPanel: [
            {
                name: "Total Tasks Fetched",
                value: tasks.length,
            },
            {
                name: "Total Files Fetched",
                value: images,
            },
            {
                name: "Open Tasks",
                value: openTasks,
            },
            {
                name: "Downloaded Tasks",
                value: downloadedTasks,
            },
            {
                name: "Redo Tasks",
                value: redos,
            },
            {
                name: "Delivered Tasks",
                value: deliveredTasks,
            },
            {
                name: "Issues",
                value: `${issuesCount} / ${issuesCountFiles}`,
            },
            {
                name: "Reject",
                value: `${rejectCount} / ${rejectCountFiles}`,
            },
            {
                name: "Hold",
                value: `${holdCount} / ${holdCountFiles}`,
            },
            {
                name: "Cancelled",
                value: `${cancelledCount} / ${cancelledCountFiles}`,
            },
            {
                name: "Qa",
                value: `${qaCount} / ${qaCountFiles}`,
            },
            {
                name: "videoQa",
                value: `${videoQaCount} / ${videoQaCountFiles}`,
            },
            {
                name: "VideoArtist",
                value: `${videoArtistCount} / ${videoArtistCountFiles}`,
            },
        ],
    };
};

export { setTableValuesFunc };
