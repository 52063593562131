/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState }  from "react";

const CustomImage =  ({onThumbClick,src,srcDone, placeholderImg,orderId, errorImg,name,func, rollnum , acceptValue,comment,selectValue = '',...props }) => {
  const [imgName, setName] = useState(srcDone);
  const [imgStyle, setStyle] = useState({});
  const fileSrc=`/api/getfiles/thumb/512/${orderId}/${src}`
  const doneFileSrc=`/api/getfiles/processedThumb/512/${orderId}/${srcDone}`
  console.log(selectValue)
  
  const onLoad =  useCallback(({target:img}) => {

    if(img ){
      const h = img.height
      const w = img.width

      // setHeight(h)
      // setWidth(w)
    if(w > h ){
      setStyle({
        width:`100%`,
        display: 'block'
      })
    }
    else{
      setStyle({
        height:`100%`,
        display: 'block'

      })
    }
  }

  },[])
  const onError = useCallback(() => {
    // setSrc(errorImg || placeholderImg);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorImg, placeholderImg]);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    // img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      // img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onError]);



  // eslint-disable-next-line prefer-const
  // let dimgStyle = {alignSelf: 'center', ...imgStyle}
  // eslint-disable-next-line dot-notation
  // if (src === '/cady/noImage.jpeg'){
  //   setName(name.split('/').reverse()[0])
  //   func = ()=>{}
  // }
  return <div className="imageframe" im={name} onClick={func} onKeyPress={()=>{}} role="button" tabIndex={rollnum} >
         <input checked={selectValue} id="remember" className = "multipleselection styled-checkbox"name="remember" type="checkbox" onClick={(e)=>{onThumbClick(e,name)}}/>
         <label htmlFor="styled-checkbox-2"></label>
            <div 
            className="imageDiv" 
            im={name}
            onMouseEnter={()=>{setName(src);console.log('hi')}}
            onMouseLeave={()=>{setName(srcDone)}}
            >
            
             { acceptValue===1 ? <i className = "tim-icons icon-check-2 green iconindicator"/>: acceptValue===-1 ? <i className = "tim-icons icon-simple-remove red iconindicator customTooltip inlineTooltip"/>:<></> }
             <div className="tooltiptext">
                            <p><b>Processed Image Issue</b></p>
                            <p>{comment}</p>
                            </div>
              <img
              im={name}
              className="raw"
              {...props}
              style={imgStyle}
              alt={fileSrc} 
              src={fileSrc} 
              onLoad={onLoad}
              />

              <img 
              im={name}
              className="done"
              {...props}
              style={imgStyle}
              alt={doneFileSrc}
              src={doneFileSrc}
              onLoad={onLoad}
               />
            </div>
            <span >{imgName}</span>
          </div>
}

export default CustomImage