/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { MDBDataTableV5 } from "mdbreact";
import {CSVLink,
  //  CSVDownload
  } from 'react-csv';

import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import ReactLoading from "react-loading";

const axios = require("axios");

class Users extends Component {
  constructor(props) {
    super(props);
    const pathUrl = window.location.pathname.toString()
    const path = pathUrl.split('/');
    // const self = this;
    const value=path[2];
    this.state = {
      columns: [
        {
          label: "In Time",
          field: "inTime",
        },
        {
          label: "Count of Orders",
          field: "count",
        },
        {
          label: "fileCount",
          field: "fileCount",
        },
      ],
      detailColumns: [
        {
          label: "OriginalProductionTaskId",
          field: "OriginalProductionTaskId",
        },
        {
          label: "ProductionTaskId",
          field: "ProductionTaskId",
        },
        {
          label: "SalesType",
          field: "SalesType",
        },
        {
          label: "Count",
          field: "Count",
        },
        {
          label: "Redo",
          field: "Correction",
        },
        {
          label: "inTime",
          field: "createdAt",
        },
       
      ],
      infoPanel: [],
      isrendered: false,
      kpi: value,
      titleData : value.toUpperCase()
    };
  }

  componentDidMount() { 

      this.fetchApi();
      console.log("Child did mount.");
  }

   fetchApi =  () => {

    const taskList = [];
    const self = this;
    let taskdata = []
    let taskTableData = []
    const kpiValue = self.state.kpi
    axios.get("/graph/tasks").then((response) => {
      
      if(kpiValue ==="order-received"){
        taskdata = response.data.kpi_ordersReceived;
        console.log('helloowwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww')
        console.log(response.data.kpi_ordersReceivedDetails)
        taskTableData = response.data.kpi_ordersReceivedDetails
      }
        else if( kpiValue==="order-delivered"){
          taskdata = response.data.kpi_ordersDelivered;
          taskTableData = response.data.kpi_ordersDeliveredDetails
        }
        else if( kpiValue==="redo-orders"){
          taskdata = response.data.kpi_redoOrders;
          taskTableData = response.data.kpi_redoOrdersDetails;
	 console.log(response.data.kpi_redoOrdersDetails)
	}
        else if( kpiValue==="current-pending-orders"){
          taskdata = response.data.kpi_currentPending;
          taskTableData = []
        }
        else if( kpiValue==="current-redo-pending-orders"){
          taskdata = response.data.kpi_currentRedoPending;
          taskTableData = []
        }
        else if( kpiValue==="overall-pending-orders"){
          taskdata = response.data.kpi_totalPending;
          console.log(taskdata)
          console.log("---=-")
          // taskdata = response.data.kpi_totalPendingDetails;
          taskTableData = response.data.kpi_totalPendingDetails;
        }
        else if( kpiValue==="overall-redo-pending-orders"){
          taskdata = response.data.kpi_totalRedoPending;
          taskTableData = response.data.kpi_totalRedoPendingDetails;
        }
        else if( kpiValue==="pending-orders-12HRS"){
          taskdata = response.data.pending12HrsOrders;
          taskTableData = response.data.pending12HrsOrdersDetails;
        }
        else if( kpiValue==="pending-orders-18HRS"){
          taskdata = response.data.pending18HrsOrders;
          taskTableData = response.data.pending18HrsOrdersDetails;
        }
        else if( kpiValue==="pending-orders-24HRS"){
          taskdata = response.data.pending24HrsOrders;
          taskTableData = response.data.pending24HrsOrdersDetails;
        }
        else if( kpiValue==="pending-orders-48HRS"){
          taskdata = response.data.pending48HrsOrders;
          taskTableData = response.data.pending48HrsOrdersDetails;
        }

      for (let i = 0; i < taskdata.length; i+= 1) {
          const dayOfMonth =  taskdata[i].day
          const currentMonth =  taskdata[i].month
          const currentYear =  taskdata[i].year
          const dateOfMonth = `${currentYear}-${currentMonth}-${dayOfMonth}`
          taskdata[i].inTime = dateOfMonth
          taskList.push(taskdata[i]);
       
       }

      // const exportData=[]
      console.log('heloooww')
      console.log(taskTableData)
      for (let j = 0; j < taskTableData.length; j+= 1) {
        taskTableData[j].createdAt = taskTableData[j].createdAtIST
       }
      const exportData=taskTableData


      console.log('data')
      console.log(exportData)
      console.log('data---')

      self.setState({
        infoPanel: taskList,
        isrendered: true,
        infoPanelDetails: exportData,
      });


    });

  };


  render() {
    const self = this;
    const [datatable] = [
      {
        columns: self.state.columns,
        rows: self.state.infoPanel,
      },
    ];
    const datatableDetails = { rows:self.state.infoPanelDetails,
                                columns:self.state.detailColumns
    }
    console.log(datatableDetails)
    console.log(datatable)

    // const datatobeexported = self.state.infoPanelDetails || []

    return (
      <>
        <div className="content">
          <Row>
            <Col lg="12">
              <Card className="card-chart" style={{ minHeight: "857px"}}>
                <CardHeader>
                  <CardTitle style={{ textAlign: "center",fontWeight: "500" }} tag="h4">{self.state.titleData}</CardTitle>
                </CardHeader>
                <CardBody style={{ fontWeight: "500", marginLeft: "100px", marginRight: "100px"}}>
                  {!this.state.isrendered ? (
                    <div style={{ margin: "200px 600px 250px" }}>
                      <ReactLoading type="spinningBubbles" color="#e14eca" />
                    </div>
                  ) : (
                    <MDBDataTableV5
                      id="hoverTable"
                      entriesOptions={[10, 20, 30, 40]}
                      entries={10}
                      pagesAmount={20}
                      data={datatable}
                      searchTop
                      searchBottom={false}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
             <Col lg="12">
              <Card className="card-chart" style={{ minHeight: "857px"}}>
                <CardHeader>
                
                  <CardTitle style={{ textAlign: "center",fontWeight: "500" }} tag="h4">{self.state.titleData}</CardTitle>
                </CardHeader>
                <CardBody style={{ fontWeight: "500", marginLeft: "100px", marginRight: "100px"}}>
                  {!this.state.isrendered ? (
                    <div style={{ margin: "200px 600px 250px" }}>
                      <ReactLoading type="spinningBubbles" color="#e14eca" />
                    </div>
                  ) : (
                    <><CSVLink data={self.state.infoPanelDetails || [] } ><CardTitle style={{ textAlign: "right"}}>
                      <i className="tim-icons icon-cloud-download-93" style ={ { color: "blue", fontSize: "2em" }}/>
                    </CardTitle>
                      </CSVLink>
                    <MDBDataTableV5
                      id="hoverTable"
                      entriesOptions={[10, 20, 30, 40]}
                      entries={10}
                      pagesAmount={20}
                      data={datatableDetails}
                      searchTop
                      searchBottom={false}
                    /></>
                  )}
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </div>
      </>
    );
  }
}
export default Users;
