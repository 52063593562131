/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */


/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://photo-wonder.com/product/black-dashboard-pro-react
* Copyright 2020 Photo-Wonder (https://photo-wonder.com)

* Coded by Photo-Wonder

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {
    Component
} from "react";
// import {
//   Link
// } from "react-router-dom";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// import {
//   FacebookShareButton,
//   TwitterShareButton,
//   FacebookIcon,
//   TwitterIcon
// } from "react-share";
// import GitHubButton from "react-github-button";

// import {
//     Button
// } from "reactstrap";

class FixedPlugin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: "dropdown",
            darkMode: true
        };
    }

    handleClick = () => {
        if (this.state.classes === "dropdown") {
            this.setState({
                classes: "dropdown show"
            });
        } else {
            this.setState({
                classes: "dropdown"
            });
        }
    };

    handleActiveMode = () => {
        this.setState({
            darkMode: !this.state.darkMode
        });
        document.body.classList.toggle("white-content");
    };

    render() {
        return (<div className="fixed-plugin" >
            <div className={
                this.state.classes
            } >
                <a href="#pablo"
                    onClick={
                        e => {
                            e.preventDefault();
                            this.handleClick();
                        }
                    } >
                    <i className="fa fa-cog fa-2x" />
                </a> <ul className="dropdown-menu show" >
                    <li className="header-title" > SIDEBAR BACKGROUND </li>
                    <li className="adjustments-line" >
                        <div className="badge-colors text-center" >
                            <span className={
                                this.props.activeColor === "primary" ?
                                    "badge filter badge-primary active" :
                                    "badge filter badge-primary"
                            }
                                data-color="primary"
                                onClick={
                                    () => {
                                        this.props.handleActiveClick("primary");
                                    }
                                }
                            /> <span className={
                                this.props.activeColor === "blue" ?
                                    "badge filter badge-info active" :
                                    "badge filter badge-info"
                            }
                                data-color="info"
                                onClick={
                                    () => {
                                        this.props.handleActiveClick("blue");
                                    }
                                }
                            /> <span className={
                                this.props.activeColor === "green" ?
                                    "badge filter badge-success active" :
                                    "badge filter badge-success"
                            }
                                data-color="success"
                                onClick={
                                    () => {
                                        this.props.handleActiveClick("green");
                                    }
                                }
                            /> <span className={
                                this.props.activeColor === "orange" ?
                                    "badge filter badge-warning active" :
                                    "badge filter badge-warning"
                            }
                                data-color="warning"
                                onClick={
                                    () => {
                                        this.props.handleActiveClick("orange");
                                    }
                                }
                            /> <span className={
                                this.props.activeColor === "red" ?
                                    "badge filter badge-danger active" :
                                    "badge filter badge-danger"
                            }
                                data-color="danger"
                                onClick={
                                    () => {
                                        this.props.handleActiveClick("red");
                                    }
                                }
                            /> </div> </li>
                    <li className="header-title" > SIDEBAR MINI </li>
                    <li className="adjustments-line" >
                        <div className="togglebutton switch-sidebar-mini" >
                            <span className="label-switch" > OFF </span> <Switch onChange={
                                this.props.handleMiniClick
                            }
                                value={
                                    this.props.sidebarMini
                                }
                                onText=""
                                offText="" />
                            <span className="label-switch" > ON </span> </div> </li>
                    <li className="adjustments-line" >
                        <div className="togglebutton switch-change-color mt-3" >
                            <span className="label-switch" > LIGHT MODE </span> <Switch onChange={
                                this.handleActiveMode
                            }
                                value={
                                    this.state.darkMode
                                }
                                onText=""
                                offText="" />
                            <span className="label-switch" > DARK MODE </span> </div> </li>
                </ul> </div> </div>
        );
    }
}

export default FixedPlugin;