import React from "react";
import { Link } from "react-router-dom";
import config from "config/config";

const columns = [
        {
            title: "Task ID",
            field: "ProductionTaskId", // field is the "key" in the data
            key: "ProductionTaskId", // field is the "key" in the data
        },
        {
            title: "Redo",
            field: "Correction", // field is the "key" in the data
            key: "Correction", // field is the "key" in the data
            grouping: true,
            lookup: { 1: "Yes", 0: "No" },
        },
        {
            title: "Original Task ID",
            field: "OriginalProductionTaskId", // field is the "key" in the data
            key: "OriginalProductionTaskId", // field is the "key" in the data
        },
        {
            title: "Sales Type",
            field: "SalesType",
            key: "SalesType",
            grouping: true,
            //lookup:{'Elite':'Elite','Most Popular':'Most Popular','Yearbook':'Yearbook','Heroes':'Heroes','Traditional-ArtistTouch':'Traditional-ArtistTouch','Traditional':'Traditional','Most Popular-ArtistTouch':'Most Popular-ArtistTouch','PremierRT':'PremierRT'}
            lookup: config["SalesType"],
        },

        {
            title: "Student Name",
            field: "StudentName",
            key: "StudentName",
            grow: 3,
        },
        {
            title: "Files",
            field: "Count",
            key: "Count",
        },
        {
            title: "In Time",
            field: "createdAt",
            key: "createdAt",
            // type:'date',
            flexGrow: 5,
            // Cell: props =>  ( props.value && moment(props.value).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm')) || 'undefined'
        },
        {
            width: 150,
            title: "Out Time",
            field: "completedAt",
            key: "completedAt",
            // Cell: props =>  ( props.value && moment(props.value).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm')) || 'undefined'
        },
        {
            width: 150,
            title: "Assignee",
            field: "assignee",
            key: "assignee",
            // Cell: props =>  ( props.value && moment(props.value).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm')) || 'undefined'
        },
        {
            width: 150,
            title: "Artist",
            field: "prev",
            key: "prev",
        },
        {
            width: 150,
            title: "Stage",
            field: "stage",
            key: "stage",
            grouping: true,
            lookup: {
                Notified: "Notified",
                Received: "Received",
                issue: "issue",
                qa: "qa",
                qaRequested: "qaRequested",
                videoArtist: "videoArtist",
                videoArtistRequested: "videoArtistRequested",
                videoQa: "videoQa",
                videoQaRequested: "videoQaRequested",
                rejected: "rejected",
                done: "done",
                hold: "hold",
                cancelled: "cancelled",
                movetor4meRequested: "movetor4meRequested",
                movetor4me: "movetor4me",
                movetor4meDone: "movetor4meDone",
                ReceivedNoKO: "ReceivedNoKO",
                KO: "KO",
                KOError: "KOError",
                KOProc: "KOProc",
		DownloadError: "DownloadError",
		MD5Error: "MD5Error"
            },
            // render: (rowData) => {
            //     return rowData.stage === "issue" ? (
            //         <p style={{ backgroundColor: "#fb6340",padding: "2px",
            //         textAlign: "center"}}>issue</p>
            //     ) : rowData.stage === "rejected" ? (
            //         <p style={{ backgroundColor: "red", textAlign: "center",padding: "2px"}}>rejected</p>
            //     ) : (
            //         <span>{rowData.stage}</span>
            //     );
            // },
        },
        {
            width: 150,
            title: "Status",
            field: "ProductionStatusId",
            key: "ProductionStatusId",
            grouping: true,
            lookup: {
                2: "Delivered",
                0: "Open",
                1: "Downloaded",
                "-1": "hold",
                "-2": "cancelled",
            },
        },
        {
            filtering: false,
            field: "Lookup",
            title: "Lookup",
            render: (rowData) => (
                <Link
                    to="route"
                    target="_blank"
                    onClick={(event) => {
                        event.preventDefault();
                        window.open(`/orders/${rowData.ProductionTaskId}`);
                    }}
                >
                    {" "}
                    <i className="tim-icons icon-zoom-split" />
                </Link>
            ),
        },
    //     {
    //     filtering: false,
    //     field: "QA",
    //     title: "QA",
    //     render: (rowData) => (
    //         <Link
    //             to="route"
    //             target="_blank"
    //             onClick={(event) => {
    //                 event.preventDefault();
    //                 window.open(`/qa/${rowData.ProductionTaskId}`);
    //             }}
    //         >
    //             {" "}
    //             <i className="tim-icons icon-zoom-split" />
    //         </Link>
    //     ),
    // }
]

export default columns;
