/* eslint-disable react/no-unused-state */
import React from "react";
// import 'bootstrap/dist/css/bootstrap.css';


// reactstrap components
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ClickupFrame({src}) {
    return (
    <>
            <iframe
              title="Cady Documentaion"
              className="clickup-embed clickup-dynamic-height"
              src={src}
              frameBorder="0"
              style={{
                background: "transparent",
                position: "absolute",
                top: 60,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%"
              }}
            />
            <script
              async
              src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
            />
    </>
)
    
} 

function Cardtkt() {
  const [modalOpen, setModalOpen] = React.useState(true);
  const [url, setUrl] = React.useState("")
  return (
    <>
    
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h1 className=" modal-title" id="exampleModalLabel">
            Cady Documentation
          </h1>
          
        </div>
        <ModalBody>
        <div>
          <h5 className=" modal-title" id="exampleModalLabel">read the doc:</h5>     
        <Button
            id="Software Documentaion"
            color="primary"
            type="button"
            onClick={() => {setUrl("https://doc.clickup.com/3700557/d/h/3gxud-36024/fb480be1063a6d4");setModalOpen(false)}}
          >
            Software Documentaion
          </Button>
          <Button
            id="Cady Do's and Dont's"
            color="secondary"
            type="button"
            onClick={() => {setUrl("https://doc.clickup.com/3700557/d/h/3gxud-36004/2e58b8623a4cd3f");setModalOpen(false)}}
          >
            Cady Do's and Dont's
          </Button>
          </div>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
      <div><ClickupFrame src={url} />
      
      
      </div>
      
    </> 
  );
}

export default Cardtkt;