/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable  no-unused-expressions */

import React, { Component } from "react";
import {
    Input,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
// import { Link } from "react-router-dom";
// import AutoFitImage from 'react-image-autofit-frame';
import SweetAlert from "react-bootstrap-sweetalert";
import Image from "../../Image";
// import style1 from "../../../assets/scss/_custom.scss"
// import { array } from "prop-types";

const axios = require("axios");

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoPanel: [],
            taskData: [],
            isrendered: false,
            isloaded: false,
            open: true,
            setOpen: true,
            currentFile: "",
            fileArray: [],
            taskid: "Undefined",
            sweetAlert: null,
            acceptAlert: false,
            acceptMultipleAlert: false,
            rejectMultipleAlert: false,
            // acceptAlertNew:false,
            rejectAlert: false,
            // rejectAlertNew:false,
            overView: 0,
            // height2:0,
            // width2:0,
            // height1:0,
            // width1:0,
            overallComment: "",
            rotationAngle: 0,
            newArrayData: [],
            newArray: [],
        };
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
        const path = window.location.pathname.split("/").reverse();
        let taskid;
        const self = this;
        if (path) {
            taskid = path[0];
            this.fetchApi(taskid);
        } else {
            taskid = "";
            self.setState({
                isloaded: true,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    hideAlert = () => {
        this.setState({
            sweetAlert: null,
        });
    };

    handleKeyDown = (event) => {
        if (
            this.state.im &&
            [
                "ArrowUp",
                "ArrowDown",
                "ArrowLeft",
                "ArrowRight",
                "\\",
                "/",
                "Escape",
                "+",
                "-",
                "0",
            ].includes(event.key)
        ) {
            event.preventDefault();
            switch (event.key) {
                case "ArrowUp":
                    this.toggleView();
                    break;
                case "ArrowDown":
                    this.toggleView();
                    break;
                case "ArrowLeft":
                    this.onPrevClick();
                    break;
                case "ArrowRight":
                    this.onNextClick();
                    break;
                case "\\":
                    this.onRejectClick();
                    break;
                case "/":
                    this.onAcceptClick();
                    break;
                case "Escape":
                    this.onCancelClick();
                    break;
                case "+":
                    this.onZoomInClick();
                    break;
                case "-":
                    this.onZoomOutClick();
                    break;
                case "0":
                    this.onRotateClick();
                    break;
                case "9":
                    this.onRotateClick9();
                    break;
                default:
                    break;
            }
        }
    };

    fetchApi = async (id) => {
        const self = this;
        console.log(id); 
        axios
            .get(`/api/tasks/getWithExif/${id}`)
            .then((response) => {
                const currentFile = "";
                const taskData = response.data.data.task;
                const taskFiles = taskData["Files"] || [];
                let files = [];
                for (let I = 0; I < taskFiles.length; I += 1) {
                    const taskFile = taskFiles[I];
                    const taskFileURL = taskFile["URL"];
                    const taskFileName = taskFileURL.split("/").reverse()[0];
                    let val = 0;
                    let com = "";
                    const height = taskFile["size"].height;
                    const width = taskFile["size"].width;
                    const resolutionX = taskFile["resolutionX"];
                    const resolutionY = taskFile["resolutionY"];
                    if (taskData["rejectionNotes"]) {
                        for (const issue of taskData["rejectionNotes"]) {
                            if (issue["url"] === taskFileName) {
                                val = issue["value"];
                                com = issue["comment"];
                            }
                        }
                    }
                    files.push({
                        url: taskFileName,
                        value: val,
                        comment: com,
                        color: taskFile["color"],
                        doneFile: taskFile["doneFile"],
                        doneFileName: taskFile["doneFileName"],
                        height: height,
                        width: width,
                        Ratio: parseInt(width) / (parseInt(height) || 1),
                        resolutionX,
                        resolutionY,
                    });
                }
                console.log(files);
                let fileswithComments = [];

                // for (let index = 0; index < files.length; index++) {
                //   const file = files[index];
                //   const URL = file[]

                // }

                if (
                    taskData.rejectionNotes &&
                    taskData.rejectionNotes[0]
                ) {
                    files.forEach((file) => {
                        fileswithComments.push({
                            ...file,
                            ...taskData.rejectionNotes[file["url"]],
                        });
                    });
                } else {
                    fileswithComments = files;
                }
                let filesData = fileswithComments.filter((i) => {
                    const ext = i.url.split(".").pop();
                    return [
                        "JPG",
                        "JPEG",
                        "PNG",
                        "CR2",
                        "jpg",
                        "jpeg",
                        "png",
                        "cr2",
                    ].includes(ext);
                });
                let selectedFiles = {};
                filesData.forEach((x) => {
                    selectedFiles[x["url"]] = false;
                });
                console.log(filesData);
                self.setState({
                    fileArray: filesData,
                    currentFile,
                    isrendered: true,
                    isloaded: true,
                    taskid: id,
                    selectedFiles,
                });
            })
            .catch((error) => {
                // handle error
                self.setState({
                    isloaded: true,
                });
                console.log(error);
            })
            .finally(() => {
                // always executed
            });
    };

    onImageClick = async (e) => {
        const fi = e.target.getAttribute("im");
        // eslint-disable-next-line react/no-access-state-in-setstate
        const ffileArray = this.state.fileArray;
        const foundIndex = ffileArray.findIndex((x) => x["url"] === fi);
        await this.setState({
            im: ffileArray[foundIndex],
        });
        this.retain();
    };

    toggleView = async (e) => {
        document.getElementsByClassName("imageFrameBig")[0].style.width =
            "100%";
        await this.setState((prevState) => ({
            overView: !prevState.overView,
        }));
        this.retain();
    };

    retain = async () => {
        localStorage.setItem("localstate", this.state);
    };

    onCancelClick = async (e) => {
        await this.setState({
            im: null,
        });
        this.retain();
    };

    onZoomInClick = async (e) => {
        var size =
            parseInt(
                document
                    .getElementsByClassName("imageFrameBig")[0]
                    .style.width.replace("px")
            ) + 20;
        document.getElementsByClassName("imageFrameBig")[0].style.width =
            size + "px";
        await this.setState({});
        this.retain();
    };

    onZoomOutClick = async (e) => {
        var size =
            parseInt(
                document
                    .getElementsByClassName("imageFrameBig")[0]
                    .style.width.replace("px")
            ) - 20;
        document.getElementsByClassName("imageFrameBig")[0].style.width =
            size + "px";
        await this.setState({});
        this.retain();
    };

    onRotateClick = async (e) => {
        if (this.state.rotationAngle === 0) {
            document.querySelector(".original").style.transform =
                "rotate(180deg)";
            await this.setState({
                rotationAngle: 180,
            });
        } else {
            document.querySelector(".original").style.transform =
                "rotate(0deg)";
            await this.setState({
                rotationAngle: 0,
            });
        }

        this.retain();
    };

    onRotateClick9 = async (e) => {
        document.querySelector(".original").style.transform = "rotate(270deg)";
        await this.setState({});
        this.retain();
    };

    onRejectClick = async (e) => {
        await this.setState({
            rejectAlert: true,
        });
        this.retain();
    };

    onAcceptClick = async (e) => {
        await this.setState({
            acceptAlert: true,
        });
        this.retain();
    };

    onMultipleImageAccept = async (e) => {
        await this.setState({
          acceptMultipleAlert: true,
        });
        this.retain();
    };

    onMultipleImageReject = async (e) => {
        await this.setState({
          rejectMultipleAlert: true,
        });
        this.retain();
    };

    //  onAcceptClickSingle = async (e) =>{
    //   await this.setState({
    //     acceptAlertNew : true
    //    });
    //    let arr = this.state.newArrayData
    //    console.log(arr)

    //    for (let I = 0; I < arr.length; I+=1) {
    //      if ( arr[I].value === 0 ){
    //       arr[I].value = 1
    //      }
    //    }

    // }
    // onRejectClickSingle = async (e) =>{
    //   await this.setState({
    //   rejectAlertNew : true
    //  });
    //  let arr = this.state.newArrayData
    //    console.log(arr)

    //    for (let I = 0; I < arr.length; I+=1) {
    //      if ( arr[I].value === 0 ){
    //       arr[I].value = -1
    //      }
    //    }

    // }

    onThumbClick = async (e, name) => {
        let { selectedFiles } = this.state;
        selectedFiles[name] = !selectedFiles[name];
        await this.setState({
            selectedFiles,
        });
    };

    onNextClick = async (e) => {
        const fi = this.state.im["url"];
        const ffileArray = this.state.fileArray;
        const foundIndex = ffileArray.findIndex((x) => x["url"] === fi);
        if (foundIndex + 1 < ffileArray.length) {
            await this.setState({
                im: ffileArray[foundIndex + 1],
            });
        }
        this.retain();
    };

    onPrevClick = async (e) => {
        const fi = this.state.im["url"];
        const ffileArray = this.state.fileArray;
        const foundIndex = ffileArray.findIndex((x) => x["url"] === fi);
        if (foundIndex - 1 >= 0) {
            await this.setState({
                im: ffileArray[foundIndex - 1],
            });
        }
        this.retain();
    };

    onImageSizePlus = async (e) => {
        const nodeList = document.querySelectorAll(".imageframe .imageDiv");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].style.width = "500px";
            nodeList[i].style.height = "500px";
        }

        await this.setState({});

        this.retain();
    };

    onImageSizeMinus = async (e) => {
        const nodeList = document.querySelectorAll(".imageframe .imageDiv");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].style.width = "320px";
            nodeList[i].style.height = "320px";
        }

        await this.setState({});

        this.retain();
    };

    retain = async () => {
        localStorage.setItem("localstate", this.state);
    };

    onProcessedClick = async (event) => {
        const currentState = this.state.active || false;
        console.log(currentState, event.keyCode);
        switch (event.keyCode) {
            case 80:
                if (!currentState) this.setState({ active: true });
                break;
            case undefined:
                if (!currentState) await this.setState({ active: true });
                break;
            default:
        }
        this.retain();
    };

    onProcessedClickRelease = async (event) => {
        const currentState = this.state.active || false;
        console.log(currentState, event.keyCode);
        switch (event.keyCode) {
            case 80:
                if (currentState) this.setState({ active: false });
                break;
            case undefined:
                if (currentState) await this.setState({ active: false });
                break;
            default:
        }
        this.retain();
    };

    processAll = async () => {
        console.log(this.state.fileArray);
        await this.setState({
            processAlert: true,
        });

        this.retain();
    };

    onAllAccept = async (e) => {
        console.log(e);
        await this.setState({
            processAlert: false,
        });

        let arr = this.state.fileArray;
        //  console.log(this.state.newArrayData)
        //  console.log(arr)

        for (let I = 0; I < arr.length; I += 1) {
            if (arr[I].value === 0) {
                arr[I].value = 1;
                arr[I].comment = this.state.overallComment;
            }
        }

        const payload = {
            artist: "",
            ids: [this.state.taskid],
            notes: arr,
        };
        // console.log(payload)

        let flag = 0;
        let counter = 0;
        for (let I = 0; I < arr.length; I += 1) {
            if (arr[I].value == -1) {
                flag = 1;
                counter = counter + 1;
            }
        }
        payload["counts"] = {
          rejectedCount: counter,
          acceptedCount: arr.length - counter
        };
        if (flag) {
            await axios.post("/api/users/reject", payload).then(() => {
                // console.log(axiosResponse)
                window.location.reload();
            });
        } else {
            // console.log(flag)
            await axios.post("/api/users/updateOrder", payload).then(() => {
                // console.log(axiosResponse)
                window.location.reload();
            });
        }
    };

    onAllReject = async (e) => {
        console.log(e);
        await this.setState({
            processAlert: false,
        });

        const arr = this.state.fileArray;

        for (let I = 0; I < arr.length; I += 1) {
            if (arr[I].value === 0) {
                arr[I].value = -1;
                arr[I].comment = this.state.overallComment;
            }
        }

        const payload = {
            artist: "",
            ids: [this.state.taskid],
            notes: arr,
        };
        console.log(payload);
        let flag = 0;
        let counter = 0;

        for (let I = 0; I < arr.length; I += 1) {
            if (arr[I].value === -1) {
                counter = counter + 1;
                flag = 1;
            }
        } 
        payload["counts"] = {
          rejectedCount: counter,
          acceptedCount: arr.length - counter
        };

        if (flag) {
            await axios.post("/api/users/reject", payload).then(() => {
                // console.log(axiosResponse)
                window.location.reload();
            });
        } else {
            await axios.post("/api/users/updateOrder", payload).then(() => {
                // console.log(axiosResponse)
                window.location.reload();
            });
        }

        //  this.retain();
    };

    render() {
        const self = this;
        // const display = []
        let { fileArray } = self.state;
        // const {currentFile} = self.state
        console.log(fileArray, "dddd");
        fileArray = fileArray || [];
        const validNames = fileArray.reduce((x, y) => {
            return (y["doneFileName"] && 1) * x;
        }, 1);

        return (
            <>
                <div className="content" style={{ minHeight: "700px" }}>
                    {this.state.isloaded ? (
                        <>
                            {!this.state.im ? (
                                <>
                                    <Row>
                                        <Col lg="12" style={{}}>
                                            <Card
                                                className="card-chart"
                                                style={{
                                                    backgroundColor: "#F8F8F8",
                                                }}
                                            >
                                                <CardHeader>
                                                    <CardTitle>
                                                        <h4
                                                            style={{
                                                                color: "#181818	",
                                                                fontWeight:
                                                                    "400",
                                                                fontSize:
                                                                    "40px",
                                                                textAlign:
                                                                    "center",
                                                                margin: "40px",
                                                            }}
                                                        >
                                                            {`Task : ${self.state.taskid}`}
                                                        </h4>
                                                        {validNames ? (
                                                            <>
                                                                <i
                                                                    className="tim-icons icon-cloud-upload-94 uploadall"
                                                                    onClick={
                                                                        this
                                                                            .processAll
                                                                    }
                                                                />
                                                                <button
                                                                    className="btn btn-lg btn-primary imageSizePlus"
                                                                    onClick={
                                                                        this
                                                                            .onImageSizePlus
                                                                    }
                                                                >
                                                                    <i className="tim-icons icon-simple-add" />
                                                                </button>
                                                                <button
                                                                    className="btn btn-lg btn-primary imageSizeMinus"
                                                                    onClick={
                                                                        this
                                                                            .onImageSizeMinus
                                                                    }
                                                                >
                                                                    <i className="tim-icons icon-simple-delete" />
                                                                </button>
                                                                <button
                                                                    className="btn btn-lg btn-success imageSizeAccept"
                                                                    onClick={
                                                                        this
                                                                            .onMultipleImageAccept
                                                                    }
                                                                >
                                                                    <i className="tim-icons icon-check-2" />
                                                                </button>
                                                                <button
                                                                    className="btn btn-lg btn-danger imageSizeReject"
                                                                    onClick={
                                                                        this
                                                                            .onMultipleImageReject
                                                                    }
                                                                >
                                                                    <i className="tim-icons icon-simple-remove" />
                                                                </button>
                                                                {/* <i className = "tim-icons icon-simple-remove red singleselectred"onClick={ this.onRejectClickSingle }role="button"tabIndex="0"/>
                                  <i className = "tim-icons icon-check-2 green singleselectgreen"onClick={ this.onAcceptClickSingle }role="button" tabIndex="0"/> */}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody
                                                    style={{
                                                        textAlign: "center",
                                                        paddingLeft: "75px",
                                                        paddingRight: "75px",
                                                    }}
                                                >
                                                    {fileArray.map(
                                                        (i, index) => {
                                                            // <Image onThumbClick={this.onThumbClick} src={`/cady/${self.state.taskid}-raw/thumb.` + i['url']} srcDone={(`/cady/${self.state.taskid}-done/done-thumb.` + i['url'])} name={i['url']} func={ this.onImageClick } rollnum={index} key={index} acceptValue ={i['value']} comment={i['comment']} onError={(i)=>{ i.target.onerror = null; i.target.src ="https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg"}} />
                                                            if (
                                                                i[
                                                                    "doneFileName"
                                                                ]
                                                            ) {
                                                                return (
                                                                    <Image
                                                                        onThumbClick={
                                                                            this
                                                                                .onThumbClick
                                                                        }
                                                                        // src={`/cady/${self.state.taskid}-raw/thumb.` + i['url']}
                                                                        src={`${i["url"]}`}
                                                                        // srcDone={`/cady/${self.state.taskid}-done/done-thumb.` + i['doneFileName']}
                                                                        srcDone={`${i["doneFileName"]}`}
                                                                        name={
                                                                            i[
                                                                                "url"
                                                                            ]
                                                                        }
                                                                        func={
                                                                            this
                                                                                .onImageClick
                                                                        }
                                                                        rollnum={
                                                                            index
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        orderId={`${self.state.taskid}`}
                                                                        acceptValue={
                                                                            i[
                                                                                "value"
                                                                            ]
                                                                        }
                                                                        comment={
                                                                            i[
                                                                                "comment"
                                                                            ]
                                                                        }
                                                                        onError={(
                                                                            i
                                                                        ) => {
                                                                            i.target.onerror =
                                                                                null;
                                                                            i.target.src =
                                                                                "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg";
                                                                        }}
                                                                        selectValue={
                                                                            this
                                                                                .state
                                                                                .selectedFiles[
                                                                                i[
                                                                                    "url"
                                                                                ]
                                                                            ]
                                                                        }
                                                                    />
                                                                );
                                                            } else {
                                                                return (
                                                                    <Image
                                                                        src={`/cady/noImage.jpeg`}
                                                                        srcDone={`/cady/noImage.jpeg`}
                                                                        name={
                                                                            i[
                                                                                "url"
                                                                            ]
                                                                        }
                                                                        rollnum={
                                                                            index
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                        acceptValue={
                                                                            i[
                                                                                "value"
                                                                            ]
                                                                        }
                                                                        comment={
                                                                            i[
                                                                                "comment"
                                                                            ]
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </CardBody>
                                            </Card>
                                            {/* // )} */}
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Col
                                            lg="10"
                                            style={{
                                                textAlign: "Left",
                                                margin: "auto",
                                                marginTop: "2%",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    width: "50%",
                                                    display: "inline-block",
                                                }}
                                                className={
                                                    (this.state.active
                                                        ? "clicked"
                                                        : "processedTitle") +
                                                    " " +
                                                    (this.state.overView
                                                        ? "themedTitle"
                                                        : "")
                                                }
                                            >
                                                <div>
                                                    <b>Original</b>
                                                </div>
                                                <div>
                                                    {self.state.im["url"]}
                                                </div>
                                            </span>
                                            <span
                                                style={{
                                                    width: "50%",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <div>
                                                    <b>Processed</b>
                                                </div>
                                                <div>
                                                    {
                                                        self.state.im[
                                                            "doneFileName"
                                                        ]
                                                    }
                                                </div>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="qa-icons">
                                            <div
                                                className="cancel-btn"
                                                onClick={this.onCancelClick}
                                                onKeyDown={this.onCancelClick}
                                                role="button"
                                                tabIndex="0"
                                            >
                                                <i className="tim-icons icon-simple-remove" />
                                            </div>
                                        </div>

                                        <Col lg="10" style={{ margin: "auto" }}>
                                            <div
                                                className={
                                                    "imageFrameBig " +
                                                    (this.state.overView
                                                        ? "themed"
                                                        : "")
                                                }
                                                role="button"
                                                onKeyDown={
                                                    this.onProcessedClick
                                                }
                                                onKeyUp={
                                                    this.onProcessedClickRelease
                                                }
                                                onMouseDown={
                                                    this.onProcessedClick
                                                }
                                                onMouseUp={
                                                    this.onProcessedClickRelease
                                                }
                                            >
                                                {self.state.im["value"] ===
                                                1 ? (
                                                    <i className="tim-icons icon-check-2 green iconindicator" />
                                                ) : self.state.im.value ===
                                                  -1 ? (
                                                    <i className="tim-icons icon-simple-remove red iconindicator customTooltip" />
                                                ) : (
                                                    <></>
                                                )}
                                                <span className="tooltiptext">
                                                    <p>
                                                        <b>
                                                            Processed Image
                                                            Issue
                                                        </b>
                                                    </p>
                                                    <p>
                                                        {
                                                            self.state.im[
                                                                "comment"
                                                            ]
                                                        }
                                                    </p>
                                                </span>
                                                <i className="tim-icons icon-alert-circle-exc green iconindicator customTooltip cog" />
                                                <span className="tooltiptext">
                                                    <div className="Image2HW">
                                                        <p>
                                                            <b>
                                                                Processed Image
                                                                Stats
                                                            </b>{" "}
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            ImageHeight :
                                                            {
                                                                self.state.im[
                                                                    "height"
                                                                ]
                                                            }
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            ImageWidth :{" "}
                                                            {
                                                                self.state.im[
                                                                    "width"
                                                                ]
                                                            }
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            resolutionX :{" "}
                                                            {
                                                                self.state.im[
                                                                    "resolutionX"
                                                                ]
                                                            }
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            resolutionY :{" "}
                                                            {
                                                                self.state.im[
                                                                    "resolutionY"
                                                                ]
                                                            }
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            Profile :{" "}
                                                            {
                                                                self.state.im[
                                                                    "color"
                                                                ]
                                                            }
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            Ratio :{" "}
                                                            {parseFloat(
                                                                self.state.im[
                                                                    "Ratio"
                                                                ]
                                                            ).toFixed(2)}
                                                        </p>
                                                    </div>
                                                </span>

                                                <img
                                                    // src={`/cady/${self.state.taskid}-done/`+ self.state.im['doneFileName'] }
                                                    src={`/api/getfiles/processedImage/${self.state.taskid}/${self.state.im["doneFileName"]}`}
                                                    alt=""
                                                    className={
                                                        this.state.active
                                                            ? "processed clicked"
                                                            : "processed"
                                                    }
                                                />
                                                <img
                                                    src={`/api/getfiles/image/${self.state.taskid}/${self.state.im["url"]}`}
                                                    // src={`/cady/${self.state.taskid}-raw/`+ self.state.im['url'] }
                                                    alt=""
                                                    className={
                                                        this.state.overView
                                                            ? this.state.active
                                                                ? "originalnew newclicked"
                                                                : "originalnew"
                                                            : "original"
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            bottom: "0",
                                            position: "fixed",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            zIndex: "600",
                                        }}
                                    >
                                        <Col
                                            lg="8"
                                            style={{ marginLeft: "20%" }}
                                        >
                                            <div className="action-icons">
                                                <div className="cancel-btn">
                                                    <i
                                                        className="tim-icons icon-minimal-left blue"
                                                        onClick={
                                                            this.onPrevClick
                                                        }
                                                        onKeyDown={
                                                            this.onPrevClick
                                                        }
                                                        role="button"
                                                        tabIndex="0"
                                                    />
                                                    <i
                                                        className="tim-icons icon-simple-remove red"
                                                        onClick={
                                                            this.onRejectClick
                                                        }
                                                        onKeyDown={
                                                            this.onRejectClick
                                                        }
                                                        role="button"
                                                        tabIndex="0"
                                                    />
                                                    <i
                                                        className="tim-icons icon-check-2 green"
                                                        onClick={
                                                            this.onAcceptClick
                                                        }
                                                        onKeyDown={
                                                            this.onAcceptClick
                                                        }
                                                        role="button"
                                                        tabIndex="0"
                                                    />
                                                    <i
                                                        className="tim-icons icon-minimal-right blue"
                                                        onClick={
                                                            this.onNextClick
                                                        }
                                                        onKeyDown={
                                                            this.onNextClick
                                                        }
                                                        role="button"
                                                        tabIndex="0"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {this.state.rejectAlert && (
                    <SweetAlert
                        input
                        showCancel
                        cancelBtnBsStyle="light"
                        title="Reject"
                        placeHolder="Add Comment"
                        onConfirm={async (response) => {
                            const fi = self.state.im["url"];
                            const ffileArray = self.state.fileArray;
                            const foundIndex = ffileArray.findIndex(
                                (x) => x["url"] === fi
                            );
                            ffileArray[foundIndex]["value"] = -1;
                            ffileArray[foundIndex]["comment"] = response;
                            await this.setState({
                                fileArray: ffileArray,
                                rejectAlert: false,
                            });
                            this.onNextClick();
                        }}
                        onCancel={() => {
                            this.setState({
                                rejectAlert: false,
                            });
                        }}
                    >
                        Add Comment
                    </SweetAlert>
                )}
                {this.state.rejectMultipleAlert && (
                    <SweetAlert
                        input
                        showCancel
                        cancelBtnBsStyle="light"
                        title="Reject"
                        placeHolder="Add Comment for Multiple images"
                        onConfirm={async (response) => {
                            const ffileArray = self.state.fileArray;
                            const selectedFiles = self.state.selectedFiles;
                            for (
                                let index = 0;
                                index < ffileArray.length;
                                index++
                            ) {
                                const element = ffileArray[index];
                                if (selectedFiles[element["url"]]) {
                                    ffileArray[index]["value"] = -1;
                                    ffileArray[index]["comment"] = response;
                                    selectedFiles[element["url"]] = false
                                }
                            }
                            await this.setState({
                                fileArray: ffileArray,
                                rejectMultipleAlert: false,
                                selectedFiles
                            });
                        }}
                        onCancel={() => {
                            this.setState({
                              rejectMultipleAlert: false,
                            });
                        }}
                    >
                        Add Comment
                    </SweetAlert>
                )}
                {this.state.acceptAlert && (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Accepted!"
                        onConfirm={async () => {
                            const fi = self.state.im["url"];
                            const ffileArray = self.state.fileArray;
                            const foundIndex = ffileArray.findIndex(
                                (x) => x["url"] === fi
                            );
                            ffileArray[foundIndex]["value"] = 1;
                            await this.setState({
                                fileArray: ffileArray,
                                acceptAlert: false,
                            });
                            this.onNextClick();
                        }}
                    >
                        File has been accepted
                    </SweetAlert>
                )}
                {this.state.acceptMultipleAlert && (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Accepted!"
                        onConfirm={async () => {
                            const ffileArray = self.state.fileArray;
                            const selectedFiles = self.state.selectedFiles;
                            for (
                                let index = 0;
                                index < ffileArray.length;
                                index++
                            ) {
                                const element = ffileArray[index];
                                if (selectedFiles[element["url"]]) {
                                    ffileArray[index]["value"] = 1;
                                    ffileArray[index]["comment"] = "";
                                    selectedFiles[element["url"]] = false
                                }
                            }
                            await this.setState({
                                fileArray: ffileArray,
                                acceptMultipleAlert: false,
                                selectedFiles
                            });
                        }}
                    >
                        Files have been accepted
                    </SweetAlert>
                )}
                {this.state.processAlert && (
                    <SweetAlert
                        // input
                        showCancel
                        cancelBtnBsStyle="light"
                        title="Process Order"
                        // placeHolder="Add Comment"
                        customButtons={
                            <>
                                <Input
                                    id="commentOverall"
                                    placeholder="Enter comment"
                                    onBlur={async (e) => {
                                        console.log(e.target.value);
                                        this.setState({
                                            overallComment: e.target.value,
                                        });
                                    }}
                                />
                                <button
                                    className="btn btn-lg btn-primary"
                                    onClick={this.onAllAccept}
                                >
                                    Accept All/Remaining{" "}
                                </button>
                                <button
                                    className="btn btn-lg btn-secondary"
                                    onClick={this.onAllReject}
                                >
                                    Reject All/Remaining
                                </button>
                            </>
                        }
                        onConfirm={async (response) => {
                            // this is not working anyways. dont change it
                            // const fi = self.state.im['url'];
                            // const ffileArray = self.state.fileArray;
                            // const foundIndex = ffileArray.findIndex(x => x['url'] === fi);
                            // ffileArray[foundIndex]['value'] = -1;
                            // ffileArray[foundIndex]['comment'] = response;
                            // await this.setState({
                            //   fileArray : ffileArray,
                            //   rejectAlert : false
                            // })
                            // this.onNextClick()
                            this.setState({
                                processAlert: false,
                            });
                        }}
                        onCancel={() => {
                            this.setState({
                                processAlert: false,
                            });
                        }}
                    >
                        Add Comment
                    </SweetAlert>
                )}
            </>
        );
    }
}
export default Order;
