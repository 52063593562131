import React from "react";

import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col,
  } from "reactstrap";

const OrderStats = ({props}) =>{

    const {totalOrders, totalRedos, pendingOrders, tasksDumped}=props;
    return <Row>
        <Col lg="3" md="6">
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col xs="5">
            <div className="info-icon text-center icon-warning">
              <i className="tim-icons icon-delivery-fast" />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">Total Recieved</p>
              <CardTitle tag="h3">
                {totalOrders || "-"}
              </CardTitle>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <i className="tim-icons icon-trophy" />
          Recieved yet
        </div>
      </CardFooter>
    </Card>
    </Col>
    <Col lg="3" md="6">
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col xs="5">
            <div className="info-icon text-center icon-primary">
              <i className="tim-icons icon-shape-star" />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">Total Redos</p>
              <CardTitle tag="h3">
                {totalRedos || "-"}
              </CardTitle>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <i className="tim-icons icon-refresh-01" />
          Resent by client
        </div>
      </CardFooter>
    </Card>
    </Col>
    <Col lg="3" md="6">
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col xs="5">
            <div className="info-icon text-center icon-danger">
              <i className="tim-icons icon-alert-circle-exc" />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">Total Pending</p>
              <CardTitle tag="h3">
                {pendingOrders || "-"}
              </CardTitle>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <i className="tim-icons icon-sound-wave" />
          orders productionStatus != 2
        </div>
      </CardFooter>
    </Card>
    </Col>
    <Col lg="3" md="6">
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col xs="5">
            <div className="info-icon text-center icon-success">
              <i className="tim-icons icon-molecule-40" />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">Total Dumped</p>
              <CardTitle tag="h3">{tasksDumped}</CardTitle>
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <hr />
        <div className="stats">
          <i className="tim-icons icon-watch-time" />
          Errored tasks
        </div>
      </CardFooter>
    </Card>
    </Col>
    </Row>
    
}


export default OrderStats;