/* eslint-disable no-console */
const axios = require("axios");

const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
];

const returnGraphMonthly = (data = []) => {
    const graphData = [];
    const columns = [];
    data.forEach((element) => {
        graphData.push(element.count);
        columns.push(months[element.month - 1]);
    });
    //   console.log(data, graphData, "000000000");
    return (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); // blue colors

        return {
            labels: columns,
            datasets: [{
                label: "Order count",
                fill: true,
                backgroundColor: gradientStroke,
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: graphData,
            }, ],
        };
    };
};

const returnGraphMonthlySales = (data = []) => {
    const replaces = {
        "Most Popular": "MP",
        "Most Popular-ArtistTouch": "MP-AT",
        "Traditional-ArtistTouch": "Trad-AT",
        Traditional: "Trad",
    };
    const graphData = [];
    const columns = [];
    data.forEach((element) => {
        graphData.push(element.count);
        columns.push(replaces[element.type] || element.type);
    });

    // console.log(graphData);
    // console.log(columns);

    return (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); // purple colors

        return {
            labels: columns,
            datasets: [{
                label: "Order Count",
                fill: true,
                backgroundColor: gradientStroke,
                hoverBackgroundColor: gradientStroke,
                borderColor: "#d048b6",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: graphData,
            }, ],
        };
    };
};

// const returnGraphMonthlyTasks = (data = []) => {
//   const graphData = [];
//   const columns = [];
//   data.forEach((element) => {
//     graphData.push(element.elite);
//     columns.push(months[element.month - 1]);
//   });
//   return (canvas) => {
//     const ctx = canvas.getContext("2d");
//     const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
//     gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
//     gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); // green colors
//     gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); // green colors

//     return {
//       labels: columns,
//       datasets: [
//         {
//           label: "Order Count",
//           fill: true,
//           backgroundColor: gradientStroke,
//           borderColor: "#00d6b4",
//           borderWidth: 2,
//           borderDash: [],
//           borderDashOffset: 0.0,
//           pointBackgroundColor: "#00d6b4",
//           pointBorderColor: "rgba(255,255,255,0)",
//           pointHoverBackgroundColor: "#00d6b4",
//           pointBorderWidth: 20,
//           pointHoverRadius: 4,
//           pointHoverBorderWidth: 15,
//           pointRadius: 4,
//           data: [60, 27, 60, 12, 80],
//         },
//       ],
//     };
//   };
// };

const fetchApi = async() => {

    // var self = this; const rl = (message) => {     let newMessage =
    // message.replace(/[\r\n]+/gm, "");     return newMessage.replace(/["]+/gm,
    // "'"); }
    try {
        return (await axios.get("/graph/tasks")).data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

const setKpiStats = async(data) => {
    let count = 0
    let taskData = []
    taskData = data
    for (let i = 0; i < taskData.length; i += 1) {
        count += taskData[i].count
    }
    return count
};

const setfilesStats = async(data) => {
    let fileCount = 0
    let taskData = []
    taskData = data
    for (let i = 0; i < taskData.length; i += 1) {
        fileCount += taskData[i].fileCount
    }
    return fileCount


};

// const fetchTasksApi = async () => {
//   // var self = this; const rl = (message) => {     let newMessage =
//   // message.replace(/[\r\n]+/gm, "");     return newMessage.replace(/["]+/gm,
//   // "'"); }
//   try {
//     return (await axios.get("/api/tasks")).data;
//   } catch (error) {
//     // eslint-disable-next-line no-console
//     console.log(error);
//     return [];
//   }
// };

const makeMainChartLabels = async(days = 30) => {
    const now = new Date();
    const labels = [];
    now.setDate(now.getDate() - days);

    for (let index = 0; index < days + 1; index += 1) {
        labels.push(`${months[now.getMonth()]} ${now.getDate()}`);
        now.setDate(now.getDate() + 1);
    }
    // console.log('there are', labels)
    return labels;
};
const makeMainChartData = async(records, days = 30) => {
    const now = new Date();
    const dataset = [];
    const dataset2 = [];
    now.setDate(now.getDate() - days);

    for (let index = 0; index < days + 1; index += 1) {
        const record = records.filter(
            // eslint-disable-next-line no-shadow
            (record) =>
            `${record.month}` === `${now.getMonth() + 1}` &&
            `${record.day}` === `${now.getDate()}`
        );
        if (!record[0]) {
            dataset.push(0);
            dataset2.push(0);
        } else {
            // console.log(record)
            dataset.push(record[0].count || 0);
            dataset2.push((record[0].fileCount || 0) / 10.0);
        }
        now.setDate(now.getDate() + 1);
    }
    return [dataset, dataset2];
};

const returnGraphData = (dataset, labels) => (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)");
    // eslint-disable-next-line no-console
    // console.log(dataset);
    return {
        labels: JSON.parse(JSON.stringify(labels)),
        datasets: [{
                label: "Order Count",
                fill: true,
                backgroundColor: gradientStroke,
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: JSON.parse(JSON.stringify(dataset[0])),
            },
            {
                label: "File Count( x 10)",
                fill: true,
                backgroundColor: gradientStroke,
                borderColor: "#C22AA7",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#C22AA7",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#C22AA7",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: JSON.parse(JSON.stringify(dataset[1])),
            },

        ],
    };
};
const getGraphStats = async() => {
    // const tasksData = await fetchTasksApi();
    const data = await fetchApi();
    const labels = await makeMainChartLabels();
    const records = await makeMainChartData(data.daily);
    const recordsOut = await makeMainChartData(data.dailyOut);
    const recordsRedo = await makeMainChartData(data.dailyRedo);

    const newData = returnGraphData(records, labels, "Order Count");
    const newData1 = returnGraphData(recordsOut, labels, "Order Count");
    const newData2 = returnGraphData(recordsRedo, labels, "Order Count");
    const graphMonthly = returnGraphMonthly(data.monthly);
    const maxOrders = data.maxOrders || "-";
    const totalOrders = data.totalOrders || "-";
    const pendingOrders = data.pendingOrders || "-";
    const totalFilesProcessed = data.totalFilesProcessed || "-";
    // const monthlyOrders = data.monthlyOrders || [];
    const orderTypeStats = data.orderTypeStats || [];
    const filesPerOrder = data.filesPerOrder || [];
    const tasksDumped = data.tasksDumped || "-";
    const todayOrders = data.todayOrders || "-";
    const totalRedos = data.totalRedos || "-";
    const lastMonth = data.monthly[data.monthly.length - 1].count || "-";
    const lastMonthSalesTypes = returnGraphMonthlySales(data.typeCount);
    const receivedLastMonth = await setKpiStats(data.receivedLastMonth);
    const deliveredLastMonth = await setKpiStats(data.deliveredLastMonth);

    const receivedMonthly = await setKpiStats(data.kpi_ordersReceived);
    const deliveredMonthly = await setKpiStats(data.kpi_ordersDelivered);
    const redosMonthly = await setKpiStats(data.kpi_redoOrders);
    const currentPendingOrders = await setKpiStats(data.kpi_currentPending);
    const totalPendingOrders = await setKpiStats(data.kpi_totalPending);
    const totalPendingOrdersFiles = await setfilesStats(data.kpi_totalPending);
    const redoPendancy = await setKpiStats(data.kpi_currentRedoPending);
    const totalRedoPendancy = await setKpiStats(data.kpi_totalRedoPending);
    const totalRedoPendancyFiles = await setfilesStats(data.kpi_totalRedoPending);
     // const pending12Hrs = await data.currentPending12Hrs;
    const pending12Hrs = await setKpiStats(data.pending12HrsOrders);



    const pending12HrsDetails = await setKpiStats(data.pending12HrsOrdersDetails);
    // const pending18Hrs = await data.currentPending18Hrs;
    const pending18Hrs = await setKpiStats(data.pending18HrsOrders);
    const pending18HrsDetails = await setKpiStats(data.pending18HrsOrdersDetails);
    // const pending24Hrs = await data.currentPending24Hrs;
    const pending24Hrs = await setKpiStats(data.pending24HrsOrders);

    const pending24HrsDetails = await setKpiStats(data.pending24HrsOrdersDetails);

    const filesCount24Hr = await setfilesStats(data.filesCount24Hr);
    const filesCount48Hr = await setfilesStats(data.filesCount48Hr);
    const filesCount12Hr = await setfilesStats(data.filesCount12Hr);
    // const pending48Hrs = await data.currentPending48Hrs;
    const pending48Hrs = await setKpiStats(data.pending48HrsOrders);
    const pending48HrsDetails = await setKpiStats(data.pending48HrsOrdersDetails);

    return {
        newData,
        newData1,
        newData2,
        maxOrders,
        totalOrders,
        pendingOrders,
        graphMonthly,
        orderTypeStats,
        filesPerOrder,
        totalFilesProcessed,
        tasksDumped,
        todayOrders,
        totalRedos,
        lastMonth,
        lastMonthSalesTypes,
        receivedMonthly,
        deliveredMonthly,
        redosMonthly,
        currentPendingOrders,
        redoPendancy,
        totalRedoPendancy,
        totalRedoPendancyFiles,
        receivedLastMonth,
        deliveredLastMonth,
        totalPendingOrders,
        totalPendingOrdersFiles,
        pending12Hrs,
        pending18Hrs,
        pending24Hrs,
        pending48Hrs,
        pending12HrsDetails,
        pending18HrsDetails,
        pending24HrsDetails,
        pending48HrsDetails,
        filesCount24Hr,
        filesCount48Hr,
        filesCount12Hr,
    };
};

// ######################################### // // used inside
// src/views/Dashboard.js #########################################

export default getGraphStats; // in src/views/Dashboard.j