import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import { Provider } from "react-redux";
import store from "./store";
import "./assets/scss/black-dashboard-pro-react.scss";
import "./assets/css/nucleo-icons.css";
import "./assets/scss/react-docs.scss";
import "./assets/scss/docs.scss";
import "./assets/demo/demo.css";

// import "./assets/css/1.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";


// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(
  <Provider store={store}>
    {" "}
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
