/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://photo-wonder.com/product/black-dashboard-pro-react
* Copyright 2020 Photo-Wonder (https://photo-wonder.com)

* Coded by Photo-Wonder

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {
    Container,
    Row
} from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
    render() {
        return ( <footer className = {
                "footer" + (this.props.default ? " footer-default" : "")
            } >
            <Container fluid = {
                this.props.fluid ? true : false
            } >
            {/* <ul className = "nav" >
            <li className = "nav-item" >
            <a className = "nav-link"
            href = "https://photo-wonder.com" >
            Photo-Wonder </a> </li>{" "} <li className = "nav-item" >
            <a className = "nav-link"
            href = "https://photo-wonder.com/presentation" >
            About us </a> </li>{" "} <li className = "nav-item" >
            <a className = "nav-link"
            href = "https://blog.photo-wonder.com" >
            Blog </a> </li> </ul>  */}
            <div className = "copyright" > ©{
                new Date().getFullYear()
            }
            made with {
                " "
            } <i className = "tim-icons icon-heart-2" / > by {
                " "
            } <a href = "https://photo-wonder.com/"
            target = "_blank" >
            Photo-Wonder </a>{" "}
            for a better web. </div> </Container> </footer>
        );
    }
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool
};

export default Footer;