/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
import React, { Component } from "react";
import { UncontrolledCollapse,Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// import NestedList from "./NestedList";


const axios = require("axios");

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPanel: [],
      taskData: [],
      isrendered: false,
      isloaded: false,
      open: true,
      setOpen: true,
      display : [],
      ordersData : []
    };
  }

  componentDidMount() {
    const path = window.location.pathname.match("[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
    let emailid;
    // alert(path)
    const self = this;
    if (path) {
    emailid = path;
    this.fetchApi(emailid);
    } else {
      emailid = "";
      self.setState({
        isloaded: true,
      });
    }
  }

  fetchApi = async (emailid) => {
    const self = this;
    axios
      .get(`/api/tasks/assigned/${emailid}`)
      .then((response) => {
        if(response.data.data.task[0]){
        const taskData = response.data.data.task;
        this.setTableData(taskData)
        self.setState({
          taskData,
          isrendered: true,
          isloaded: true,
        });
        console.log('done')
        }
      })
      .catch((error) => {
        // handle error
        self.setState({
          isloaded: true,
        });
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  };

  
  setTableData = async (orderList) => {
    const orderData = orderList;
    const titleData =[];

    for (let i = 0; i < orderData.length; i += 1) {
        const titleobj = {"title":orderData[i].ProductionTaskId}
        titleData.push(titleobj)
        
        Object.keys(titleData[i] || [] ).forEach((key)=> {
          this.state.display.push(this.makeList(key,titleData[i][key]))
        });
        Object.keys(orderData[i] || [] ).forEach((key)=> {
          this.state.display.push(this.makeList(key,orderData[i][key]))
        });
        } 
    }


  makeList = (key,data,i=0) => {
    if(key ==='title'){
    const val = <span>{`${data}`}</span>
    return(<tr> <td style={{
      fontWeight:'600',
      width:'200px',
      backgroundColor : '#d1e0e0',
      color : ' #000000',
      fontSize:'15px'}}  ref={(el) => {
        if (el) {
          el.style.setProperty('color', '#333', 'important');
          }
        }}> <div style={{color: 'black',marginLeft:'370px'}}>Order Id : {val}</div>
       </td></tr>)
     }
    else if ((typeof(data) === 'string' || typeof(data) === 'number') && !['_id'].includes(key) && !['__v'].includes(key) ){
      let val = <span style={{fontWeight:"400",marginLeft:'15px'}}>{`${data}`}</span>
          if (key === 'URL'){
            val = <Link to="route" onClick={(event) => {event.preventDefault(); window.open(`${data}` );}} >{val}</Link>
          }
          return (<tr>
            <td style={{
              fontWeight:'600',
              width:'200px',
              fontSize:'15px'
            }}
            ref={(el) => {
              if (el) {
                el.style.setProperty('color', '#333', 'important');
                }
              }}
            >
              {`${key}:`}{val}
            </td>
            {/* <td
            ref={(el) => {
              if (el) {
                el.style.setProperty('color', '#333', 'important');
                }
              }}
            >
              {`${data}`}
            </td> */}
          </tr>)
      }
      else if(typeof(data) === 'object') {
        console.log(key);
        let tempStyle=null
        if (key==='actionLogs'){
          tempStyle={
            marginLeft: "15px"
          }
        }
      return(
        <tr>
          <td>
          <span 
          id={`key-${key}`}
          style={{
            fontWeight:'600',
            width:'200px',
            fontSize:'15px',
            cursor: 'pointer'
          }}

          ref={(el) => {
              if (el) {
                el.style.setProperty('color', '#333', 'important');
                }
              }}
           ><i className="tim-icons icon-bullet-list-67" style={{
                marginRight: '5px',
                fontSize: '12px',
                color: 'gray',
                fontWeight: '800',
           }} /> {`${key}`}</span>
          <UncontrolledCollapse toggler={`key-${key}`}>
            <table className="table" style={tempStyle}>
                        <tbody>
                          {data && data.map((d) => {
                            console.log(d)
                            if(typeof(d)==='string'){
                              return (
                                <tr>
                                    <td style={{
                                      fontWeight:'600',
                                      width:'200px',
                                      fontSize:'15px'
                                    }}
                                    ref={(el) => {
                                      if (el) {
                                        el.style.setProperty('color', '#333', 'important');
                                        }
                                      }}
                                    >
                                      {`${d}:`}
                                    </td>
                                  </tr>)
                            }
                              const x=[]
                              Object.keys(d).forEach((k2)=>{
                                x.push(this.makeList(k2  ,d[k2] ))
                              })
                              if(tempStyle){
                                x.push(<tr ><td><hr /></td></tr>)
                               }
                              return x
      })}
                        </tbody>
                      </table>

        </UncontrolledCollapse>
        </td>
        </tr>
        
      )
    }
    return <></>
  }

  render() {
    const self = this
    // this.setTableData();

    return (
      <>
        <div className="content" style={{ minHeight: "700px" }}>
          {!this.state.isloaded ? (
            <div style={{ margin: "100px 450px 150px" }} />
          ) : (
            <Row>
              <Col lg="8" style={{ marginTop: "5%", marginLeft: "20%" }}>
                {!this.state.isrendered ? (
                  <div style={{ 
                    background:"white", color: "white" }}>
                    <b style={{ marginLeft: "30%" }}>
                      Order ID given in the url is not valid !
                    </b>
                  </div>
                ) : (
                  <Card
                    className="card-chart"
                    style={{
                      backgroundColor: "#F8F8F8",
                      boxShadow: "5px 5px 5px 5px #888888",
                    }}
                  >
                    <CardHeader>
                      <CardTitle>
                        <h4
                          style={{
                            color: "#181818	",
                            marginLeft: "40%",
                            fontWeight: "660",
                          }}
                        >
                          {/* {`Task : ${self.state.taskData.ProductionTaskId}`} */}
                          Total Orders Count : {self.state.taskData.length}
                        </h4>
                      </CardTitle>
                    </CardHeader>
                    <CardBody style={{ marginLeft: "6%" }}>
                      <table className="table">
                          {this.state.display.map((i) => (
                              i
                          ))}
                      </table>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}
export default Order;
