// import React from 'react'
// import { Link } from "react-router-dom";
// import icons from '../../../variables/icons';

const columns = [
    {
        title: "ProductionTaskId",
        field: "ProductionTaskId",
    },
    // {
    //     title: "Assignee",
    //     field: "assignee",
    // },
    {
        title: 'SalesType',
        field: 'SalesType'
    },    
//    {
//        title: 'Origin',
//        field: 'origin'
//    },
    // {
    //     title: 'Service',
    //     field: 'service'
    // },
    // {
    //     title: 'Role',
    //     field: 'role'
    // },
    {
        title: "createdAt",
        field: "createdAt",
    },
    {
        title: "Count",
        field: "Count",
    },
    {
        title: 'BilledForType',
        field: 'BilledForType'
    },
    {
        title: 'BilledForCount',
        field: 'BilledForCount'
    }
]

export default columns;
