// import React from 'react'
// import { Link } from "react-router-dom";
// import icons from '../../../variables/icons';

const columns = [
    {
        title: "Date",
        field: "time",
    },
    // {
    //     title: "Assignee",
    //     field: "assignee",
    // },
    {
        title: 'Action',
        field: 'action'
    },    
//    {
//        title: 'Origin',
//        field: 'origin'
//    },
    // {
    //     title: 'Service',
    //     field: 'service'
    // },
    // {
    //     title: 'Role',
    //     field: 'role'
    // },
    {
        title: "Name",
        field: "assigneeName",
    },
    {
        title: "Assignee Type",
        field: "assigneeType",
    },
    {
        title: "SalesType",
        field: "salesType",
    },
    {
        title: 'Image Count',
        field: 'imageCount'
    },
    {
        title: 'Non-Image Count',
        field: 'nonImageCount'
    },

]


export default columns;
