/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */

import React, { Component } from "react";
import { UncontrolledCollapse,Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import LogsTable from "./LogsTable";
import {
  Button,
  Table,
  UncontrolledTooltip,
  ButtonGroup,
  FormGroup,
  Label,
  Input
} from "reactstrap";
const axios = require("axios");

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPanel: [],
      taskData: [],
      isrendered: false,
      isloaded: false,
      open: true,
      setOpen: true,
      logData: [],
    };
  }

  componentDidMount() {
    const path = window.location.pathname.replace(/\/$/, "").match(/[a-zA-Z0-9]+$/);
    let taskid;
    const self = this;
    if (path) {
      taskid = path[0];
    this.fetchApi(taskid);
    this.fetchApiLogs(taskid)
    } else {
      taskid = "";
      self.setState({
        isloaded: true,
      });
    }
  }

  fetchApi = async (id) => {
    const self = this;
    console.log(id)
    axios
      .get(`/api/tasks/${id}`)
      .then((response) => {
        const taskData = response.data.data.task[0];
        self.setState({
          taskData,
          isrendered: true,
          isloaded: true,
        });
        console.log('done')
      })
      .catch((error) => {
        // handle error
        self.setState({
          isloaded: true,
        });
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  };

  fetchApiLogs = async (id) => {
    const self = this;
    console.log(id)
    axios
      .get(`/api/tasks/orderLogs/${id}`)
      .then((response) => {
        const logData = response.data.data.logs;
        self.setState({
          logData,
        });
        console.log('done')
        console.log(logData,'--------------------------------')
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  };

  makeList = (key,data,stage='',i=0) => {
    // let color='gray'
    if( stage === 'rejected'){
      // color='red'
    }
    if ((typeof(data) === 'string' || typeof(data) === 'number') && !['_id'].includes(key) ){
      let val = <span style={{fontWeight:"400",marginLeft:'15px'}}>{`${data}`}</span>
          if (key === 'URL'){
            val = <Link to="route" onClick={(event) => {event.preventDefault(); window.open(`${data}` );}} >{val}</Link>
          }
          if(key === 'issues' && !(stage === 'rejected')){
            return;
          }
          return (<tr>
            <td style={{
              fontWeight:'600',
              width:'200px',
              fontSize:'15px'
            }}
            ref={(el) => {
              if (el) {
		      el.style.setProperty('color', '#333', 'important');
                }
              }}
            >
              {`${key}:`}{val}
            </td>

          </tr>)
      }
      else if(typeof(data) === 'object') {
        console.log(key);
        let tempStyle=null
        if (key==='actionLogs'){
          return;
          // tempStyle={
          //   marginLeft: "15px"
          // }
        }
      return(
        <tr>
          <td>
          <span 
          id={`key-${key}`}
          style={{
            fontWeight:'600',
            width:'200px',
            fontSize:'15px',
            cursor: 'pointer',
            color: 'red'
          }}

          ref={(el) => {
            if (el && key === 'issues' && stage !== 'rejected') {
              el.style.setProperty('color', '#888', 'important');
              }
            else if (el) {
                // el.style.setProperty('color', '#333', 'important');
                }
              }}
           ><i className="tim-icons icon-bullet-list-67" style={{
                marginRight: '5px',
                fontSize: '12px',
                color: 'gray',
                fontWeight: '800',
           }} /> {`${key}`}</span>
          <UncontrolledCollapse toggler={`key-${key}`}>
            <table className="table" style={tempStyle}>
                        <tbody>
                          { data && data.map((d) => {
                            d.value === -1 &&
                            console.log(d)
                            
                            if(typeof(d)==='string'){
                              return (
                                <tr>
                                    <td style={{
                                      fontWeight:'600',
                                      width:'200px',
                                      fontSize:'15px'
                                    }}
                                    ref={(el) => {
                                      if (el) {
                                        el.style.setProperty('color', '#333', 'important');
                                        }
                                      }}
                                    >
                                      {`${d}:`}
                                    </td>
                                  </tr>)
                            }
                              let x= []
                              let  new_d = d
				if (stage === 'rejected')
				  {
                              if(key==='issues'){
                                const {url,value,comment} = d
                                new_d = {url,value,comment}
                              } }

                              //var a =  ['Files', 'actionLogs', 'SceneCounts', 'processed','Error']
                              //key = a
                              let test = (key === 'issues')|| (key === 'Files') || (key === 'actionLogs') ||(key === 'SceneCounts') ||(key === 'processed') ||(key === 'Error')
                               test && Object.keys(new_d).forEach((k2) => {
                                    x.push(this.makeList(k2, new_d[k2]))
                                })
                              if(tempStyle){
                                x.push(<tr ><td><hr /></td></tr>)
                               }
                              return x
                          })}
                        </tbody>
                      </table>

        </UncontrolledCollapse>
        </td>
        </tr>
      )
    }
    return <></>
  }

  render() {
    const self = this
    const display = []
    const {taskData} = self.state
    let {rejectionNotes} = taskData
    let filteredNotes = []
    // rejectionNotes = rejectionNotes.filter((
    if (rejectionNotes){
      for (let index = 0; index < rejectionNotes.length; index++) {
        const element = rejectionNotes[index];
        if(element.value === -1 ){
          filteredNotes.push(element)
        }
      }
    }
    
    const stage = taskData['stage']
    delete taskData['__v']
    delete taskData['_id']
    Object.keys(taskData || [] ).forEach((key)=> {
      display.push(self.makeList(key,taskData[key],stage))
    });
    const t = taskData['Files'] || []
    let l = 0
    if (t){ 
       l=t.length
    }
    for (let index = 0; index < l; index +=1 ) {
      const element = t[index];
      // console.log(element)      
    }

    return (
      <>
        <div className="content" style={{ minHeight: "700px" }}>
          {!this.state.isloaded ? (
            <div style={{ margin: "100px 450px 150px" }} />
          ) : (
            <>
            <Row>
              <Col lg="8" style={{ marginTop: "5%", marginLeft: "20%" }}>
                {!this.state.isrendered ? (
                  <div style={{ 
                    background:"white", color: "white" }}>
                    <b style={{ marginLeft: "30%" }}>
                      Order ID given in the url is not valid !
                    </b>
                  </div>
                ) : (
                  <Card
                    className="card-chart"
                    style={{
                      backgroundColor: "#F8F8F8",
                      // boxShadow: "5px 5px 5px 5px #888888",
                    }}
                  >
                    <CardHeader>
                      <CardTitle>
                        <h4
                          style={{
                            color: "#181818	",
                            marginLeft: "40%",
                            fontWeight: "660",
                          }}
                        >
                          {`Task : ${self.state.taskData.ProductionTaskId}`}
                        </h4>
                      </CardTitle>
                    </CardHeader>
                    <CardBody style={{ marginLeft: "4%", marginRight:"4%" }}>
 
                    <div className="">
                      {this.state.logData.length ? (<LogsTable logData={this.state.logData}/>):<></>}


    {filteredNotes.length ? (
<>
    <th className="text-justified" style={{color:"#1d253b", padding: "10px"}}> rejections: </th>
	    <Table responsive striped dark>
    <thead className="text-primary">
        <tr>
            <th className="text-justified">File</th>
            <th className="text-justified">Comment</th>
        </tr>
    </thead>
    <tbody>
    {filteredNotes.map(( listValue, index ) => {
          return (
            <tr key={index}>
              <td className="text-justified">{listValue.url}</td>
              <td className="text-justified">{listValue.comment}</td>
            </tr>
          );
        })}
    </tbody>
</Table></>) : <></>}
                    
                    </div>
                      <table className="table">
                        <tbody>
                          {display.map((i) => (
                              i
                          ))}
                        </tbody>
                      </table>


<div className="">

    <th className="text-justified" style={{color:"#1d253b", padding: "10px"}}> Assigneelogs: </th>

    <Table responsive striped dark>
    <thead className="text-primary">
        <tr>
            <th className="text-justified">Action</th>
            <th className="text-justified">Assignee</th>
            <th className="text-justified">assigneeType</th>
            <th className="text-justified">Stage</th>
            <th className="text-justified">Status</th>
            <th className="text-justified">requester</th>
            <th className="text-justified">requesterTypr</th>
            <th className="text-justified">Time</th>
        </tr>
    </thead>
    <tbody>
    { self.state.taskData.actionLogs && self.state.taskData.actionLogs.map(( listValue, index ) => {
      console.log(listValue)
          return (
            <tr key={index}>
              <td className="text-justified">{listValue.action}</td>
              <td className="text-justified">{listValue.assignee}</td>
              <td className="text-justified">{listValue.assigneeType}</td>
              <td className="text-justified">{listValue.stage}</td>
              <td className="text-justified">{listValue.status}</td>
              <td className="text-justified">{listValue.requester}</td>
              <td className="text-justified">{listValue.requesterType}</td>
              <td className="text-justified">{listValue.timestamp}</td>
            </tr>
          );
        })}
    </tbody>
</Table>
                    
                    </div>



                    <div className="">
                      {this.state.logData.length ? (<LogsTable logData={this.state.logData}/>):<></>}

    <th className="text-justified" style={{color:"#1d253b", padding: "10px"}}> Orderlogs: </th>

    <Table responsive striped dark>
    <thead className="text-primary">
        <tr>
            <th className="text-justified">Origin</th>
            <th className="text-justified">Action</th>
            <th className="text-justified">Assignee</th>
            <th className="text-justified">assigneeType</th>
            <th className="text-justified">Time</th>
            <th className="text-justified">requester</th>
            <th className="text-justified">Stage</th>
            <th className="text-justified">Status</th>
        </tr>
    </thead>
    <tbody>
    {this.state.logData.map(( listValue, index ) => {
      console.log(listValue)
          return (
            <tr key={index}>
              <td className="text-justified">{listValue.origin}</td>
              <td className="text-justified">{listValue.action}</td>
              <td className="text-justified">{listValue.assigneeName}</td>
              <td className="text-justified">{listValue.assigneeType}</td>
              <td className="text-justified">{listValue.ist}</td>
              <td className="text-justified">{listValue.requester}</td>
              <td className="text-justified">{listValue.stage}</td>
              <td className="text-justified">{listValue.status}</td>
            </tr>
          );
        })}
    </tbody>
</Table>
                    
                    </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            </>
          )}
        </div>
      </>
    );
  }
}
export default Order;
