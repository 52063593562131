/* eslint-disable react/no-deprecated */
/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import loginImage from "assets/img/card-primary.png"
// import { GoogleLogin } from 'react-google-login';
// import { Alert } from "reactstrap";
import NotificationAlert from "react-notification-alert";

// import axios from 'axios';
// eslint-disable-next-line camelcase
// import jwt_decode from "jwt-decode";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    FormFeedback,
    Col,
    Row,
} from "reactstrap";
// import setAuthToken from "../../utils/setAuthToken";

// import { refreshTokenSetup } from '../utils/refreshToken';

import { loginUser, googleloginUser } from "../../actions/authActions";
import { thru } from "lodash";
// import config from "../../config/config";
const clientId =
    '76804024670-qc315e54c20gu5qrqciqsf5vdrf189s4.apps.googleusercontent.com';
const recaptchaRef = React.createRef();

class Login extends Component {
    constructor(props) {
        super();
        this.state = {
            email: "",
            password: "",
            captcha: true,
            message: "hi there"
           // sweetAlert:null,

        };

        console.log("login", props);
    }

    
    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated ) {
            if ((["artist", "qa"].includes(this.props.auth.user.type))&& this.props.auth.user.approved) {
                this.props.history.push("/admin/orders");
           }
           else if(this.props.auth.user.approved) {
               this.props.history.push("/admin/orders");
           }
           else {
             this.props.history.push("/notify");

           }
    }
}

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.auth.isAuthenticated) {
            if ((["artist", "qa"].includes(this.props.auth.user.type)) && this.props.auth.user.approved) {                 
                this.props.history.push("/admin/orders");   
            }// push user to dashboard when they login
            else if(nextProps.auth.user.approved) {
                this.props.history.push("/admin/orders");
            }
            else {
              this.props.history.push("/notify");
            }
        }
        

    }

    onChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const userData = {
            email,
            password,
        };
        // eslint-disable-next-line react/destructuring-assignment
        this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
    };
    
    onSuccess = async (res) => {        
        this.props.googleloginUser(res.profileObj); 
    };

    onFailure = (res) => {
        console.log('Login failed: res:', res);
        console.log(
            `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
        );
    };

    onChangeCap = async () => {
        if(recaptchaRef.current){
            this.setState({
                captcha:true,
            })
        }
    }

    googleButton = renderProps => (
        <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            block
            className="mb-3 btn-simple"
            color="google"
            size="lg"
            type="button">
            <svg
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                x="0"
                y="0"
                preserveAspectRatio="xMinYMin meet"
                className="third-party-join__google-icon"
            >
                <g className="color-icon">
                    <path
                        style={{
                            fill:
                                "#E94435",
                        }}
                        d="M12.1,5.8c1.6-0.1,3.1,0.5,4.3,1.6l2.6-2.7c-1.9-1.8-4.4-2.7-6.9-2.7c-3.8,0-7.2,2-9,5.3l3,2.4C7.1,7.2,9.5,5.7,12.1,5.8z"
                    />
                    <path
                        style={{
                            fill:
                                "#F8BB15",
                        }}
                        d="M5.8,12c0-0.8,0.1-1.6,0.4-2.3l-3-2.4C2.4,8.7,2,10.4,2,12c0,1.6,0.4,3.3,1.1,4.7l3.1-2.4C5.9,13.6,5.8,12.8,5.8,12z"
                    />
                    <path
                        style={{
                            fill:
                                "#34A751",
                        }}
                        d="M15.8,17.3c-1.2,0.6-2.5,1-3.8,0.9c-2.6,0-4.9-1.5-5.8-3.9l-3.1,2.4C4.9,20,8.3,22.1,12,22c2.5,0.1,4.9-0.8,6.8-2.3L15.8,17.3z"
                    />
                    <path
                        style={{
                            fill:
                                "#547DBE",
                        }}
                        d="M22,12c0-0.7-0.1-1.3-0.2-2H12v4h6.1v0.2c-0.3,1.3-1.1,2.4-2.2,3.1l3,2.4C21,17.7,22.1,14.9,22,12z"
                    />
                </g>
            </svg>{" "}
                                                    Sign In with Google
        </Button>
    )

    render() {
        const { errors } = this.props;
        // const { loginErrors } = this.props
        if (errors && Object.keys(errors).length){
            const error = errors[Object.keys(errors)[0]]
            const options = {
                place: 'tr',
                message: (
                  <div>
                    <div>
                     {error}
                    </div>
                  </div>
                ),
                type: "danger",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7
              };
              this.refs.notificationAlert.notificationAlert(options);
        }
        return (
            <>
                <div className="content" style={{ paddingTop: 46 }}>
                    <Container>
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref="notificationAlert" />
                    </div>
                        <Row>
                            <Col className="ml-auto mr-auto" lg="5" md="6">
                                <Form
                                    className="form"
                                    noValidate
                                    onSubmit={this.onSubmit}
                                >
                                    <Card className="card-login card-white">
                                        <CardHeader>
                                            <img
                                                alt="..."
                                                src={loginImage}
                                            />
                                            <CardTitle tag="h1">
                                                {" "}
                                                Log in{" "}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <InputGroup
                                                className={classnames("", {
                                                    invalid:
                                                        errors.email ||
                                                        errors.emailnotfound,
                                                })}
                                            >
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="tim-icons icon-email-85" />{" "}
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    id="email"
                                                    type="email"
                                                    onChange={this.onChange}
                                                    value={this.state.email}
                                                    error={errors.email}
                                                />
                                            </InputGroup>
                                            <span className="red-text">
                                                {errors.email}
                                                {errors.emailnotfound}
                                            </span>

                                            <InputGroup className="invalid">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        {" "}
                                                        <i className="tim-icons icon-lock-circle" />{" "}
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <FormFeedback>Oh noes! that name is already taken</FormFeedback>
                                                <Input
                                                    style={{}}
                                                    placeholder="Password"
                                                    id="password"
                                                    type="password"
                                                    onChange={this.onChange}
                                                    value={this.state.password}
                                                    error={errors.password}
                                                />
                                            </InputGroup>
                                            {/* <Row>
                                                <Col className="pr-md-1" md="6">
                                                    <ReCAPTCHA
                                                        ref={recaptchaRef}
                                                        // size="invisible"
                                                        sitekey="6LdtqBYaAAAAANrXPDu3vFqTrtxMY4f5KcJqCpWq"
                                                        onChange={
                                                            this.onChangeCap
                                                        }
                                                        // type="image"
                                                    />
                                                </Col>
                                            </Row> */}
                                        </CardBody>
                                        <CardFooter>
                                            { this.state.captcha && <Button
                                                block
                                                className="mb-3"
                                                color="primary"
                                                size="lg"
                                                type="submit"
                                            >
                                                {" "}
                                                Sign In{" "}
                                            </Button> }
                                            
                                            {/* <GoogleLogin
                                                clientId={clientId}
                                                buttonText="Login"
                                                onSuccess={this.onSuccess}
                                                onFailure={this.onFailure}
                                                cookiePolicy="single_host_origin"
                                                style={{
                                                    marginTop: '100px',
                                                    color: "#d73925 !important",
                                                    backgroundColor: "transparent",
                                                    backgroundImage: "none !important",
                                                    boxShadow: "none",
                                                    borderColor: "#d73925"
                                                }}
                                                render={this.googleButton}
                                                // isSignedIn
                                            /> */}

                                            <div className="pull-left">
                                                <h6>
                                                    <p className="link footer-link">
                                                        {" "}
                                                        <Link to="/register">
                                                            Create Account{" "}
                                                        </Link>{" "}
                                                    </p>
                                                </h6>
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    loginUser,
    googleloginUser
})(Login);

