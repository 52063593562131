import { useTable } from 'react-table'
import React from 'react'
 const LogsTable = ( {logData} ) => {
  console.log(logData)
  console.log("-----------------------------------=-----------------------------")
  const data = logData

  const columns = React.useMemo(
    () => [
      // {
      //   Header: 'Origin',
      //   accessor: 'origin', // accessor is the "key" in the data
      // },
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      // },
      // {
      //   Header: 'Assignee',
      //   accessor: 'assigneeName',
      // },
      // {
      //   Header: 'assigneeType',
      //   accessor: 'assigneeType',
      // },
      // {
      //   Header: 'Time',
      //   accessor: 'gmt',
      // },
      // {
      //   Header: 'requester',
      //   accessor: 'requester',
      // },
      // {
      //   Header: 'Stage',
      //   accessor: 'stage',
      // },
      // {
      //   Header: 'Status',
      //   accessor: 'status',
      // },


    ],
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <table {...getTableProps()} style={{ border: 'solid 3px #525f7f', marginTop: '10px' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                style={{
                  borderBottom: 'solid 2px #525f7f',
                  background: 'white',
                  color: 'black',
                  padding:'5px 20px 5px 20px',
                  textAlign: 'justify',  
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '100px',
                      border: 'solid 1px gray',
                      background: 'white',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default LogsTable;
