/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { connect } from "react-redux";
// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col,
} from "reactstrap";

// core components
import getGraphStats from "./chart/getGraphStats";
import mainChart from "./chart/mainChart";
import chartExample3 from "./chart/chartExample3";
import chartExample2 from "./chart/chartExample2";
import DailyGraph from './dashboard-graphs/DailyGraph';
import OrderStats from './dashboard-graphs/OrderStats';
import OrderMonthlyStats from './dashboard-graphs/OrderMonthlyStats';
import MonthlyGraph from './dashboard-graphs/MonthlyGraph';
import { getUser } from "../../actions/selectors";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bigChartData: "data1",
            graphData: mainChart.data1,
        };
    }

    componentDidMount() {

        getGraphStats().then((data) => {
            const kpiReceivedMonthly = data.receivedMonthly
            const kpiDeliveredMonthly = data.deliveredMonthly
            const kpiReceivedLastMonth = data.receivedLastMonth
            const kpiDeliveredLastMonth = data.deliveredLastMonth
            const kpiReceivedPercentage = ((kpiReceivedMonthly - kpiReceivedLastMonth) / kpiReceivedLastMonth).toFixed(2)
            const kpiDeliveredPercentage = ((kpiDeliveredMonthly - kpiDeliveredLastMonth) / kpiDeliveredLastMonth).toFixed(2)
            // console.log(data.filesCount12Hr);
            this.setState({
                graphData: data.newData,
                alterData1: data.newData,
                alterData2: data.newData1,
                alterData3: data.newData2,
                totalOrders: data.totalOrders !== "-" ? data.totalOrders : "-",
                pendingOrders: data.pendingOrders,
                totalRedos: data.totalRedos,
                tasksDumped: data.tasksDumped,
                graphMonthly: data.graphMonthly,
                lastMonth: data.lastMonth,
                graphMonthlySales: data.lastMonthSalesTypes,
                receivedMonthly: data.receivedMonthly,
                deliveredMonthly: data.deliveredMonthly,
                redosMonthly: data.redosMonthly,
                currentPendingOrders: data.currentPendingOrders,
                redoPendancy: data.redoPendancy,
                totalRedoPendancy: data.totalRedoPendancy,
                kpiReceivedPercentage,
                kpiDeliveredPercentage,
                totalPendingOrders: data.totalPendingOrders,
                pending12Hrs: data.pending12Hrs,
                pending18Hrs: data.pending18Hrs,
                pending24Hrs: data.pending24Hrs,
                pending48Hrs: data.pending48Hrs,
                filesCount24Hr: data.filesCount24Hr,
                filesCount12Hr: data.filesCount12Hr,
                filesCount48Hr: data.filesCount48Hr,
                totalPendingOrdersFiles: data.totalPendingOrdersFiles,
                totalRedoPendancyFiles: data.totalRedoPendancyFiles
            });
        });
    }


    setBgChartData = (name) => {
        if (name === "data1") {
            this.setState((prevState) => ({
                bigChartData: "data1",
                graphData: prevState.alterData1,
            }));
        } else if (name === "data2") {
            this.setState((prevState) => ({
                bigChartData: "data2",
                graphData: prevState.alterData3,
            }));
        } else {
            this.setState((prevState) => ({
                bigChartData: "data3",
                graphData: prevState.alterData2,
            }));
        }
    };


    render() {
        return ( <> { " " } <div className = "content" >
            <OrderMonthlyStats props = {
                {
                    totalOrders: this.state.totalOrders,
                    totalRedos: this.state.totalRedos,
                    pendingOrders: this.state.pendingOrders,
                    tasksDumped: this.state.tasksDumped,
                    lastMonth: this.state.lastMonth,
                    receivedMonthly: this.state.receivedMonthly,
                    deliveredMonthly: this.state.deliveredMonthly,
                    redosMonthly: this.state.redosMonthly,
                    currentPendingOrders: this.state.currentPendingOrders,
                    redoPendancy: this.state.redoPendancy,
                    totalRedoPendancy: this.state.totalRedoPendancy,
                    totalRedoPendancyFiles: this.state.totalRedoPendancyFiles,
                    kpiDeliveredPercentage: this.state.kpiDeliveredPercentage,
                    kpiReceivedPercentage: this.state.kpiReceivedPercentage,
                    totalPendingOrders: this.state.totalPendingOrders,
                    totalPendingOrdersFiles: this.state.totalPendingOrdersFiles,
                    pending12Hrs: this.state.pending12Hrs,
                    pending18Hrs: this.state.pending18Hrs,
                    pending24Hrs: this.state.pending24Hrs,
                    pending48Hrs: this.state.pending48Hrs,
                    filesCount24Hr: this.state.filesCount24Hr,
                    filesCount12Hr: this.state.filesCount12Hr,
                    filesCount48Hr: this.state.filesCount48Hr,
                }
            }
            /> <Row > <Col xs = "12" > <DailyGraph props = {
                {
                    bigChartData: this.state.bigChartData,
                    graphData: this.state.graphData,
                    options: mainChart.options,
                    setBgChartData: this.setBgChartData
                }
            }
            /> </Col > </Row>

            <OrderStats props = {
                {
                    totalOrders: this.state.totalOrders,
                    totalRedos: this.state.totalRedos,
                    pendingOrders: this.state.pendingOrders,
                    tasksDumped: this.state.tasksDumped
                }
            }
            /> <Row > <Col lg = "4" >
            <Card className = "card-chart" >
            <CardHeader >
            <h5 className = "card-category" > Monthly Orders </h5> <CardTitle tag = "h3" > <i className = "tim-icons icon-bell-55 text-info" / > { " " } { this.state.lastMonth }
            { " " }this month </CardTitle> </CardHeader > <CardBody >

            <div className = "chart-area">
            <Line data = { this.state.graphMonthly || chartExample2.data }
            options = { chartExample2.options }
            /> </div > </CardBody> </Card > </Col> <Col lg = "8" > <MonthlyGraph props = {
                {
                    totalOrders: this.state.totalOrders,
                    graphMonthlySales: this.state.graphMonthlySales,
                    chartExample3
                }
            }
            /> </Col > </Row> </div> { " " } </>
        );
    }
}

// export default Dashboard;
// part 2-2 of accessing-global-store
const mapStateToProps = state => {
    const user = getUser(state);
    return { user };
};
// part 2-3 of accessing-global-store
export default connect(mapStateToProps)(Dashboard);