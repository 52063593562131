// import React from 'react'
// import { Link } from "react-router-dom";
// import icons from '../../../variables/icons';

const columns = [
  {
    title: "Name",
    field: "name", // field is the "key" in the data
    key: "name", // field is the "key" in the data
  },

  {
    title: "Email",
    field: "email", // field is the "key" in the data
    key: "email", // field is the "key" in the data
  },
  {
    title: "Sales Type",
    field: "services",
        // render: rowData => rowData.services.join(', ')

  },
   {
     title: "Assigned",
     field: "pool",
   },
  
   {
     title: "Done",
     field: "moved",
   },
   {
    title: "Pending",
     render: rowData => (rowData.pool - rowData.moved),
   },
  //{
    //title: "Total AssignedFiles",
   // field: "orders.AssignedFiles",
  //},
  //{
   // title: "DoneFiles",
   // field: "orders.DoneFiles",
  //},
 // {
  //  title: "PendingFiles",
    //field: "orders.PendingFiles",
  //},
  //{
  //  title: "Assigned",
   // field: "pool",
  //},
  //{
   // title: "Done",
   // field: "moved",
  ///},
 {
    title: "Status",
    field: "status", // field is the "key" in the data
    key: "status", // field is the "key" in the data
  },



]

export default columns;
