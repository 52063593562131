// import React from 'react'
// import { Link } from "react-router-dom";
// import icons from '../../../variables/icons';

const columns = [
  {
    title: "Name",
    field: "name", // field is the "key" in the data
    key: "name", // field is the "key" in the data
  },

  {
    title: "Email",
    field: "email", // field is the "key" in the data
    key: "email", // field is the "key" in the data

  },
  {
    title: "User Type",
    field: "type", // field is the "key" in the data
    key: "type", // field is the "key" in the data
    // lookup:{"admin": "admin", "manager": "manager", "artist": "artist","qa": "qa","videoArtist": "videoArtist",
    // "videoQa": "videoQa","coordinator": "coordinator"}
  },
  {
    title: "Services",
    field: "services",
    key: "services",
    // render: rowData => rowData.services.join(', ')
  },
 
  {
    title: "Approved Status",
    field: "status", // field is the "key" in the data
    key: "status", // field is the "key" in the data
  },
  {
    title: "Target",
    field: "target",
  },
  {
    title: "Shift",
    field: "shift",
  },




]

export default columns;