/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Link } from "react-router-dom";

class Landing extends Component {
  render() {
    return (
      <div
        style={{
          height: "75vh",
        }}
        className="container valign-wrapper"
      >
        <div className="row">
          <div className="col s12 center-align">
            <h4>
              <b> Develop /Track / Report</b>{" "}
            </h4>{" "}
            <p className="flow-text grey-text text-darken-1">
              Collaborative home for development that makes it easier to plan
              and build with your teams.{" "}
            </p>{" "}
            <br />
            <div className="col s6">
              <Link
                to="/register"
                style={{
                  width: "140px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn btn-large waves-effect waves-light blue "
              >
                Register{" "}
              </Link>{" "}
            </div>{" "}
            <div className="col s6">
              <Link
                to="/login"
                style={{
                  width: "140px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                }}
                className="btn-large waves-effect waves-light orange"
              >
                Log In{" "}
              </Link>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}
export default Landing;
