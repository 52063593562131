/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
// import { MDBDataTableV5 } from "mdbreact";

import { Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  // Input,
  Button} from "reactstrap";

import ReactLoading from "react-loading";
import MyBaseTable from "./ArtistTable";

const axios = require("axios");

class Artists extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      infoPanel: [],
      isrendered: false,
      checkbox1: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchApi();
    }, 400);
    console.log("Child did mount.");
  }

 
  onAutoAllocationOn = async () =>{  
    const payload = {
      ids:this.state.checkbox1
    }
    console.log(payload)
    axios
    .post('/api/users/markasactive', payload)
  .then(() => {
     window.location.reload();
  }) 
  }

  onAutoAllocationOff = async () =>{
    const payload = {
      ids:this.state.checkbox1
    }
    console.log(payload)
    axios
    .post('/api/users/markasinactive', payload)
  .then(() => {
     window.location.reload();
  }) 
  }

  onSelectionChange = async (rows) =>{
    console.log(rows)
    const data = await rows.map(row => 
      row.email)
    this.setState({
      checkbox1:data
    })
  
  }

  resetPool = async (rows) =>{
    console.log(rows)
    const payload = {
      ids:this.state.checkbox1
    }
    console.log(payload)
    axios
    .post('/api/users/resetpool', payload)
  .then(() => {
     window.location.reload();
  })
  }

  setTableValues = async (users) => {
  const userList = users;
  const artistList = [];
    const self = this;

  for (let i = 0; i < userList.length; i+= 1) {
      if (userList[i].type==="artist") {
        if (userList[i].active===true) {
          userList[i].status = "active"
        } 
        else{
          userList[i].status = "inactive"
        }

        if (userList[i].orders) {
          userList[i].pending = userList[i].orders.PendingFiles;
          userList[i].done = userList[i].orders.DoneFiles;
        }
       else{
          userList[i].pending = 0;
          userList[i].done = 0;
       }
	 userList[i].services = userList[i].services.join(' - ')
        artistList.push(userList[i]);
      }
    
   }

   self.setState({
        infoPanel: artistList,
        isrendered: true,
    });
  }


  fetchApi = () => {
    axios.get(`/api/users`).then((response) => {
    this.setTableValues(response.data.data.users)

   }).catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  
  };

  render() {
  
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="12">
              <Card className="card-chart" style={{ minHeight: "557px" }}>
                <CardHeader>
                  <CardTitle tag="h4">Artists List</CardTitle>
                </CardHeader>
                <CardBody>
                <Col xs={12} md={16} className={ (this.state.checkbox1.length) ? '' :'d-none'}>
                          <Card>
                              <CardHeader><CardTitle>Actions</CardTitle></CardHeader>
                              <CardBody>
                              <FormGroup>
                              <Button  style={{    width: "240px"}} color="success" onClick={this.onAutoAllocationOn}>Auto-Allocation On</Button>
                              <Button  style={{    width: "240px"}} color="primary" onClick={this.onAutoAllocationOff}>Auto-Allocation Off</Button>
                              <Button  onClick={this.resetPool} className="btn-simple" color="primary">
                                reset pool
                              </Button>
                            </FormGroup>
                              </CardBody>
                          </Card>
                      </Col> 
                  {!this.state.isrendered ? (
                    <div style={{ margin: "100px 450px 150px" }}>
                      <ReactLoading type="spinningBubbles" color="#e14eca" />
                    </div>
                  ) : (
                  
                  <MyBaseTable
                    records={this.state.infoPanel}
                    onSelectionChange={this.onSelectionChange}
                  />
              
        
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default Artists;
