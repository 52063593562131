/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable global-require */
/* eslint-disable react/no-unused-state */
import React from "react";

// reactstrap components

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  // CardText,
  FormGroup,
  Form,
  Input,
  // InputGroup,
  Row,
  Col
} from "reactstrap";
import ReactLoading from "react-loading";


const axios = require("axios");

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      empID: "",
      profileImageUrl: require("../assets/img/default-avatar.png"),
      isrendered: false
    };

  }

  componentDidMount() {
    const id = "self";
    setTimeout(() => { this.fetchApi(id) }, 400);
  }

  fetchApi = async (id) => {
    const self = this;
    axios
      .get(`/api/users/${id}`)
      .then((response) => {
        const user = response.data.data.user[0];
        const userDetails = {
          name: user.name,
          // firstName: user.firstName,
          email: user.email,
          type: user.type,
          address: user.address,
          city: user.city,
          country: user.country,
          postalCode: user.postalCode,
          about: user.about,
          empID: user.id,
          profileImageUrl: require("../assets/img/default-avatar.png"),

        }
        self.setState(userDetails);
        self.setState({ isrendered: true });

      })
  };

  updateApi = async (id, loginUser) => {
    axios.post(`/api/users/${id}`, loginUser)
      .then(res => {
        console.log(res);
        this.setState({ isrendered: true });
      })
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const loginUser = {
      name: this.state.name,
      //  firstName: this.state.firstName,
      email: this.state.email,
      type: this.state.type,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      postalCode: this.state.postalCode,
      about: this.state.about,
      // profileImageUrl : this.state.profileImageUrl
    };

    const id = "self";
    this.updateApi(id, loginUser);
    this.fetchApi(id);

  };

  render() {
    // const self = this;
    // const payload = {
    //   email: self.state.email,
    //   name: self.state.name,
    //   empID: self.state.empID,
    //   profileImageUrl: require("../assets/img/default-avatar.png")

    // };
    return (
      <>
        <div className="content">
          {!this.state.isrendered ? (
            <div style={{ margin: "100px 450px 150px" }}>
              <ReactLoading type="spinningBubbles" color="#e14eca" /></div>) : (
            <Row>

              <Col md="10">
                <Card>
                  <CardHeader>
                    <h5 className="title">Edit Profile</h5>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.onSubmit}>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <FormGroup>
                            <label>Company</label>
                            <Input
                              defaultValue="Fotografik Enterprises"
                              disabled
                              placeholder="Company"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="6">
                          <FormGroup>
                            <label>Username</label>
                            <Input id="name"
                              value={this.state.name}
                              onChange={this.onChange}
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                      </Row>
                      <Row>
                        {/* <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Name</label>
                          <Input id="firstName"
                            value={this.state.firstName}
                            onChange={this.onChange}
                            placeholder="Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                        <Col className="pl-md-1" md="5">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                              Email address
                          </label>
                            <Input id="email" placeholder="Email"
                              type="email" disabled
                              value={this.state.email}
                              onChange={this.onChange} />
                          </FormGroup>
                        </Col>

                        <Col className="pl-md-1" md="2">
                          <FormGroup>
                            <label>Type</label>
                            <Input id="type" placeholder="Type"
                              type="text" disabled
                              value={this.state.type}
                              onChange={this.onChange} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Address</label>
                            <Input
                              id="address" placeholder="Address" type="address"
                              value={this.state.address}
                              onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="4">
                          <FormGroup>
                            <label>City</label>
                            <Input
                              id="city" placeholder="City" type="address"
                              value={this.state.city}
                              onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-md-1" md="4">
                          <FormGroup>
                            <label>Country</label>
                            <Input
                              id="country" placeholder="Country" type="address"
                              value={this.state.country}
                              onChange={this.onChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <label>Postal Code</label>
                            <Input id="postalCode" placeholder="Postal Code"
                              value={this.state.postalCode}
                              onChange={this.onChange} type="number" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="8">
                          <FormGroup>
                            <label>About Me</label>
                            <Input
                              cols="80"
                              id="about"
                              value={this.state.about}
                              onChange={this.onChange}
                              placeholder="Here can be your description"
                              rows="4"
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button className="btn-fill" color="primary" type="submit">
                        Save
                  </Button>
                    </Form>
                  </CardBody>
                  <CardFooter />
                </Card>
              </Col>
              {/* <Col md="4">
                <Card className="card-user">
                  <CardBody>
                    <CardText />
                    <div className="author">
                      <div className="block block-one" />
                      <div className="block block-two" />
                      <div className="block block-three" />
                      <div className="block block-four" />

                      <a href="#pablo" onClick={e => e.preventDefault()}>

                        <img alt="..."
                          className="avatar"
                          src={payload.profileImageUrl}
                        />
                        <h5 className="title">{payload.name}</h5>
                      </a>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="button-container">
                      <Button className="btn-icon btn-round" color="facebook">
                        <i className="fab fa-facebook" />
                      </Button>
                      <Button className="btn-icon btn-round" color="twitter">
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button className="btn-icon btn-round" color="google">
                        <i className="fab fa-google-plus" />
                      </Button>
                    </div>
                  </CardFooter>
                </Card>

              </Col> */}
            </Row>
          )}
        </div>
      </>
    );
  }
}

export default UserProfile;