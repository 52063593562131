/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable  no-unused-expressions */

import React, { Component } from "react";
// import { MDBDataTableV5 } from "mdbreact";

import { Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button} from "reactstrap";

  import Datetime from 'react-datetime';
  import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

import ReactLoading from "react-loading";
import MyBaseTable from "./UsersTable";



const axios = require("axios");

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      infoPanel: [],
      isrendered: false,
      checkbox1: [],
      showServicePanel : false,
      showPollPanel : false,
      services :{
        "Most_Popular":0,
        "PremierRT":0,
        "Traditional-ArtistTouch":0,
        "Yearbook":0,
        "Elite":0,
        "Traditional":0,
        "Redo":0,
        "Heroes":0,
        "UCPremierRT":0,
        "UCEnhancedRT":0,
        "ATPremierRT":0
      },
      userType: ''
    };
  }

  componentDidMount() {
    this.fetchApi();
    // alert(this.props.user.redoLogin)


    const self = this;
    axios
    .get(`/api/users/self`)
    .then((response) => {
     const user = response.data.data.user[0];
     if(user.type === "coordinator"){
      self.setState({
        showPollPanel : false,
        showServicePanel : false});
     } 
     else{
      self.setState({
        showPollPanel : true,
        showServicePanel : true});
     }
     const userDetails = {
      name: user.name,
      firstName: user.firstName,
      email: user.email,
      address : user.address,
      city : user.city,
      country: user.country,
      postalCode: user.postalCode,
      about : user.about,  
      empID: user.id,
      }
      self.setState(userDetails);
      self.setState({
        isRedoLogin : user.redoLogin
      })
  })

  axios
    .get(`/api/users`)
    .then((response)=>{
      // console.log(response.data.data.users)
      self.setState({
        users:response.data.data.users
      })
    })
}

 
  onApprove = async () =>{  
    const payload = {
      ids:this.state.checkbox1
    }
    console.log(payload)
    axios
    .post('/api/users/markasapprove', payload)
  .then(() => {
     window.location.reload();
  }) 
  }

  onDisapprove = async () =>{
    const payload = {
      ids:this.state.checkbox1
    }
    console.log(payload)
    axios
    .post('/api/users/markasdisapprove', payload)
  .then(() => {
     window.location.reload();
  }) 
  }

  onSave = async () =>{
    const payload = {
      ids:this.state.checkbox1,
      services:this.state.services
    }
    console.log(payload)
    await axios
    .post('/api/users/saveservices', payload)
  .then(() => {
     window.location.reload();
  })
  }

  handleChange = event => {
    this.setState({ userType: event.target.value });
  };


  onSubmit = async () =>{
    const payload = {
      ids:this.state.checkbox1,
      type:this.state.userType
    }
    console.log(payload)
    await axios
    .post('/api/users/saveuserType', payload)
  .then((r) => {
    // console.log(r)
     window.location.reload();
  })
  }

  onPollSave = async () =>{
    const payload = {
      ids:this.state.checkbox1,
      pool:this.state.pool
    }
    console.log(payload)
    await axios
    .post('/api/users/pool', payload)
  .then((r) => {
    // console.log(r)
     window.location.reload();
  })
  }

  onPollReset = async () =>{
    const payload = {
      ids:this.state.checkbox1,
      pool:this.state.pool
    }
    console.log(payload)
    await axios
    .post('/api/users/poolReset', payload)
  .then((r) => {
    // console.log(r)
     window.location.reload();
  })
  }

  updateStartTime = (e)=>{
    e && this.setState({
        toDate:e.format('hh:mm:ss')
    })
    }

  updateEndTime= (e)=>{
    e && this.setState({
        fromDate:e.format('hh:mm:ss')
    })
    }


  onSelectionChange = async (rows) =>{
    console.log(rows)
    console.log('fetched..');
    let {services} = this.state;
    let userType = ''
    const data = await rows.map(row => 
      row.email)

      if(data.length === 1 ){
        const  t = {}
        console.log(rows[0].services)
        for (let index = 0; index < rows[0].services.length; index+=1) {
          const element = rows[0].services[index];
          if(element === "Most-Popular"){
            t.Most_Popular=1;
          }
          else{
            t[element]=1;
          }
        }
        services = { ...services, ...t}
        userType = rows[0].type
      }
      else{
        services ={
          "Most_Popular":0,
          "PremierRT":0,
          "Traditional-ArtistTouch":0,
          "Yearbook":0,
          "Elite":0,
          "Traditional":0,
          "Redo":0,
          "Heroes":0,
          "UCPremierRT":0,
          "UCEnhancedRT":0,
          "ATPremierRT":0
        }
      }
      console.log(userType)
    this.setState({
      checkbox1:data,
      services,
      userType
    })
  }

  

  toggleCheck = async (e) =>{
    const {services} = this.state;
    if(e.target.checked === false){
      services[e.target.id] = 0;
    }
    else{
    services[e.target.id] = 1;
    }
    console.log(e.target.id);
    console.log(e.target.checked);
    this.setState({
      services
    })
  }

  togglePool = async (e) =>{
    const pool = parseInt(e.target.value,10) || 0;
    if(e.target.value !== ''){
    this.setState({
      pool
    })
    console.log(this.state,e.target.value)
    }
  }

  setTableValues = async (users) => {
  const userList = users;
  const tableData = [];
    const self = this;

  for (let i = 0; i < userList.length; i+= 1) {
     
        if (userList[i].approved===true) {
          userList[i].status = "Yes"
        } 
        else{
          userList[i].status = "No"
        }
        tableData.push(userList[i]);
    
   }

   self.setState({
        infoPanel: tableData,
        isrendered: true,
    });
  }

  fetchApi = () => {
    axios.get(`/api/users`).then((response) => {
    this.setTableValues(response.data.data.users)

   }).catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  
  };


  fetchTimeApi = () => {
    

    this.setState({
            isLoading:true,
            loading:true
          })
    const self = this;
    const startTime = self.state.StartTime;
    const endTime = self.state.EndTime;
    
    axios
      .get(`/api/tasks?light=true&StartTime=${startTime}&EndTime=${endTime}`)
      .then((response) => {
        // const user = response.data.data.user[0];
        if(this.props.user.type === "redo"){
          this.setTableRedoValues(response.data.data.task,1)
        }
        else
        {
          this.setTableValues(response.data.data.task,1)
        }

      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  };

  render() {
  
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="12">
              <Card className="card-chart" style={{ minHeight: "557px" }}>
                <CardHeader>
                  <CardTitle tag="h4">Users List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                <Col xs={12} md={4} className={ (this.state.checkbox1.length && this.state.showServicePanel) ? '' :'d-none'}>
                          <Card style={{
                            paddingLeft:"20px"
                          }}>
                              <CardHeader><CardTitle>Services</CardTitle></CardHeader>
                              <CardBody>
                            <FormGroup check>
                               <Label check>
                                 <Input onChange={this.toggleCheck} id="Traditional" checked={this.state.services.Traditional} type="checkbox"/>
                                 <span className="form-check-sign">
                                   <span className="check">Traditional</span>
                                   </span>
                                </Label>
                             </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="Elite" checked={this.state.services.Elite} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">Elite</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="Yearbook" checked={this.state.services.Yearbook} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">Yearbook</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="Most_Popular" checked={this.state.services.Most_Popular} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">Most_Popular</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="Traditional-ArtistTouch" checked={this.state.services["Traditional-ArtistTouch"]} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">Traditional-ArtistTouch</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="PremierRT" checked={this.state.services.PremierRT} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">PremierRT</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="Redo" checked={this.state.services.Redo} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">Redo</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="Heroes" checked={this.state.services.Heroes} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">Heroes</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="UCPremierRT" checked={this.state.services.UCPremierRT} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">UCPremierRT</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="UCEnhancedRT" checked={this.state.services.UCEnhancedRT} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">UCEnhancedRT</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <FormGroup check>
                              <Label check>
                                <Input onChange={this.toggleCheck}  id="ATPremierRT" checked={this.state.services.ATPremierRT} type="checkbox"/>
                                  <span className="form-check-sign">
                                     <span className="check">ATPremierRT</span>
                                  </span>
                                    </Label>
                             </FormGroup>
                             <Button color="secondary" size="lg" active onClick={this.onSave} style={{
                            margin:"20px"
                          }}>Save</Button>
                              </CardBody>
                          </Card>
                      </Col>

                      <Col xs={12} md={4} className={ (this.state.checkbox1.length) ? '' :'d-none'}>
                          <Card style={{paddingLeft:"20px" }}>
                              <CardHeader><CardTitle>User Type</CardTitle></CardHeader>
                              <CardBody>
                              <FormControl component="fieldset">
                                <RadioGroup aria-label="Gender" name="gender1"  defaultValue={this.state.userType} value={this.state.userType} onChange={this.handleChange}>
                                  <FormControlLabel value="admin" control={<Radio />} label="admin" />
                                  <FormControlLabel active="true" value="artist" control={<Radio />} label="artist" />
                                  <FormControlLabel value="qa" control={<Radio />} label="qa" />
                                  <FormControlLabel value="videoArtist" control={<Radio />} label="videoArtist" />
                                  <FormControlLabel value="videoQa" control={<Radio />} label="videoQa" />
                                  <FormControlLabel value="manager" control={<Radio />} label="manager" />
                                  <FormControlLabel value="coordinator" control={<Radio />} label="coordinator" />
                                </RadioGroup>
                            </FormControl>
                            <Button color="secondary" size="lg" active onClick={this.onSubmit} style={{
                            margin:"20px"
                          }}>Save</Button>
                            </CardBody>
                          </Card>
                       </Col>

                      <Col xs={6} md={4} className={ (this.state.checkbox1.length) ? '' :'d-none'}>
                          <Card>
                              <CardHeader><CardTitle>Actions</CardTitle></CardHeader>
                              <CardBody>
                              <FormGroup>
                              <Button  style={{    width: "200px"}} color="success" onClick={this.onApprove}>Approve</Button>
                              <Button  style={{    width: "200px"}} color="primary" onClick={this.onDisapprove}>Disapprove</Button>
                            </FormGroup>
                              </CardBody>
                          </Card>
                            <Card className={ (this.state.checkbox1.length && this.state.showPollPanel) ? '' :'d-none'}>
                              <CardHeader><CardTitle>Pool Target</CardTitle></CardHeader>
                              <CardBody>
                              <FormGroup>
                                <Input onChange={this.togglePool} value={this.state.poll}
                                  type="number"
                                  name="pooltarget"
                                  id="target"
                                  placeholder="PoolTarget"
                                />
                            </FormGroup>
                            <Button color="secondary" size="lg" active onClick={this.onPollSave} style={{
                            margin:"20px"}}>Save</Button>
                            {/*<Button color="secondary" size="lg" active onClick={this.onPollReset} style={{
                            margin:"20px"}}>Reset</Button>*/}
                              </CardBody>
                          </Card>
                      </Col>
                    </Row>
                    <Row className={ (this.state.checkbox1.length) ? '' :'d-none'}>
                    <Col xs={12} md={4}>
                      <Card>
                          <CardHeader><CardTitle>Start Time</CardTitle></CardHeader>
                          <CardBody>
                              <FormGroup>
                                  <Datetime
                                  onBlur={this.updateStartTime}
                                  timeFormat='hh:mm:ss'
                                  dateFormat={false}
                                      inputProps={{placeholder:"Pick from time"}}
                                  />
                              </FormGroup>
                          </CardBody>
                      </Card>
                      </Col>
                      <Col xs={12} md={4}>
                      <Card>
                          <CardHeader><CardTitle>End Time</CardTitle></CardHeader>
                          <CardBody>
                              <FormGroup>
                                  <Datetime
                                  onBlur={this.updateEndTime}
                                  timeFormat='hh:mm:ss'
                                  dateFormat={false}
                                      inputProps={{placeholder:"Pick from time"}}
                                  />
                              </FormGroup>
                          </CardBody>
                      </Card>
                      </Col> 
                      <Col xs={12} md={2}>
                      <Card>
                          <CardBody>
                              <Button onClick={this.fetchTimeApi} color="primary" type="submit">Submit </Button>
                          </CardBody>
                      </Card>
                     </Col>
                    </Row>
                  {!this.state.isrendered ? (
                    <div style={{ margin: "100px 450px 150px" }}>
                      <ReactLoading type="spinningBubbles" color="#e14eca" />
                    </div>
                  ) : (
                  
                  <MyBaseTable
                    records={this.state.infoPanel}
                    onSelectionChange={this.onSelectionChange}
                  />
              
        
                  )}
                </CardBody>
              </Card>
            </Col>

            
          </Row>
        </div>
      </>
    );
  }
}
export default Users;
