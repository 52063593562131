/* eslint-disable import/no-unresolved */
// import React ,{ useEffect }from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import Order from "components/layout/orders/Order";
import qa from "components/layout/orders/qa";
import Report from "components/layout/reports/Report";
import OrdersMonthlyStats from "components/layout/orderKPIStats/OrdersMonthlyStats";
// import ImageUpload from "components/CustomUpload/ImageUpload";

import Landing from "components/layout/Landing";
// import Navbar from "components/layout/Navbar";
import Register from "components/auth/Register";
import Login from "components/auth/Login";
import Notify from "components/layout/Notify";

// import UserProfile from "components/auth/UserProfile.js"
import PrivateRoute from "components/private-route/PrivateRoute";
// import Dashboard from "components/dashboard/Dashboard";
// import Dashboard2 from "./components/dashboard/dashboard-new";
import Admin from "layouts/Admin/Admin";
import store from "./store";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import setAuthToken from "./utils/setAuthToken";
// import Admin2 from "layouts/Admin2/Admin";
// import Auth from "layouts/Auth/Auth";
// import Documentation from "layouts/Documentation/Documentation";
// import RTL from "layouts/RTL/RTL";
import "App.css";


  


// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp

  const decoded = jwt_decode(token);
  // console.log("decode",decoded)
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

document.addEventListener('DOMContentLoaded', function () {
  if (Notification.permission !== "granted")
    Notification.requestPermission();
});

// eslint-disable-next-line no-unused-vars
function notifyMe() {
  if (!Notification) {
    alert('Desktop notifications not available in your browser. Try Chromium.'); 
    return;
  }

  if (Notification.permission !== "granted")
    Notification.requestPermission();
  else {
    var notification = new Notification('Notification title', {
      icon: 'http://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png',
      body: "Hey there! You've been notified!",
    });

    notification.onclick = function () {
      window.open("https://stackoverflow.com/a/13328397/1269037");      
    };

  }

}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="sans-serif">
          {/* <Navbar /> */}
          <Route exact path="/" component={Landing} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Switch>
            {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
            <PrivateRoute
              path="/admin"
              // eslint-disable-next-line react/jsx-props-no-spreading
              component={(props) => <Admin {...props} />}
            />
            {<PrivateRoute path="/orders" component={Order} />}
            {<PrivateRoute path="/QA" component={qa} />}
            {<PrivateRoute path="/reports" component={Report} />}
            {<PrivateRoute path="/orderKPI" component={OrdersMonthlyStats} />}
            {<PrivateRoute path="/notify" component={Notify} />}

            {/* <PrivateRoute path="/admin2" render={props => <Admin2 {...props} />} /> */}
            {/* <Route path="/auth" render={props => <Auth {...props} />} /> */}
            {/* <PrivateRoute path="/documentation" render={props => <Documentation {...props} />} /> */}
            {/* <Route path="/admin/user-profile2" render={props => <UserProfile {...props} />} /> */}
            {/* <Route path="/RTL" render={props => <RTL {...props} />} /> */}
            {/* <Route path="/rtl" render={props => <RTLLayout {...props} />} /> */}
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}



export default App;
