/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable  no-unused-expressions */

import React, { Component } from "react";
import moment from "moment-timezone";
// part 2-1 of accessing-global-store
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
// import Checkbox from "@material-ui/core/Checkbox";
// import Switch from 'react-bootstrap-switch';
import ReactLoading from "react-loading";
import Switch from "react-bootstrap-switch";
import {
    defaultState,
    userPanelPermissions,
} from "./orders/functions/stateFunctions";
import Select from "react-select";
// import chroma from 'chroma-js';
import { setTableValuesFunc } from "./orders/functions/fetchFunctions";
import {
    // Input,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    // Table,
    Row,
    Col,
    // UncontrolledTooltip,
    FormGroup,
    Button,
} from "reactstrap";
import Datetime from "react-datetime";
// import setStatValues from "./Table/SatatsPanel";

import MyBaseTable from "./Table";
import MyBaseTableQA from "./TableQA";
// part 2-1-1 of accessing-global-store
import { getUser } from "../../actions/selectors";
// import { fetchApi } from "./charts";

const axios = require("axios");

const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};

const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",
    // background: "#e14eca",
    // backgroundImage: "linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca)",
    ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    container: (styles) => ({ ...styles, zIndex: "5000" }),
    control: (styles) => ({ ...styles, backgroundColor: "#272a3d" }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //     // const color = 'red';
    //     return {
    //         ...styles,
    //     };
    // },
    input: (styles) => ({ ...styles, color: "white", ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles, { data }) => ({
        ...styles,
        color: "white",
        ...dot(data.color),
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "#272a3d",
        color: "white",
    }),
    option: (styles, { data }) => ({
        ...styles,
        ":hover": {
            color: "black",
        },
        ...dot(data.color),
    }),
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

class Orders extends Component {
    constructor(props) {
        super(props);
        const QAPanel = (localStorage.getItem("QAPanel") || false) === "true";
        defaultState.QAPanel = QAPanel;
        defaultState.mainUrl = QAPanel ? "/api/tasks/qa" : "/api/tasks";
        this.state = defaultState;
    }

    componentDidMount() {
        this.fetchApi();
        const self = this;
        axios.get(`/api/users/self`).then((response) => {
            const user = response.data.data.user[0];
            console.log(user.type);
            const permissions = userPanelPermissions(user.type);
            const userDetails = {
                userType: user.type,
                name: user.name,
                firstName: user.firstName,
                email: user.email,
                address: user.address,
                city: user.city,
                country: user.country,
                postalCode: user.postalCode,
                about: user.about,
                empID: user.id,
            };
            self.setState({
                ...permissions,
                ...userDetails,
                isRedoLogin: user.redoLogin,
            });
        });

        axios.get(`/api/users/usersList`).then((response) => {
            console.log(response.data.data.users);
            let users = response.data.data.users;
            const usersSorted = {};
            // const videoArtist = []
            // const qa = []
            // const manager = []
            // const videoQa = []
            // const coordinator = []
            // const redo = []
            // const admin = []
            // const artist = []
            // console.log(users)
            // const groupedOptions = []
            for (let index = 0; index < users.length; index++) {
                usersSorted[users[index]["_id"]] = users[index]["users"];
            }
            users = [];
            // artist
            if (
                ["qa", "manager", "coordinator", "redo", "admin"].includes(
                    self.state.userType
                )
            ) {
                let t = usersSorted["artist"];
                for (let index = 0; index < t.length; index++) {
                    t[index].color = "green";
                    t[index].value = t[index].email;
                    t[index].label = t[index].name;
                }
                users.push({
                    label: "Artists",
                    options: t,
                });
            }
            // qa
            if (
                [
                    "qa",
                    "videoArtist",
                    "manager",
                    "coordinator",
                    "redo",
                    "admin",
                ].includes(self.state.userType)
            ) {
                let t = usersSorted["qa"];
                for (let index = 0; index < t.length; index++) {
                    t[index].color = "purple";
                    t[index].value = t[index].email;
                    t[index].label = t[index].name;
                }
                users.push({
                    label: "QAs",
                    options: t,
                });
            }

            // va

            if (
                [
                    "qa",
                    "videoArtist",
                    "videoQa",
                    "manager",
                    "coordinator",
                    "redo",
                    "admin",
                ].includes(self.state.userType)
            ) {
                let t = usersSorted["videoArtist"];
                for (let index = 0; index < t.length; index++) {
                    t[index].color = "yellow";
                    t[index].value = t[index].email;
                    t[index].label = t[index].name;
                }
                users.push({
                    label: "videoArtists",
                    options: t,
                });
            }

            // vqa

            if (
                ["videoQa","manager", "coordinator", "redo", "admin"].includes(
                    self.state.userType
                )
            ) {
                let t = usersSorted["videoQa"];
                for (let index = 0; index < t.length; index++) {
                    t[index].color = "red";
                    t[index].value = t[index].email;
                    t[index].label = t[index].name;
                }
                users.push({
                    label: "videoQAs",
                    options: t,
                });
            }

            self.setState({
                users: response.data.data.users,
                usersAssignment: users,
            });
        });
    }

    componentDidUpdate() {}

    onReceiveInput = async () => {};

    hideAlert = () => {
        this.setState({
            sweetAlert: null,
        });
    };

    modalHandler = async (id) => {
        const self = this;
        // const rl = (message) => {
        //     return message.replace(/[\r\n]+/gm, "");
        // }
        axios
            .get(`/api/tasks/${id}`)
            .then((response) => {
                // this.state.tasks = response.data.task
                // console.log(response, ";;;;......");

                let title = "Task";
                let details = [];
                if (
                    !(
                        response &&
                        response.data &&
                        response.data.data &&
                        response.data.data.task
                    )
                ) {
                    details = "Unable to fetch task";
                } else {
                    title = `Task : ${id}`;
                }
                const task = response.data.data.task[0];

                for (const key in task) {
                    if (key === "length" || !task.hasOwnProperty(key)) {
                        // console.log(key, ";;;;");
                        continue;
                    } else if (key === "_id" || key === "__v") continue;
                    else if (
                        key === "SceneCounts" ||
                        key === "issues" ||
                        key === "warnings" ||
                        key === "Files" ||
                        key === "processed"
                    ) {
                        // let objectString = '';

                        for (const item in task[key]) {
                            for (const key2 in item) {
                                if (
                                    key2 === "length" ||
                                    !item.hasOwnProperty(key2)
                                )
                                    continue;
                            }
                        }
                    } else {
                        console.log({ key, value: task[key] });

                        details.push({ key, value: task[key] });
                    }
                }

                // console.log(details);
                self.setState({
                    modal: {
                        title,
                        details,
                        SceneCounts: task.SceneCounts,
                        Files: task.Files,
                        processed: task.processed || [],
                        issues: task.issues || [],
                        warnings: task.warnings || [],
                    },
                });
            })
            .catch((error) => {
                // handle error
                console.log(error);
            })
            .finally(() => {
                // always executed
            });
    };

    rl = (message) => {
        const newMessage = message.replace(/[\r\n]+/gm, "");
        return newMessage.replace(/["]+/gm, "'");
    };

    setTableRedoValues = async (stateTasks, change) => {
        let images = 0;
        let openTasks = 0;
        let downloadedTasks = 0;
        let deliveredTasks = 0;
        let redos = 0;
        let issuesCount = 0;
        let rejectCount = 0;
        let holdCount = 0;
        let cancelledCount = 0;
        let qaCount = 0;
        let videoQaCount = 0;
        let videoArtistCount = 0;
        let issuesCountFiles = 0;
        let rejectCountFiles = 0;
        let holdCountFiles = 0;
        let cancelledCountFiles = 0;
        // let CountFiles = 0;
        let qaCountFiles = 0;
        let videoQaCountFiles = 0;
        let videoArtistCountFiles = 0;
        const { users } = (await axios.get(`/api/users`)).data.data;
        // console.log(users)
        // console.log('adding users')
        const mapping = {};
        for (let index = 0; index < users.length; index++) {
            const user = users[index];
            mapping[user.email] = user.name;
        }
        const tasks = stateTasks;
        const redoTasks = [];
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].Correction === "1") {
                // console.log(tasks)
                images += parseInt(tasks[i].Count) || 0;
                tasks[i].assignee = mapping[tasks[i].assignee];

                if (change) {
                    if (!tasks[i].createdAt) {
                        tasks[i].createdAt = tasks[i].OriginalRequestDate;
                    }
                    tasks[i].createdAt = moment(tasks[i].createdAt)
                        .tz("Asia/Kolkata")
                        .format("YYYY-MM-DD HH:mm");
                    if (tasks[i].completedAt) {
                        tasks[i].completedAt = moment(tasks[i].completedAt)
                            .tz("Asia/Kolkata")
                            .format("YYYY-MM-DD HH:mm");
                    }
                }
                const fileCount = parseInt(tasks[i].Count) || 0;
                if (tasks[i].stage.toLowerCase() === "issue") {
                    issuesCount += 1;
                    issuesCountFiles += fileCount;
                }
                if (tasks[i].stage === "rejected") {
                    rejectCount += 1;
                    rejectCountFiles += fileCount;
                }
                if (tasks[i].stage === "hold") {
                    holdCount += 1;
                    holdCountFiles += fileCount;
                }
                if (tasks[i].stage === "cancelled") {
                    cancelledCount += 1;
                    cancelledCountFiles += fileCount;
                }
                if (tasks[i].stage === "qa") {
                    qaCount += 1;
                    qaCountFiles += fileCount;
                }
                if (tasks[i].stage === "videoQa") {
                    videoQaCount += 1;
                    videoQaCountFiles += fileCount;
                }
                if (tasks[i].stage === "videoArtist") {
                    videoArtistCount += 1;
                    videoArtistCountFiles += fileCount;
                }
                // eslint-disable-next-line eqeqeq
                if (tasks[i].ProductionStatusId == "2") {
                    deliveredTasks += 1;
                }
                // eslint-disable-next-line eqeqeq
                else if (tasks[i].ProductionStatusId == "1") {
                    downloadedTasks += 1;
                }
                // eslint-disable-next-line eqeqeq
                else if (tasks[i].ProductionStatusId == "0") {
                    openTasks += 1;
                } else {
                    openTasks += 1;
                }
                // eslint-disable-next-line eqeqeq
                if (tasks[i].Correction == "1") {
                    redos += 1;
                }
                if (tasks[i].Notes) {
                    tasks[i].Notes = this.rl(tasks[i].Notes);
                }
                if (change && tasks[i].comments) {
                    tasks[i].comments = this.rl(tasks[i].comments);
                }
                redoTasks.push(tasks[i]);
            }
        }
        if (change) {
            this.setState({
                tasks: redoTasks,
                isLoading: false,
                loading: true,
            });
        }
        this.setState({
            loading: false,
            originalTasks: tasks,
            MostPopular: true,
            Trad: true,
            Elite: true,
            CapAndGown: true,
            PremierRT: true,
            TraditionalAT: true,
            Yearbook: true,
            Heroes: true,
            open: true,
            downloaded: true,
            delivered: true,
            redo: true,
            infoPanel: [
                {
                    name: "Total Tasks Fetched",
                    value: redoTasks.length,
                },
                {
                    name: "Total Files Fetched",
                    value: images,
                },
                {
                    name: "Open Tasks",
                    value: openTasks,
                },
                {
                    name: "Downloaded Tasks",
                    value: downloadedTasks,
                },
                {
                    name: "Redo Tasks",
                    value: redos,
                },
                {
                    name: "Delivered Tasks",
                    value: deliveredTasks,
                },
                {
                    name: "Issues",
                    value: `${issuesCount} / ${issuesCountFiles}`,
                },
                {
                    name: "Reject",
                    value: `${rejectCount} / ${rejectCountFiles}`,
                },
                {
                    name: "Hold",
                    value: `${holdCount} / ${holdCountFiles}`,
                },
                {
                    name: "Cancelled",
                    value: `${cancelledCount} / ${cancelledCountFiles}`,
                },
                {
                    name: "Qa",
                    value: `${qaCount} / ${qaCountFiles}`,
                },
                {
                    name: "videoQa",
                    value: `${videoQaCount} / ${videoQaCountFiles}`,
                },
                {
                    name: "VideoArtist",
                    value: `${videoArtistCount} / ${videoArtistCountFiles}`,
                },
            ],
        });
    };

    onSelectionChange = async (rows) => {
        const data = [];
        let count = 0;
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            data.push(element.ProductionTaskId);
            count += parseInt(element.Count) || 0;
        }
        this.setState({
            selectedRows: data,
            selectedFilesCount: count,
        });
    };
    
    onAssign = async (e) => {
        console.log(e);
        const payload = {
            artist: e.email,
            ids: this.state.selectedRows,
        };
        // console.log(payload)
        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Order Assignment"
                    onConfirm={() => {
                        axios
                            .post("/api/users/assign", payload)
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        okayAlert: true,
                                        tasks: [],
                                    });
                                } else {
                                    this.setState({
                                        errorAlert: true,
                                    });
                                }
                            })
                            .then(() => {
                                this.setState({
                                    sweetAlert: null,
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                                this.setState({
                                    errorAlert: true,
                                });
                            });
                    }}
                    onCancel={() => {
                        this.setState({
                            sweetAlert: null,
                        });
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    Make assignment changes to the selected orders?
                </SweetAlert>
            ),
        });
    };
    onAssignMe = async () => {
        // console.log(this.state.email)
        this.onAssign({
            email:this.state.email
        })
    }
    onAssignRemove = async () => {
        this.onAssign({
            email:'remove'
        })
    }
    sendOnPriority = async () => {
        const payload = {
            user_name: "application",
            text: this.state.selectedRows.join(" "),
        };
        // console.log(payload)

        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Order Mark as Done"
                    onConfirm={() => {
                        axios
                            .post(
                                "`http://api.photo-wonder.com:3005/slack/upload-task?key=4229d691b07b13341da53f17ab9f2416",
                                payload
                            )
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        doneAlert: true,
                                        tasks: [],
                                        skipped: response,
                                    });
                                } else {
                                    this.setState({
                                        errorAlert: true,
                                    });
                                }
                            })
                            .then(() => {
                                this.setState({
                                    sweetAlert: null,
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                                this.setState({
                                    errorAlert: true,
                                });
                            });
                    }}
                    onCancel={() => {
                        this.setState({
                            sweetAlert: null,
                        });
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    Are you sure you want to SendOnPriority?
                </SweetAlert>
            ),
        });
    };

    onNextClick = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        // console.log(payload)
        axios.post("/api/users/moveToNextStage", payload).then(async (res) => {
            const { data } = res.data;
            console.log(data);
            const changeLogMessage = [];
            const skippedLogMessage = [];
            data[0].changeLog.forEach((skippedOrder) => {
                changeLogMessage.push(
                    <p style={{ color: "#525f7f" }}>
                        {skippedOrder["id"]} : {skippedOrder["change"]}
                    </p>
                );
            });
            data[0].skipped.forEach((changeLogOrder) => {
                skippedLogMessage.push(
                    <p style={{ color: "#525f7f" }}>
                        {changeLogOrder["id"]} : {changeLogOrder["reason"]}
                    </p>
                );
            });
            await this.setState({
                changeLogAlert: true,
                changeLog: [changeLogMessage, skippedLogMessage],
            });
            // window.location.reload();
        });
    };
    onMoveToQa = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        // console.log(payload)
        axios.post("/api/users/movetoqa", payload).then(() => {
            window.location.reload();
        });
    };

    onMoveTor4me = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        // console.log(payload)
        axios.post("/api/users/movetor4me", payload).then(() => {
            window.location.reload();
        });
    };

    handleSwitchClick = async () => {
        let payload = {};
        if (this.state.isRedoLogin === true) {
            this.setState({
                isRedoLogin: false,
            });
            payload = { withRedos: "false" };
        } else {
            this.setState({
                isRedoLogin: true,
            });
            payload = { withRedos: "true" };
        }
        axios.post("/api/users/updateRedoLogin", payload).then(() => {
            window.location.reload();
        });
    };
    handleQASwitchClick = async () => {
        //@note
        let QAPanel = localStorage.getItem("QAPanel") === "true";
        QAPanel = !QAPanel;
        localStorage.setItem("QAPanel", QAPanel);
        await this.setState({
            QAPanel,
            mainUrl: QAPanel ? "/api/tasks/qa" : "/api/tasks",
        });
        this.fetchApi();
    };

    onMarkAsDone = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        // console.log(payload)

        this.setState({
            sweetAlert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Order Mark as Done"
                    onConfirm={() => {
                        axios
                            .post("/api/users/markasdone", payload)

                            .then((response) => {
                                let ordersSkipped = "";
                                for (
                                    let i = 0;
                                    i < response.data.data.length;
                                    i++
                                ) {
                                    ordersSkipped += " ";
                                    ordersSkipped += response.data.data[i];
                                }
                                console.log(response.data);
                                console.log(ordersSkipped);
                                if (response.status === 200) {
                                    this.setState({
                                        doneAlert: true,
                                        tasks: [],
                                        skipped: response.data.data,
                                    });
                                } else {
                                    this.setState({
                                        errorAlert: true,
                                    });
                                }
                            })
                            .then(() => {
                                this.setState({
                                    sweetAlert: null,
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                                this.setState({
                                    errorAlert: true,
                                });
                            });
                    }}
                    onCancel={() => {
                        this.setState({
                            sweetAlert: null,
                        });
                    }}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                >
                    Are you sure you want to Mark as Done?
                </SweetAlert>
            ),
        });
    };

    onReject = async () => {
        // console.log(payload)
        await this.setState({
            rejectAlert: true,
        });
    };

    onMoveToVideoQa = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        // console.log(payload)
        axios.post("/api/users/movetovqa", payload).then(() => {
            window.location.reload();
        });
    };

    onMoveToVideoArtist = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        // console.log(payload)
        axios.post("/api/users/movetovartist", payload).then(() => {
            window.location.reload();
            //  console.log(response)
        });
    };

    onHold = async () => {
        // console.log(payload)
        await this.setState({
            HoldAlert: true,
        });
    };

    onCancelled = async (e) => {
        const payload = {
            artist: e.target.value,
            ids: this.state.selectedRows,
        };
        console.log(payload);
        axios.post("/api/users/markascancel", payload).then(() => {
            window.location.reload();
            //  console.log(response)
        });
    };

    fetchApi = () => {
        const self = this;
        const mainUrl = self.state.mainUrl;
        // this.timer = setInterval(() => this.fetchStatsApi(), 180000);
        const dateFrom = self.state.fromDate;
        const dateTo = self.state.toDate;
        this.setState({
            isLoading: true,
            loading: true,
        });
        axios
            .get(`${mainUrl}?light=true&fromDate=${dateFrom}&toDate=${dateTo}`)
            .then(async (response) => {
                if (this.props.user.type === "redo") {
                    this.setTableRedoValues(response.data.data.task, 1);
                } else {
                    // this.setTableValues(response.data.data.task, 1);
                    const setTableValues = await setTableValuesFunc(
                        response.data.data.task,
                        1
                    );
                    this.setState({
                        tasks: setTableValues.originalTasks,
                        isLoading: false,
                        loading: true,
                        ...setTableValues,
                    });
                }
            })
            .catch((error) => {
                // handle error
                console.log(error);
            })
            .finally(() => {
                // always executed
            });
    };

    fetchStatsApi = () => {
        const self = this;
        const mainUrl = self.state.mainUrl;
        const dateFrom = self.state.fromDate;
        const dateTo = self.state.toDate;
        axios
            .get(
                `${mainUrl}?light=true&fromDate=${dateFrom}&toDate=${dateTo}&stats=true`
            )
            .then((response) => {
                // console.log(response.data.data.task);
                this.setStatValues(response.data.data.task, 1);
            })
            .catch((error) => {
                // handle error
                console.log(error);
            })
            .finally(() => {
                // always executed
            });
    };

    updateToDate = (e) => {
        e &&
            this.setState({
                toDate: e.format("YYYY/MM/DD"),
            });
    };

    updateFromDate = (e) => {
        e &&
            this.setState({
                fromDate: e.format("YYYY/MM/DD"),
            });
    };

    render() {
        const self = this;

        return (
            <>
                <div className="content">
                    <Row>
                        <Col lg="6">
                            <Card
                                className="card-chart"
                                style={{ minHeight: "300px" }}
                            >
                                <CardHeader>
                                    <CardTitle tag="h4">STATS</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <table className="table">
                                        <tbody>
                                            {self.state.infoPanel
                                                .slice(0, 6)
                                                .map((i, index) => (
                                                    <tr key={index}>
                                                        <td> {i.name} </td>
                                                        <td> {i.value} </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card
                                className="card-chart"
                                style={{ minHeight: "200px" }}
                            >
                                <CardBody>
                                    <table className="table">
                                        <tbody>
                                            {self.state.infoPanel
                                                .slice(6)
                                                .map((i, index) => (
                                                    <tr key={index}>
                                                        <td> {i.name} </td>
                                                        <td> {i.value} </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h2">
                                        {this.state.QAPanel
                                            ? "QA ORDERS"
                                            : "ALL ORDERS"}
                                    </CardTitle>
                                </CardHeader>
                                <CardBody style={{ minHeight: "500px" }}>
                                    <Row
                                        className={
                                            this.state.showDatePanel
                                                ? ""
                                                : "d-none"
                                        }
                                    >
                                        {this.state.loading && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    height: "100%",
                                                    width: "100%",
                                                    zIndex: "999999",
                                                    background: "RGB(39,41,59)",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        height: "100%",
                                                        width: "100%",
                                                        left: "40%",
                                                    }}
                                                >
                                                    <ReactLoading
                                                        type="bars"
                                                        // color={color}
                                                        height="20%"
                                                        width="20%"
                                                        color="#1d8cf8"
                                                        // style={{
                                                        //   height: "20%",
                                                        //   width: "20%",
                                                        //   left: "50%",
                                                        //   position: "absolute",
                                                        //   top: "10%",
                                                        //   transform: "translate(-50%, -50%)"
                                                        // }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <Col xs={12} md={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        From Date
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Datetime
                                                            onBlur={
                                                                this
                                                                    .updateFromDate
                                                            }
                                                            dateFormat="YYYY/MM/DD"
                                                            timeFormat={false}
                                                            inputProps={{
                                                                placeholder:
                                                                    "Pick from date",
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        To Date
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Datetime
                                                            onBlur={
                                                                this
                                                                    .updateToDate
                                                            }
                                                            dateFormat="YYYY/MM/DD"
                                                            timeFormat={false}
                                                            inputProps={{
                                                                placeholder:
                                                                    "Pick from date",
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={2}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        Submit
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Button
                                                        onClick={this.fetchApi}
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Fetch Orders
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={6} md={1}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        Redo Login
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <div className="togglebutton switch-sidebar-mini">
                                                            <span
                                                                style={{
                                                                    color:
                                                                        "white",
                                                                }}
                                                                className="label-switch"
                                                            >
                                                                {" "}
                                                                OFF{" "}
                                                            </span>{" "}
                                                            <Switch
                                                                onChange={
                                                                    this
                                                                        .handleSwitchClick
                                                                }
                                                                value={
                                                                    this.state
                                                                        .isRedoLogin
                                                                }
                                                                onText=""
                                                                offText=""
                                                            />
                                                            <span
                                                                style={{
                                                                    color:
                                                                        "white",
                                                                }}
                                                                className="label-switch"
                                                            >
                                                                {" "}
                                                                ON{" "}
                                                            </span>{" "}
                                                        </div>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        {[
                                            "qa",
                                            "manager",
                                            "coordinator",
                                            "admin",
                                        ].includes(this.state.userType) && (
                                            <Col xs={6} md={1}>
                                                <Card>
                                                    <CardHeader>
                                                        <CardTitle>
                                                            QA Panel
                                                        </CardTitle>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <FormGroup>
                                                            <div className="togglebutton switch-sidebar-mini">
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "white",
                                                                    }}
                                                                    className="label-switch"
                                                                >
                                                                    {" "}
                                                                    OFF{" "}
                                                                </span>{" "}
                                                                <Switch
                                                                    onChange={
                                                                        this
                                                                            .handleQASwitchClick
                                                                    }
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .QAPanel
                                                                    }
                                                                    onText=""
                                                                    offText=""
                                                                />
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "white",
                                                                    }}
                                                                    className="label-switch"
                                                                >
                                                                    {" "}
                                                                    ON{" "}
                                                                </span>{" "}
                                                            </div>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={4}
                                            className={
                                                this.state.selectedRows.length
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        Assign
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state
                                                        .showAssignPanel && (
                                                        <Select
                                                            onChange={
                                                                this.onAssign
                                                            }
                                                            defaultValue={{
                                                                value:
                                                                    "Default",
                                                                label:
                                                                    "-- Please Select Assignee --",
                                                                isDisabled: true,
                                                            }}
                                                            options={
                                                                this.state
                                                                    .usersAssignment
                                                            }
                                                            formatGroupLabel={
                                                                formatGroupLabel
                                                            }
                                                            styles={
                                                                colourStyles
                                                            }
                                                        />
                                                    )}
                                                    <Button
                                                                style={{
                                                                    width:
                                                                        "240px",
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .showRejectOption
                                                                        ? ""
                                                                        : "d-none"
                                                                }
                                                                color="primary"
                                                                onClick={
                                                                    this.onAssignMe
                                                                }
                                                            >
                                                                Assign to me
                                                            </Button>
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        "240px",
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .showRejectOption
                                                                        ? ""
                                                                        : "d-none"
                                                                }
                                                                color="primary"
                                                                onClick={
                                                                    this.onAssignRemove
                                                                }
                                                            >
                                                                Remove Assignee
                                                            </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col
                                            xs={12}
                                            md={4}
                                            className={
                                                this.state.selectedRows.length
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        Order Stages
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Button
                                                            style={{
                                                                width: "240px",
                                                            }}
                                                            className={
                                                                this.state
                                                                    .showQaOption
                                                                    ? ""
                                                                    : "d-none"
                                                            }
                                                            color="primary"
                                                            onClick={
                                                                this.onNextClick
                                                            }
                                                        >
                                                            Next Stage
                                                        </Button>
                                                        {/* retouch4me - only 4 artist */}
                                                        {![
                                                            "qa",
                                                            "videoArtist",
                                                            "videoQa",
                                                        ].includes(
                                                            this.state.userType
                                                        ) && (
                                                            <Button
                                                                style={{
                                                                    width:
                                                                        "240px",
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .showQaOption
                                                                        ? ""
                                                                        : "d-none"
                                                                }
                                                                color="primary"
                                                                onClick={
                                                                    this
                                                                        .onMoveTor4me
                                                                }
                                                            >
                                                                MoveToRetouch4me
                                                            </Button>
                                                        )}
                                                        {![
                                                            "artist",
                                                            "qa",
                                                            "videoArtist",
                                                            "videoQa",
                                                        ].includes(
                                                            this.state.userType
                                                        ) && (
                                                            <>
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showQaOption
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .onMoveToQa
                                                                    }
                                                                >
                                                                    MoveToQA
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showVideoQaOption
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .onMoveToVideoQa
                                                                    }
                                                                >
                                                                    MoveToVideoQa
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showVideoArtistOption
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .onMoveToVideoArtist
                                                                    }
                                                                >
                                                                    MoveToVideoArtist
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showPriorityOption
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .sendOnPriority
                                                                    }
                                                                >
                                                                    SendOnPriority
                                                                </Button>
                                                            </>
                                                        )}
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col
                                            xs={12}
                                            md={4}
                                            className={
                                                this.state.selectedRows.length
                                                    ? ""
                                                    : "d-none"
                                            }
                                        >
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>
                                                        Order Actions
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        {/* {![
                                                            "artist",
                                                            "videoArtist",
                                                            "videoQa",
                                                        ].includes(
                                                            this.state.userType
                                                        ) && (
                                                            // <Button
                                                            //     style={{
                                                            //         width:
                                                            //             "240px",
                                                            //     }}
                                                            //     className={
                                                            //         this.state
                                                            //             .showRejectOption
                                                            //             ? ""
                                                            //             : "d-none"
                                                            //     }
                                                            //     color="primary"
                                                            //     onClick={
                                                            //         this
                                                            //             .onReject
                                                            //     }
                                                            // >
                                                            //     Reject
                                                            // </Button>
                                                        )} */}
                                                        {![
                                                            "artist",
                                                            "qa",
                                                            "videoArtist",
                                                            "videoQa",
                                                        ].includes(
                                                            this.state.userType
                                                        ) && (
                                                            <>
                                                                {/* <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showRejectOption
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .onMarkAsDone
                                                                    }
                                                                >
                                                                    MarkAsDone
                                                                </Button> */}
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showOnHold
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .onHold
                                                                    }
                                                                >
                                                                    ToggleHold
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        width:
                                                                            "240px",
                                                                    }}
                                                                    className={
                                                                        this
                                                                            .state
                                                                            .showCancelled
                                                                            ? ""
                                                                            : "d-none"
                                                                    }
                                                                    color="primary"
                                                                    onClick={
                                                                        this
                                                                            .onCancelled
                                                                    }
                                                                >
                                                                    Cancelled
                                                                </Button>
                                                            </>
                                                        )}
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    { this.state.QAPanel? <MyBaseTableQA
                                        isLoading={this.state.isLoading}
                                        records={this.state.tasks}
                                        title={this.state.modal.title}
                                        details={this.state.modal.details}
                                        SceneCounts={
                                            this.state.modal.SceneCounts
                                        }
                                        Files={this.state.modal.Files}
                                        processed={this.state.modal.processed}
                                        issues={this.state.modal.issues}
                                        warnings={this.state.modal.warnings}
                                        modalHandler={this.modalHandler}
                                        onSelectionChange={
                                            this.onSelectionChange
                                        }
                                        count={this.state.selectedFilesCount}
                                    />:<MyBaseTable
                                    isLoading={this.state.isLoading}
                                    records={this.state.tasks}
                                    title={this.state.modal.title}
                                    details={this.state.modal.details}
                                    SceneCounts={
                                        this.state.modal.SceneCounts
                                    }
                                    Files={this.state.modal.Files}
                                    processed={this.state.modal.processed}
                                    issues={this.state.modal.issues}
                                    warnings={this.state.modal.warnings}
                                    modalHandler={this.modalHandler}
                                    onSelectionChange={
                                        this.onSelectionChange
                                    }
                                    count={this.state.selectedFilesCount}
                                />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                {this.state.sweetAlert}

                {this.state.okayAlert && (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Assignee Changed!"
                        onConfirm={() => {
                            this.setState({
                                okayAlert: false,
                            });
                            window.location.reload();
                        }}
                        onCancel={() => {
                            this.setState({
                                okayAlert: false,
                            });
                            window.location.reload();
                        }}
                        confirmBtnBsStyle="info"
                    >
                        Files will start moving in a while.
                    </SweetAlert>
                )}
                {this.state.okayAlert && (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Assignee Changed!"
                        onConfirm={() => {
                            this.setState({
                                okayAlert: false,
                            });
                            window.location.reload();
                        }}
                        onCancel={() => {
                            this.setState({
                                okayAlert: false,
                            });
                            window.location.reload();
                        }}
                        confirmBtnBsStyle="info"
                    >
                        Files will start moving in a while.
                    </SweetAlert>
                )}
                {this.state.doneAlert && (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={`Marked As Done! Orders Skipped:${this.state.skipped}`}
                        onConfirm={() => {
                            this.setState({
                                doneAlert: false,
                            });
                            window.location.reload();
                        }}
                        onCancel={() => {
                            this.setState({
                                doneAlert: false,
                            });
                            window.location.reload();
                        }}
                        confirmBtnBsStyle="info"
                    >
                        Files will start moving in a while.
                    </SweetAlert>
                )}

                {this.state.errorAlert && (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Error Making Changes"
                        onConfirm={() => {
                            this.setState({
                                errorAlert: false,
                                sweetAlert: null,
                            });
                        }}
                        onCancel={() =>
                            this.setState({
                                errorAlert: false,
                                sweetAlert: null,
                            })
                        }
                        confirmBtnBsStyle="info"
                    >
                        Please go ahead and raise a ticket about this{" "}
                        <a href="/admin/help">here</a>.
                    </SweetAlert>
                )}

                {this.state.HoldAlert && (
                    <SweetAlert
                        input
                        showCancel
                        cancelBtnBsStyle="light"
                        title="Hold"
                        placeHolder="Input here"
                        onConfirm={async (response) => {
                            const payload = {
                                ids: this.state.selectedRows,
                                notes: response,
                            };
                            await axios
                                .post("/api/users/markashold", payload)
                                .then(() => {
                                    // console.log(axiosResponse)
                                    window.location.reload();
                                });
                        }}
                        onCancel={() => {
                            this.setState({
                                HoldAlert: false,
                            });
                        }}
                    >
                        Reason for hold:
                    </SweetAlert>
                )}

                {this.state.rejectAlert && (
                    <SweetAlert
                        input
                        showCancel
                        cancelBtnBsStyle="light"
                        title="Reject"
                        placeHolder="Input here"
                        onConfirm={async (response) => {
                            const payload = {
                                artist: "",
                                ids: this.state.selectedRows,
                                notes: response,
                            };
                            await axios
                                .post("/api/users/reject", payload)
                                .then(() => {
                                    // console.log(axiosResponse)
                                    window.location.reload();
                                });
                        }}
                        onCancel={() => {
                            this.setState({
                                rejectAlert: false,
                            });
                        }}
                    >
                        Reason for rejection:
                    </SweetAlert>
                )}

                {this.state.changeLogAlert && (
                    <SweetAlert
                        cancelBtnBsStyle="light"
                        title="Changes Have been Made"
                        onConfirm={async (response) => {
                            window.location.reload();
                        }}
                        onCancel={() => {
                            this.setState({
                                changeLogAlert: false,
                            });
                            window.location.reload();
                        }}
                    >
                        Changelog:
                        <div
                            style={{
                                color: "#525f7f",
                                padding: "30px",
                                textAlign: "justify",
                            }}
                        >
                            {this.state.changeLog[0].length !== 0 && (
                                <p style={{ color: "#525f7f" }}>
                                    The following orders have been moved to the
                                    next stage:
                                </p>
                            )}
                            {this.state.changeLog[0]}
                            {this.state.changeLog[1].length !== 0 && (
                                <p style={{ color: "#525f7f" }}>
                                    The following orders have been skipped due
                                    to some errors:
                                </p>
                            )}
                            {this.state.changeLog[1]}
                        </div>
                        <p style={{ color: "#525f7f" }}>
                            This page will be reloaded on confirmation
                        </p>
                    </SweetAlert>
                )}
            </>
        );
    }
}

// part 2-2 of accessing-global-store
const mapStateToProps = (state) => {
    const user = getUser(state);
    return { user };
};
// part 2-3 of accessing-global-store
export default connect(mapStateToProps)(Orders);
