const defaultState =  {
    skipped: [123,124],
    isRedoLogin: false,
    sweetAlert:null,
    okayAlert: null,
    errorAlert: null,
    rejectAlert: null,
    HoldAlert :null,
    tasks: [],
    originalTasks: [
      {
        Name: "s",
      },
    ],
    MostPopular: true,
    Trad: true,
    Elite: true,
    CapAndGown: true,
    PremierRT: true,
    TraditionalAT: true,
    Yearbook: true,
    Heroes: true,
    open: true,
    downloaded: true,
    delivered: true,
    redo: true,
    fromDate: null,
    toDate: null,
    searchFilter: null,
    infoPanel: [],
    isLoading:false,
    loading:false,
    modal: {
      title: "Task",
      details: "",
      Files: [],
      SceneCounts: [],
      processed: [],
      issues: [],
      warnings: [],
    },
    selectedRows:[],
    showAssignPanel : false,
    showQaAssignPanel: false,
    showVideoArtistAssignPanel : false,
    showVideoQaAssignPanel : false,
    showDatePanel : false,  
    showRejectOption : false,
    showQaOption : false,
    showPriorityOption : false,
    showVideoQaOption : false,
    showVideoArtistOption : false,
    showOnHold : false,
    showCancelled : false,
    changeLogAlert: false,
    changeLog:[]
  };


function userPanelPermissions (role) {
    let permissions = {};
     if(role === "artist"){
      permissions = {
        showAssignPanel : false,
        showQaAssignPanel : false,
        showVideoArtistAssignPanel : false,
        showVideoQaAssignPanel : false, 
        showDatePanel : false,
        showRejectOption : false,
        showQaOption : true,
        showPriorityOption : false,
        showVideoQaOption :false,
        showVideoArtistOption : false,
        showOnHold : false,
        showCancelled : false};
     }
     else if(role === "qa"){ 
      permissions = {
        showAssignPanel : true,
        showQaAssignPanel : true,
        showVideoArtistAssignPanel : true,
        showVideoQaAssignPanel : false,
        showDatePanel : true,
        showRejectOption : true,
        showQaOption : true,
        showPriorityOption :true,
        showVideoQaOption :false,
        showVideoArtistOption : true,
        showOnHold : false,
        showCancelled : false};
     }
     else if(role === "videoQa"){ 
      permissions = {
        showAssignPanel : true,
        showQaAssignPanel : false,
        showVideoArtistAssignPanel : false,
        showVideoQaAssignPanel: true,
        showDatePanel : true,
        showRejectOption : true,
        showQaOption : true,
        showPriorityOption : true,
        showVideoQaOption :true,
        showVideoArtistOption:false,
        showOnHold : false,
        showCancelled : false};
     }
     else if(role === "videoArtist"){ 
      permissions = {
        showAssignPanel : true,
        showQaAssignPanel : false, 
        showVideoArtistAssignPanel : false,
        showVideoQaAssignPanel : false,
        showDatePanel : true,
        showRejectOption : false,
        showQaOption : true,
        showPriorityOption : false,
        showVideoQaOption :true,
        showVideoArtistOption :false,
        showOnHold : false,
        showCancelled : false};
     }
     else{
      permissions = {
        showAssignPanel : true,
        showQaAssignPanel : false,
        showVideoArtistAssignPanel : false,
        showVideoQaAssignPanel : false,
        showDatePanel : true,
        showRejectOption : true,
        showQaOption : true,
        showPriorityOption : true,
        showVideoQaOption :true,
        showVideoArtistOption : true,
        showOnHold : true,
        showCancelled : true};
     }
     return permissions
}

export { defaultState, userPanelPermissions}
