import React from "react";

import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  // Button
} from "reactstrap";

// import { Link } from "react-router-dom";


function OrderStats({ props }) {

  function onKeyPressed() {
    // console.log("key down")
  };


  const {
    // totalOrders,
    // totalRedos,
    // pendingOrders,
    // tasksDumped,
    // lastMonth,
    pending12Hrs,
    //  pending18Hrs,
    pending24Hrs,
    pending48Hrs,
    receivedMonthly,
    deliveredMonthly,
    redosMonthly,
      //  currentPendingOrders,
          //  redoPendancy,
    totalRedoPendancy,
    totalRedoPendancyFiles,
    kpiReceivedPercentage,
    kpiDeliveredPercentage,
    totalPendingOrders,
    totalPendingOrdersFiles,
    filesCount24Hr,
    filesCount12Hr,
    filesCount48Hr } = props;
  return <Row>
    <Col lg="4" md="6">
      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/order-received`); }}  >
        <CardBody>
          <div >
            <Row>
              <Col xs="5">
                <div className="info-icon text-center icon-warning" >
                  <i className="tim-icons icon-check-2" />

                </div>

              </Col>
              <Col xs="7">
                <div className="numbers">
                  <p className="card-category">Orders Recieved</p>
                  <p className="card-category">(MTD vs MLTD)</p>
                  <CardTitle tag="h3">
                    {receivedMonthly || "-"}
                  </CardTitle>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {kpiReceivedPercentage}
          </div>
        </CardFooter>
      </Card>
    </Col>
    <Col lg="4" md="6">
      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/order-delivered`); }}>
        <CardBody>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-double-right" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Orders Delivered</p>
                <p className="card-category">(MTD vs MLTD)</p>
                <CardTitle tag="h3">
                  {deliveredMonthly || "-"}
                </CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {kpiDeliveredPercentage}


          </div>
        </CardFooter>
      </Card>
    </Col>
    <Col lg="4" md="6">

      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/redo-orders`); }}  >
        <CardBody style={{
          minHeight:"128px"
        }}>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-danger">
                <i className="tim-icons icon-refresh-01" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Redos</p>
                <p className="card-category">(MTD vs MLTD)</p>
                <CardTitle tag="h3">
                  {redosMonthly || "-"}
                </CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="tim-icons icon-refresh-01" />
          Resent by Client
        </div>
        </CardFooter>
      </Card>
    </Col>
{/* 
    <Col lg="3" md="6">
      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/current-pending-orders`); }}  >
        <CardBody>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-molecule-40" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Current Pending Orders</p>
                <CardTitle tag="h3">{currentPendingOrders}</CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
          </div>
        </CardFooter>
      </Card>
    </Col> */}

    {/* <Col lg="3" md="6">
      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/current-redo-pending-orders`); }}  >
        <CardBody>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-danger">
                <i className="tim-icons icon-alert-circle-exc" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Current Redo Pendancy</p>
                <CardTitle tag="h3">
                  {redoPendancy || "-"}
                </CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
          </div>
        </CardFooter>
      </Card>
    </Col> */}
    <Col lg="4" md="6">
      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/overall-pending-orders`); }}  >
        <CardBody style={{
          minHeight:"128px"
        }}>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-bag-16" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Overall Pending Orders</p>
                <CardTitle tag="h3">
                  {totalPendingOrders || "-"}
                </CardTitle>
                <p className="card-category">FileCount: {totalPendingOrdersFiles} </p>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {/* <i className="tim-icons icon-trophy" />
          Recieved yet */}
          </div>
        </CardFooter>
      </Card>
    </Col>
    <Col lg="4" md="6">
      <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/overall-redo-pending-orders`); }}  >
        <CardBody style={{
          minHeight:"128px"
        }}>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-warning">
                <i className="tim-icons icon-basket-simple" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Overall redo Pendancy</p>
                <CardTitle tag="h3">
                  {totalRedoPendancy || "-"}
                </CardTitle>
                <p className="card-category">FileCount: {totalRedoPendancyFiles} </p>
                 
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {/* <i className="tim-icons icon-trophy" />
          Recieved yet */}
          </div>
        </CardFooter>
      </Card>
    </Col>
    
    <Col lg="4" md="6">
    <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/pending-orders-12HRS`); }}  >
              <CardBody style={{
          minHeight:"128px"
        }}>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-molecule-40" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Pending Orders</p>
                <p className="card-category">( 12 HRS LATE )</p>
                <CardTitle tag="h3">{pending12Hrs}</CardTitle>
                <p className="card-category">FileCount: {filesCount12Hr} </p>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {/* <i className="tim-icons icon-watch-time" />
          Errored tasks */}
          </div>
        </CardFooter>
      </Card>
    </Col>

    {/* <Col lg="4" md="6">
    <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/pending-orders-18HRS`); }}  >
        <CardBody>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-molecule-40" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Pending Orders</p>
                <p className="card-category">( 18 HRS LATE )</p>
                <CardTitle tag="h3">{pending18Hrs}</CardTitle>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats"> */}
            {/* <i className="tim-icons icon-watch-time" />
          Errored tasks */}
          {/* </div>
        </CardFooter>
      </Card>
    </Col> */}

    <Col lg="4" md="6">
    <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/pending-orders-24HRS`); }}  >
           <CardBody style={{
          minHeight:"128px"
        }}>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-molecule-40" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Pending Orders</p>
                <p className="card-category">( 24 HRS LATE )</p>
                <CardTitle tag="h3">{pending24Hrs}</CardTitle>
                <p className="card-category">FileCount: {filesCount24Hr} </p>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {/* <i className="tim-icons icon-watch-time" />
          Errored tasks */}
          </div>
        </CardFooter>
      </Card>
    </Col>

    <Col lg="4" md="6">
    <Card className="card-stats" role="button" tabIndex={-1} onKeyDown={onKeyPressed} onClick={(event) => { event.preventDefault(); window.open(`/orderKPI/pending-orders-48HRS`); }}  >
              <CardBody style={{
          minHeight:"128px"
        }}>
          <Row>
            <Col xs="5">
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-molecule-40" />
              </div>
            </Col>
            <Col xs="7">
              <div className="numbers">
                <p className="card-category">Pending Orders</p>
                <p className="card-category">( 48 HRS LATE )</p>
                <CardTitle tag="h3">{pending48Hrs}</CardTitle>
                <p className="card-category">FileCount: {filesCount48Hr} </p>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            {/* <i className="tim-icons icon-watch-time" />
          Errored tasks */}
          </div>
        </CardFooter>
      </Card>
    </Col>
  </Row>

}


export default OrderStats;