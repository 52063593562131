import React from "react";
import "react-base-table/styles.css";
import MaterialTable from 'material-table'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import columns from './Columns';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#f36df0',
    },
    background:{
      default:{main:'#27293d'},
      paper:'#27293d'
    }
  },
});


function MyBaseTable(records = []) {


  
  return (
    <div style={{ maxWidth: '100%' }}>
      <ThemeProvider theme={theme}>
      <MaterialTable
      isLoading={records.isLoading}
        options={
            { columnsButton:true,
              exportButton:true,
              exportAllData:true,
              filtering:true,
              toolbar:true,
              pageSize:20,
              pageSizeOptions : [20,100,500],
            }}
        columns={columns}
        data={records.records}
        title=""
        onSelectionChange = {records.onSelectionChange}
      />
      </ThemeProvider>
    </div>
  )


}

export default MyBaseTable;
