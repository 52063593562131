/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://photo-wonder.com/product/black-dashboard-pro-react
* Copyright 2020 Photo-Wonder (https://photo-wonder.com)

* Coded by Photo-Wonder

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {
    NavLink
} from "react-router-dom";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import logo1 from "../assets/img/pw-logo.png"
// reactstrap components
import {
    Nav,
    Collapse
} from "reactstrap";
import { connect } from "react-redux";
import { getUser } from "../../actions/selectors"

import config from '../../config/config'
var ps;

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getCollapseStates(props.routes);
    }
    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i <routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = (routes,user) => {
        const {
            rtlActive
        } = this.props;
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return ( <li className = {
                        this.getCollapseInitialState(prop.views) ? "active" : ""
                    }
                    key = {
                        key
                    } >
                    <a href = "#pablo"
                    data-toggle = "collapse"
                    aria-expanded = {
                        this.state[prop.state]
                    }
                    onClick = {
                        e => {
                            e.preventDefault();
                            this.setState(st);
                        }
                    } >
                    {
                        prop.icon !== undefined ? ( <>
                            <i className = {
                                prop.icon
                            }
                            /> <p > {
                                rtlActive ? prop.rtlName : prop.name
                            } <b className = "caret" />
                            </p> </>
                        ) : ( <>
                            <span className = "sidebar-mini-icon" > {
                                rtlActive ? prop.rtlMini : prop.mini
                            } </span> <span className = "sidebar-normal" > {
                                rtlActive ? prop.rtlName : prop.name
                            } <b className = "caret" />
                            </span> </>
                        )
                    } </a> <Collapse isOpen = {
                        this.state[prop.state]
                    } >
                    <ul className = "nav" > {
                        this.createLinks(prop.views)
                    } </ul> </Collapse> </li>
                );
            }
            const x = <li className = {
                this.activeRoute(prop.layout + prop.path)
            }
            key = {
                key
            } >
            <NavLink to = {{
                pathname:prop.layout + prop.path,
                locationProps:user
            }
            }
            activeClassName = ""
            onClick = {
                this.props.closeSidebar
            } >
            {
                prop.icon !== undefined ? ( <>
                    <i className = {
                        prop.icon
                    }
                    /> <p > {
                        rtlActive ? prop.rtlName : prop.name
                    } </p> </>
                ) : ( <>
                    <span className = "sidebar-mini-icon" > {
                        rtlActive ? prop.rtlMini : prop.mini
                    } </span> <span className = "sidebar-normal" > {
                        rtlActive ? prop.rtlName : prop.name
                    } </span> </>
                )
            } </NavLink> </li>
                
            if ( prop.path === '/dashboard'){
                if(config.isAdmin(user) || config.isCoordinator(user) || config.isManager(user) || config.isQA (user) || config.isVideoQA(user))
                return (x);
              }
              else if ( prop.path === '/qa'){
                if ((user.name != "joseph") && (config.isAdmin(user) || config.isCoordinator(user) || config.isManager(user) || config.isQA (user)))
                return (x);
              }
            else if(prop.path === '/users'){
                if ((user.name != "joseph") && (config.isAdmin(user) || config.isCoordinator(user) || config.isManager(user) || config.isQA (user) || config.isVideoQA(user)))
                    return (x);
                }
            else if(prop.path === '/artists'){
                if  ((user.name != "joseph") && (config.isAdmin(user) || config.isCoordinator(user) || config.isManager(user) || config.isQA (user) || config.isVideoQA(user)))
                    return (x);
                 }
                 else if(prop.path === '/reports'){
                    if ((user.name != "joseph") && (config.isAdmin(user) || config.isCoordinator(user) || config.isManager(user) || config.isQA (user) || config.isVideoQA(user)))
                        return (x);
                     }
		else if(prop.path === '/dailyreports'){
                        if (  (user.name != "Youreditingartist1") && (user.name != "Youreditingartist2") && (user.name != "Youreditingartist3") && (user.name != "Youreditingartist4") && (user.name != "joseph")   )
                            return (x);
                         }
        else if(prop.path === '/bill'){
                            if (user.name.includes("billing"))   
                                return (x);
                             }
            else {
                return (x)
            }

        });
    };
    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    componentDidMount() {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebar, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        const {
            activeColor,
            logo
        } = this.props;
        let logoImg = null;
        let logoText = null;
        if (logo !== undefined) {
            if (logo.outterLink !== undefined) {
                logoImg = ( <a href = {
                        logo.outterLink
                    }
                    className = "simple-text logo-mini"
                    target = "_blank"
                    onClick = {
                        this.props.closeSidebar
                    } >
                    <div className = "logo-img" >
                    <img alt = "..."
            src = {
                logo1
            }
            /> 
            </div> </a>
                );
                logoText = ( <a href = {
                        logo.outterLink
                    }
                    className = "simple-text logo-normal"
                    target = "_blank"
                    onClick = {
                        this.props.closeSidebar
                    } >
                    {
                        logo.text
                    } </a>
                );
            } else {
                logoImg = ( <NavLink to = {
                        logo.innerLink
                    }
                    className = "simple-text logo-mini"
                    onClick = {
                        this.props.closeSidebar
                    } >
                    <div className = "logo-img" >
                    <img src = {
                        logo.imgSrc
                    }
                    alt = "react-logo"/>
                    </div> </NavLink>
                );
                logoText = ( <NavLink to = {
                        logo.innerLink
                    }
                    className = "simple-text logo-normal"
                    onClick = {
                        this.props.closeSidebar
                    } >
                    {
                        logo.text
                    } </NavLink>
                );
            }
        }
        return ( <div className = "sidebar"
            data = {
                activeColor
            } >
            <div className = "sidebar-wrapper"
            ref = "sidebar" > {
                logoImg !== null || logoText !== null ? ( <div className = "logo" > {
                        logoImg
                    } {
                        logoText
                    } </div>
                ) : null
            } <Nav > {
                this.createLinks(this.props.routes,this.props.user)
            } </Nav> </div> </div>
        );
    }
}

Sidebar.propTypes = {
    activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
    rtlActive: PropTypes.bool,
    routes: PropTypes.array.isRequired,
    logo: PropTypes.oneOfType([
        PropTypes.shape({
            innerLink: PropTypes.string.isRequired,
            imgSrc: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        }),
        PropTypes.shape({
            outterLink: PropTypes.string.isRequired,
            imgSrc: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
        })
    ]),
    // this is used on responsive to close the sidebar on route navigation
    closeSidebar: PropTypes.func
};

const mapStateToProps = state => {
    const user = getUser(state);
    return { user };
  };
export default connect(mapStateToProps)(Sidebar);
