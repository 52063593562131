/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable global-require */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/*!

=========================================================
* Black Dashboard PRO React-v1.1.0
=========================================================

* Product Page: https://photo-wonder.com/product/black-dashboard-pro-react
* Copyright 2020 Photo-Wonder (https://photo-wonder.com)

* Coded by Photo-Wonder

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// import Avatar from 'react-avatar';

// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    // UncontrolledTooltip
} from "reactstrap";
import { GoogleLogout } from 'react-google-login';
import loginImage from "../assets/img/card-primary.png"

class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            modalSearch: false,
            color: "navbar-transparent"
        };
    }

    redirect() {
        window.location.href = '/admin/user-profile';
    }

    logout() {
        localStorage.clear();
        window.location.href = '/';
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColor);
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
            this.setState({
                color: "bg-white"
            });
        } else {
            this.setState({
                color: "navbar-transparent"
            });
        }
    };

    // this function opens and closes the collapse on small devices
    toggleCollapse = () => {
        if (this.state.collapseOpen) {
            this.setState({
                color: "navbar-transparent"
            });
        } else {
            this.setState({
                color: "bg-white"
            });
        }
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };

    // this function is to open the Search modal
    toggleModalSearch = () => {
        this.setState({
            modalSearch: !this.state.modalSearch
        });
    };

    googleButton = renderProps => (
        <span
        onClick={renderProps.onClick}
        disabled={renderProps.disabled}
         />
    )

    render() {
        return (<>
            <Navbar
                className=
                {
                    classNames("navbar-absolute", {
                        [this.state.color]: false
                    })
                }
                expand="lg" >
                <Container fluid >
                    <div className="navbar-wrapper" >
                        <div className={
                            classNames("navbar-toggle d-inline", {
                                toggled: this.props.sidebarOpened
                            })
                        } >
                            <button className="navbar-toggler"
                                type="button"
                                onClick={
                                    this.props.toggleSidebar
                                } >
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button> </div> <NavbarBrand href="#pablo"
                                onClick={
                                    e => e.preventDefault()
                                } > {
                                this.props.brandText
                            } </NavbarBrand> </div> <button className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navigation"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={
                                    this.toggleCollapse
                                } >
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </button> <Collapse navbar isOpen={
                        this.state.collapseOpen
                    } >
                        <Nav className="ml-auto"
                            navbar >
                            <InputGroup className="search-bar"
                                tag="li" >
                                <Button color="link"
                                    data-target="#searchModal"
                                    data-toggle="modal"
                                    id="search-button"
                                    onClick={
                                        this.toggleModalSearch
                                    } >
                                    <i className="tim-icons icon-zoom-split" />
                                    <span className="d-lg-none d-md-block" > Search </span> </Button> </InputGroup>


                            <UncontrolledDropdown nav >

                                <DropdownToggle caret color="default"
                                    data-toggle="dropdown"
                                    nav onClick={
                                        e => e.preventDefault()
                                    } >
                                    <div className="photo" >
                                        <img alt="..."
                                            src={loginImage}
                                        />
                                    </div>
                                    <b className="caret d-none d-lg-block d-xl-block" />
                                    <p className="d-lg-none" >
                                        <GoogleLogout
                                            clientId="76804024670-qc315e54c20gu5qrqciqsf5vdrf189s4.apps.googleusercontent.com"
                                            buttonText="Logout"
                                            onLogoutSuccess={this.logout}
                                            render={this.googleButton}
                                         />
                                    </p> </DropdownToggle> <DropdownMenu className="dropdown-navbar"
                                        right tag="ul" >
                                 
                                    <NavLink tag="li" onClick={this.logout} >
                                        <DropdownItem className="nav-item"> Log out </DropdownItem> </NavLink> </DropdownMenu> </UncontrolledDropdown> <li className="separator d-lg-none" />
                        </Nav> </Collapse> </Container> </Navbar> <Modal modalClassName="modal-search"
                            isOpen={
                                this.state.modalSearch
                            }
                            toggle={
                                this.toggleModalSearch
                            } >
                <div className="modal-header" >
                    <Input id="inlineFormInputGroup"
                        placeholder="SEARCH"
                        type="text" />
                    <button aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={
                            this.toggleModalSearch
                        } >
                        <i className="tim-icons icon-simple-remove" />
                    </button> </div> </Modal> </>
        );
    }
}

export default AdminNavbar;