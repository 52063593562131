/* eslint-disable react/no-unused-state */
import React from "react";
// import 'bootstrap/dist/css/bootstrap.css';


// reactstrap components
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ClickupFrame({src}) {
    return (
    <>
            <iframe
              title="Cady Order Related Issues"
              className="clickup-embed clickup-dynamic-height"
              src={src}
              frameBorder="0"
              style={{
                background: "transparent",
                position: "absolute",
                top: 60,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%"
              }}
            />
            <script
              async
              src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
            />
    </>
)
    
} 

function Cardtkt() {
  const [modalOpen, setModalOpen] = React.useState(true);
  const [url, setUrl] = React.useState("")
  return (
    <>
    
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h1 className=" modal-title" id="exampleModalLabel">
            Choose Your Issue
          </h1>
          
        </div>
        <ModalBody>
        <div>
          <h5 className=" modal-title" id="exampleModalLabel">Raise Order/Panel Related Issue :</h5>     
        <Button
            id="capy panel"
            color="primary"
            type="button"
            onClick={() => {setUrl("https://forms.clickup.com/3700557/f/3gxud-29804/YPY6L2PUCD7HA6KSQU");setModalOpen(false)}}
          >
            Cady Helpdesk
          </Button>
            
          <h5 className=" modal-title" id="exampleModalLabel">Raise Internet/Hardware/FTP Related Issue :</h5> 
          <Button
            id="Helpdesk panel"
            color="secondary"
            type="button"
            onClick={() => {setUrl("https://forms.clickup.com/3700557/f/3gxud-1215/CZ2W4R2GT9O5NEVPTU");setModalOpen(false)}}
          >
            General Helpdesk
          </Button>

          </div>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
      <div><ClickupFrame src={url} />
      
      
      </div>
      
    </>
  );
}

export default Cardtkt;