/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

// import Navbar from "components/Navbars/AdminNavbar";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { registerUser } from "../../actions/authActions";
import ConsoleHelper from "../../helpers/ConsoleHelper";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      type : "",
      orgId : "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/admin/dashboard");
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      type: this.state.type,
      orgId: this.state.orgId
    };
    ConsoleHelper(newUser);
    this.props.registerUser(newUser, this.props.history);
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="content" style={{ paddingTop: 46 }}>
        <Container>
          <Row>
            <Col className="ml-auto" md="5">
              <div className="info-area info-horizontal mt-5">
                <div className="icon icon-warning">
                  <i className="tim-icons icon-wifi" />
                </div>{" "}
                <div className="description">
                  <h3 className="info-title"> Production </h3>{" "}
                  <p className="description">
                    We are the production backbone for many of the worlds
                    largest photo and video companies.{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="info-area info-horizontal">
                <div className="icon icon-primary">
                  <i className="tim-icons icon-triangle-right-17" />
                </div>{" "}
                <div className="description">
                  <h3 className="info-title"> Support </h3>{" "}
                  <p className="description">
                    We provide quick scalable production support for a wide
                    range of industries at a highly competitive price.{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="info-area info-horizontal">
                <div className="icon icon-info">
                  <i className="tim-icons icon-trophy" />
                </div>{" "}
                <div className="description">
                  <h3 className="info-title"> Security </h3>{" "}
                  <p className="description">
                    All of our work is processed on the cloud providing the
                    highest security and full transparency{" "}
                  </p>{" "}
                </div>{" "}
              </div>
            </Col>
            <Col className="mr-auto" md="7">
              <Card className="card-register card-white">
                <CardHeader>
                  <CardImg
                    alt="..."
                    // eslint-disable-next-line global-require
                    src={require("assets/img/card-primary.png")}
                  />
                  <CardTitle tag="h4"> Register </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form className="form" noValidate onSubmit={this.onSubmit}>
                    <InputGroup>
                      <ImageUpload avatar />
                    </InputGroup>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <InputGroup
                          className={classnames("", {
                            invalid: errors.name,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="tim-icons icon-single-02" />{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Full Name"
                            onChange={this.onChange}
                            value={this.state.name}
                            error={errors.name}
                            id="name"
                            type="text"
                          />
                        </InputGroup>
                        <span className="red-text">{errors.name}</span>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <InputGroup
                          className={classnames("", {
                            invalid: errors.email,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            id="email"
                            type="email"
                          />
                        </InputGroup>
                        <span className="red-text">{errors.email}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <InputGroup
                          className={classnames("", {
                            invalid: errors.password,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            id="password"
                            type="password"
                          />
                        </InputGroup>
                        <span className="red-text">{errors.password}</span>
                      </Col>

                      <Col className="pr-md-1" md="6">
                        <InputGroup
                          className={classnames("", {
                            invalid: errors.password2,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            onChange={this.onChange}
                            value={this.state.password2}
                            error={errors.password2}
                            id="password2"
                            type="password"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <InputGroup
                          className={classnames("", {
                            invalid: errors.password2,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="tim-icons icon-components" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="select"
                            name="select"
                            id="type"
                            onChange={this.onChange}
                          >
                            <option selected>Type</option>
                            <option value="admin">Admin</option>
                            <option value="artist">Artist</option>
                            <option value="videoArtist">VideoArtist</option>
                            <option value="manager">Manager</option>
                            <option value="coordinator">Coordinator</option>
                            <option value="qa">QA</option>
                            <option value="videoQa">VideoQA</option>
                            <option value="redo">Redo</option>
                          </Input>
                        </InputGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <InputGroup
                          className={classnames("", {
                            invalid: errors.password2,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="tim-icons icon-shape-star" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="select"
                            name="select"
                            id="orgId"
                            onChange={this.onChange}
                          >
                            <option selected>Organization</option>
                            <option value="e0001">Cady</option>
                            <option value="e0002">Soos</option>
                          </Input>
                        </InputGroup>
                      </Col>
                    </Row>

                    <FormGroup check className="text-left">
                      <Label check>
                        <Input type="checkbox" />
                        <span className="form-check-sign" /> I agree to the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          terms and conditions{" "}
                        </a>
                        .{" "}
                      </Label>
                    </FormGroup>
                    <Button
                      className="btn-round"
                      color="primary"
                      type="submit"
                      size="lg"
                    >
                      {" "}
                      Get Started{" "}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <Navbar newUser = {this.newUser}/> */}
      </div>
    );
  }
}
Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  registerUser,
})(withRouter(Register));
