import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Card,
    CardBody,
    CardTitle,
    CardHeader
  } from "reactstrap";

const MonthlyGraph = ({props}) =>{

    const {totalOrders,graphMonthlySales,chartExample3}=props;

    return<Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Sales type distribution</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-delivery-fast text-primary" />
                    {totalOrders}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Bar
                      data={graphMonthlySales || chartExample3.data}
                      options={chartExample3.options}
                    />
                  </div>
                </CardBody>
              </Card>

}

export default MonthlyGraph;