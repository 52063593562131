/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
// import { MDBDataTableV5 } from "mdbreact";

import {
  // Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  // Table,
  Row,
  Col,
  // UncontrolledTooltip,
  FormGroup,
  Button,
} from "reactstrap";

import ReactLoading from "react-loading";
import MyBaseTable from "./ReportsTable";
import Datetime from "react-datetime";

const axios = require("axios");

class Artists extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
      infoPanel: [],
      isrendered: false,
      checkbox1: [],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchApi();
    }, 400);
    console.log("Child did mount.");
  }


  onSelectionChange = async (rows) =>{
    console.log(rows)
    const data = await rows.map(row => 
      row.email)
    this.setState({
      checkbox1:data
    })
  
  }

  setTableValues = async (logs) => {
  const logList = logs;
  const artistList = [];
    const self = this;

  for (let i = 0; i < logList.length; i+= 1) {
    const log = logList[i];
    console.log(log)
    if(log.action === 'assign'){
      // eslint-disable-next-line no-continue
      continue;
    }
        artistList.push(log)
   }

   self.setState({
        infoPanel: artistList,
        isrendered: true,
    });
  }


  fetchApi = () => {

     let self = this;
     let {fromDate,toDate} = self.state;

     axios.get(`/api/reports?light=true&fromDate=${fromDate}&toDate=${toDate}`).then((response) => {
        this.setTableValues(response.data.data);
          })
          .catch((error) => {
            
              console.log(error);
          })
          .finally(() => {
              // always executed
          });
  };
    
  updateToDate = (e) => {
          this.setState({
              toDate: e,
              
          });
  };

  updateFromDate = (e) => {
          this.setState({
              fromDate: e,
          });
  }; 
      
render() {
  
    return (
      <>
          <div className="content">
          <Row>
            <Col lg="12">
              <Card className="card-chart" style={{ minHeight: "557px" }}>
                <CardHeader>
                  <CardTitle tag="h4">Daily Report</CardTitle>
                </CardHeader>
                <div>
                  <Row>
                <Col xs={12} md={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>From Date</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Datetime
                                                            onBlur={
                                                                this
                                                                    .updateFromDate
                                                            }
                                                            dateFormat="YYYY/MM/DD"
                                                            timeFormat={true}
                                    
                                                            filter={this.dateFrom}
                                                            inputProps={{
                                                                placeholder:
                                                                    "Start date",
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>To Date</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Datetime
                                                            onBlur={
                                                                this
                                                                    .updateToDate
                                                            }
                                                            dateFormat="YYYY/MM/DD"
                                                            timeFormat={true}
                                                            filter={this.dateto}
                                                            inputProps={{
                                                                placeholder:
                                                                    "End date",
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
                                        </Col>  
                                        <Col xs={8} md={2}>
                                            <Card>
                                                <CardHeader>
                                                <CardTitle></CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Button
                                                        onClick={this.fetchApi}
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Filter Reports
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                       </Row> 
          
                </div>
                <CardBody>
                  {!this.state.isrendered ? (
                    <div style={{ margin: "100px 450px 150px" }}>
                      <ReactLoading type="spinningBubbles" color="#e14eca" />
                    </div>
                  ) : (
                  <MyBaseTable
                    records={this.state.infoPanel}
                    onSelectionChange={this.onSelectionChange}
                  />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default Artists;
