const cron_order_limit = 5000;
const pool_limit = 200;
const entities = {
    organization: "organization",
    organizations: "organizations",
    user: "user",
    users: "users",
    task: "task",
    tasks: "tasks",
};
const videoExt = ["CTG", "ctg", "OGG", "MPG", "MP2", "MPEG", "MPE", "MPV", "WEBM", "MP4", "M4P", "M4V", "AVI", "WMV", "MOV", "QT", "FLV", "SWF",
    "AVCHD", "ogg", "mpg", "mp2", "mpeg", "mpe", "mpv", "webm", "mp4", "m4p", "m4v", "avi", "wmv", "mov", "qt", "flv", "swf", "avchd"];
const audioExt = ["PDF", "pdf", "dat", "DAT", "3gp", "8svx", "aa", "aac", "aax", "act", "aiff", "alac", "amr", "ape", "au", "awb", "cda", "dct", "dss", "dvf", "flac", "gsm", "iklax", "ivs", "m4a", "m4b", "m4p", "mmf", "mp3", "mpc", "msv", "nmf", "ogg", "opus", "ra", "raw", "rf64", "sln", "tta", "voc", "vox", "wav",
    "webm", "wma", "wv", "3GP", "8SVX", "AA", "AAC", "AAX", "ACT", "AIFF", "ALAC", "AMR", "APE", "AU", "AWB", "CDA", "DCT", "DSS", "DVF", "FLAC", "GSM", "IKLAX", "IVS", "M4A", "M4B", "M4P", "MMF", "MP3", "MPC", "MSV", "NMF", "OGG", "OPUS", "RA", "RAW", "RF64", "SLN", "TTA", "VOC", "VOX", "WAV", "WEBM", "WMA", "WV"];
const imageExt = ["JPG", "PNG", "GIF", "WEBP", "TIFF", "PSD", "RAW", "BMP", "HEIF", "INDD", "JPEG", "SVG", "AI", "EPS", "PDF", "JPG", "JPEG", "JPE", "JIF", "JFIF", "JFI", "PNG", "GIF", "WEBP", "TIFF", "TIF", "PSD", "RAW", "ARW", "CR2", "CR3", "NRW", "K25", "BMP", "DIB", "HEIF", "HEIC", "IND", "INDD", "INDT", "JP2", "J2K", "JPF", "JPX", "JPM", "MJ2", "SVG", "SVGZ", "AI", "EPS",
    "PDF", "jpg", "png", "gif", "webp", "tiff", "psd", "raw", "bmp", "heif", "indd", "jpeg", "svg", "ai", "eps", "pdf", "jpg", "jpeg", "jpe", "jif", "jfif", "jfi", "png", "gif", "webp", "tiff", "tif", "psd", "raw", "arw", "cr2", "cr3", "nrw", "k25", "bmp", "dib", "heif", "heic", "ind", "indd", "indt", "jp2", "j2k", "jpf", "jpx", "jpm", "mj2", "svg", "svgz", "ai", "eps", "pdf"];

const response = {
    status: "",
    data: {},
    message: "",
};
const errorResponse = {
    status: "error",
    data: {},
    message: "Something wrong",
};
const okResponse = {
    status: "success",
    data: {},
    message: "",
};

const errorMessages = {
    duplicate: {
        name: "",
        code: 11000,
        responseMessage: "Already exists, please ensure to supply unique id",
    },
    validation: {
        name: "ValidationError",
        code: "",
        responseMessage: "Data not valid, please ensure to supply unique id",
    },
    error: {
        name: "error",
        code: "",
        responseMessage:
            "Something went wrong. Please contact support@photografic.com",
    },
    incorrectRequest: {
        name: "incorrectRequest",
        code: "",
        responseMessage: "Format is incorrect",
    },
    noData: {
        name: "noData",
        code: "",
        responseMessage: "No data found!",
    },
};

const logLevels = {
    none: "none",
    info: "info",
    error: "error",
    debug: "debug",
    verbose: "verbose",
};

const db = {
    address: "127.0.0.1",
    port: "27017",
    database: "theApp",
};

const environments = { prod: "production", qa: "qa", dev: "development" };
const app = { port: 3005, enableSSL: 1 };
const NODE_ENV = "dev";
const roles = {
    admin: "admin",
    mgr: "manager",
    artist: "artist",
    qa: "qa",
    vartist: "videoArtist",
    vqa: "videoQa",
    co: "coordinator", 
};
const actions = {
    assign: "assign",
    movetoqa: "movetoqa",
    movetor4me: "movetor4me",
    markasdone: "markasdone",
    movetoreject: "movetoreject",
    movetovideoqa: "movetovideoqa",
    movetovideoartist: "movetovideoartist",
};
const SalesType = {
    Elite: "Elite",
    "Most Popular": "Most Popular",
    Yearbook: "Yearbook",
    Heroes: "Heroes",
    "Traditional-ArtistTouch": "Traditional-ArtistTouch",
    Traditional: "Traditional",
    "Most Popular-ArtistTouch": "Most Popular-ArtistTouch",
    PremierRT: "PremierRT",
    UCPremierRT: "UCPremierRT",
    UCEnhancedRT: "UCEnhancedRT",
    ATPremierRT: "ATPremierRT",
    RetakeArtistTouch :"Retake-ArtistTouch",
    Retake :"Retake",
};
const SalesTypes = {
    elite: "Elite",
    mostPopular: "Most Popular",
    yearbook: "Yearbook",
    heroes: "Heroes",
    traditionalArtistTouch: "Traditional-ArtistTouch",
    traditional: "Traditional",
    mostPopularArtistTouch: "Most Popular-ArtistTouch",
    premierRt: "PremierRT",
    ucPremierRt: "UCPremierRT",
    ucEnhancedRt: "UCEnhancedRT",
    atPremierRt: "ATPremierRT",
    RetakeArtistTouch :"Retake-ArtistTouch",
    Retake :"Retake",
};
const isAdmin = (user) => {
    if ("type" in user && user.type === roles.admin) {
        return true;
    }
    return false;
};
const isManager = (user) => {
    if (user && "type" in user && user.type === roles.mgr) {
        return true;
    }
    return false;
};
const isQA = (user) => {
    if (user && "type" in user && user.type === roles.qa) {
        return true;
    }
    return false;
};
const isCoordinator = (user) => {
    if (user && "type" in user && user.type === roles.co) {
        return true;
    }
    return false;
};
const isArtist = (user) => {
    if (user && "type" in user && user.type === roles.artist) {
        return true;
    }
    return false;
};
const isVideoArtist = (user) => {
    if (user && "type" in user && user.type === roles.vartist) {
        return true;
    }
    return false;
};
const isVideoQA = (user) => {
    if (user && "type" in user && user.type === roles.vqa) {
        return true;
    }
    return false;
};
const isRedo = (user) => {
    if (user && "type" in user && user.type === roles.redo) {
        return true;
    }
    return false;
};
const stages = {
    cancelled: "cancelled",
    done: "done",
    hold: "hold",
    issue: "issue",
    ko: "KO",
    koError: "KOError",
    koProc: "KOProc",
    movetor4me: "movetor4me",
    movetor4meDone: "movetor4meDone",
    movetor4meRequested: "movetor4meRequested",
    notified: "Notified",
    qa: "qa",
    qaRequested: "qaRequested",
    received: "Received",
    receivedNoKO: "ReceivedNoKO",
    rejected: "rejected",
    videoArtist: "videoArtist",
    videoArtistRequested: "videoArtistRequested",
    videoqa: "videoQa",
    videoqaRequested: "videoQaRequested",
};
const nextStages = {
    [stages.received]: stages.qaRequested,
    [stages.rejected]: stages.qaRequested,
    [stages.receivedNoKO]: stages.qaRequested,
    [stages.koError]: stages.qaRequested,
    [stages.movetor4meRequested]: stages.qaRequested,
    [stages.movetor4me]: stages.qaRequested,
    [stages.movetor4meDone]: stages.qaRequested,
    [stages.qa]: stages.videoArtistRequested,
    [stages.videoArtist]: stages.videoqaRequested,
    [stages.videoqa]: stages.done,
};
const videoSalesTypes = [
    SalesTypes.mostPopular,
    SalesTypes.elite,
];

module.exports = {
    imageExt,
    videoExt,
    audioExt,
    cron_order_limit,
    pool_limit,
    entities,
    response,
    errorResponse,
    okResponse,
    resOutputOk: (params) =>
        Object.assign(okResponse, { ...params }),
    resOutputError: (params) =>
        Object.assign(errorResponse, { ...params }),
    logLevels,
    environments,
    NODE_ENV,
    errorMessages,
    db,
    app,
    roles,
    actions,
    SalesType,
    SalesTypes,
    isAdmin,
    isManager,
    isQA,
    isCoordinator,
    isArtist,
    isVideoArtist,
    isVideoQA,
    isRedo,
    stages,
    nextStages,
    videoSalesTypes,
};
